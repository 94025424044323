import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import currentBusinessState from "../../../atoms/currentBusinessState";
import Loading from "../../../components/Loading";
import ShipmentBookingForm from "../../../components/ShipmentBookingForm";
import Api from "../../../lib/Api";

export default function BookShipment() {
    const { shipment_id } = useParams();

    const [shipment, setShipment] = useState(null)

    const [publicCoupons, setPublicCoupons] = useState([])

    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);


    useEffect(() => {
        if (currentBusiness && currentBusiness.id) {
            Api.get(`/${currentBusiness.id}/shipment/${shipment_id}`)
                .then(res => {
                    if (res.success) {
                        setShipment(res.data);
                        setPublicCoupons(res.coupon_codes)
                    }
                    else {
                        alert(res.message ? `${res.message}` : "Oops, something went wrong");
                        setShipment(null);
                    }
                })
        }
        else{
            setShipment(null)
        }

    }, [currentBusiness]);

    if(!shipment){
        return <Loading />
    }

    return <ShipmentBookingForm shipment={shipment} setShipment={setShipment} publicCoupons={publicCoupons} />
}