import dayjs from "dayjs";

const prepareRequestForQuotation = (shipment, quotation = null, coupon_code = null, quickQuote = false) => {
    const output = {}
    output.origin_country = shipment.pickup_country;
    output.from_city = `${shipment.pickup_city} - ${shipment.pickup_zip}`;
    output.origin_city = shipment.pickup_city;
    output.origin_zip = shipment.pickup_zip;
    output.pick_up_type = shipment.pickup_type ?? "commercial";
    output.destination_country = shipment.delivery_country;
    output.destination_zip = shipment.delivery_zip;
    output.destination_city = shipment.delivery_city;
    output.delivery_type = shipment.delivery_type ?? "residential";
    output.collection_date = shipment.pickup_date;
    output.delivery_state = shipment.delivery_state;
    output.pickup_state = shipment.pickup_state;
    output.product_type = shipment.product_type;
    output.weights = shipment.items.map(item => item.weight);
    output.widths = shipment.items.map(item => item.width);
    output.lengths = shipment.items.map(item => item.length);
    output.heights = shipment.items.map(item => item.height);
    output.PackageType = shipment.items.map(item => item.type);
    if(quotation && quotation.id){
        output.quotation_id = quotation.id;
    }

    output.shipment_id = shipment.id;
    if(coupon_code){
        output.coupon_code = coupon_code;
    }

    output.taillift_at_pickup = shipment.taillift_at_pickup;
    output.taillift_at_delivery = shipment.taillift_at_delivery;
    if(quickQuote){
        output.quick_quote = true;
    }
    else{
        output.quick_quote = false;
    }
    return output;
}

export default prepareRequestForQuotation;