import Select from "react-select";

function IndividualItem({item, setItem, removeItem, options}) {
    const value = options.find(opt => opt.value == item.admin_generic_item_id)
    return (
        <div className="row">
            <div className="col-md-5">
                <div className="form-group">
                    <label>Name</label>
                    <input className="form-control" type={'text'} 
                        onChange={(e) => {
                            const newItem = {...item}
                            newItem.name = e.target.value;

                            setItem(newItem)
                        }}
                        value={item.name}
                        required
                    />

                </div>
            </div>
            <div className="col-md-5">
                <div className="form-group">
                    <label>Maps To</label>
                    <Select 
                        options={options}
                        value={value}
                        onChange={val => {
                            const newItem = {...item}
                            newItem.admin_generic_item_id = val.value;

                            setItem(newItem)
                        }}
                    />

                </div>
            </div>
            <div className="col-md-2">
                <button className="btn btn-danger" style={{marginTop: '1rem'}} onClick={removeItem} type={'button'}>X</button>
            </div>
        </div>
    )
}

export default function GenericItemContainer({
    adminItems,
    businessItems,
    setBusinessItems
}){
    const options = adminItems.map(item => {
        return {
            label: item.name,
            value: item.id
        }
    })


    return (
        <>
            {businessItems.map((item, index) => {
                return <IndividualItem 
                    key={index}
                    item={item}
                    setItem={(i) => {
                        const myBusinessItems = [...businessItems]
                        myBusinessItems[index]=i;
                        setBusinessItems(myBusinessItems)
                    }}
                    removeItem={() => {
                        const myBusinessItems = [...businessItems]
                        myBusinessItems.splice(index, 1)
                        setBusinessItems(myBusinessItems)
                    }}
                    options={options}
                />
            })}

            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                <button className="btn btn-success" onClick={() => {
                    const myBusinessItems = [...businessItems]
                    myBusinessItems.push({
                        name: "",
                        admin_generic_item_id: adminItems[0].id
                    });

                    setBusinessItems(myBusinessItems)
                }} 
                type={'button'}
                >+</button>
            </div>

            
        </>
    )

}