export default function showB64File(file, type){
    try {
        const iframe = `<iframe width='100%' height='100%' src='data:${type};base64,${file}'></iframe>`
        const myWindow = window.open()
        myWindow.document.open();
        myWindow.document.write(iframe);
        myWindow.document.close();
    } catch (error) {
        alert("Unable to open file... Please make sure you have allowed pop up for this page");
        console.error(error);
    }
}