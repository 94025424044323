import { useEffect, useState } from "react";
import Api from "../lib/Api";
import BusinessSignatureUpdate from "./BusinessSignatureUpdate";
import Loading from "./Loading";

export default function BusinessSignature({ business }) {

    const [showUpdateForm, setShowUpdateForm] = useState(false)

    const [image, setImage] = useState(null);

    useEffect(() => {
        if (business && business.id) {
            Api.get(`/${business.id}/getBusinessSign`)
                .then(res => {
                    if (res.success) {
                        setImage(`data:${res.mime};base64,${res.file}`)
                    }
                    else {
                        setImage('#')
                        alert(res.message ?? "Something Went Wrong")
                    }
                })
                .catch(e => {
                    setImage('#')
                    alert(e.message ?? "Something Went Wrong")
                })
        }

    }, [business])


    if (!(business && business.setting && business.setting.signature) || showUpdateForm) {
        return <BusinessSignatureUpdate business={business} />
    }

    return (
        <div className="signature text-center">
            {!image &&
                <Loading />
            }
            {image &&
                <img className="signature-preview mb-4 mt-4" src={image} />
            }
            <br />
            <button className="btn btn-primary"
                onClick={() => {
                    setShowUpdateForm(!showUpdateForm)
                }}
            >Update Sign</button>
        </div>
    )


}