import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.headers = {
    "Content-Type": "application/json",
    "Accept": "application/json"
};
const prefix = `${process.env.REACT_APP_BASE_URL}`
const Api = {};

const allowedCodes = [403, 401, 422];

Api.get = async (url, params = {}) => {
    try{
        const response = await axios.get(`${prefix}${url}`, {
            params: params
        })

        return response.data
    }
    catch(error){
        if(allowedCodes.indexOf(error.response.status) == -1){
            throw error;
        }
        return error.response.data;
    }
}

Api.post = async (url, body) => {
    try{
        const response = await axios.post(`${prefix}${url}`, JSON.stringify(body))
        return response.data;
    }
    catch(error){
        if(allowedCodes.indexOf(error.response.status) == -1){
            throw error;
        }
        
        return error.response.data;
    }   
}

Api.delete = async (url, body = {}) => {
    body['_method'] = 'delete';
    return Api.post(url, body);
} 

Api.put = async (url, body = {}) => {
    body['_method'] = 'put';
    return Api.post(url, body);
} 


Api.filePost = async (url, formDataBody) => {
    try {
        const response = await axios.post(`${prefix}${url}`, formDataBody, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': "application/json"
            }
        })

        return response.data; 
    } catch (error) {
        if(allowedCodes.indexOf(error.response.status) == -1){
            throw error.response.data;;
        }
        return error.response.data;
    }
}



export default Api;