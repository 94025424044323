import { FieldArray } from "formik"
import getPackageTypeOptions from "../lib/getPackageTypeOptions"
import FormikInputBootstrap from "./util/FormikInputBootstrap"
import Warning from "./util/Warning"

export default function ItemsContainer({ formik }) {
    const addItem = () => {
        const lastItem = formik.values.items.length > 0 ? formik.values.items[formik.values.items.length - 1] : {
            height: '',
            width: '',
            length: '',
            weight: '',
            type: '',
            value: '',
            content_description: ''
        }
        formik.setFieldValue('items', [...formik.values.items, lastItem])
    }

    const getError = (item, key) => {
        // console.log(formik.errors.items[key][item]);
        if (formik.errors && formik.errors.items && formik.errors.items[key] && formik.errors.items[key][item] && formik.touched.items && formik.touched.items[key] && formik.touched.items[key][item]) {
            
            return formik.errors.items[key][item];
        }

        return null;
    }

    const getValue = (item, key) => {
        if (formik.values && formik.values.items && formik.values.items[key] && formik.values.items[key][item]) {
            return formik.values.items[key][item];
        }

        return null;
    }

    const removeItem = (key) => {
        const newValue = formik.values.items.filter((item, index) => index != key);
        formik.setFieldValue('items', newValue)
    }
    return (
        <fieldset>

            {formik.values.items.map((item, key) => {
                return (
                    <div className="row mb-4 individual_item" key={key}>

                        <div className="col-md-2">
                            <FormikInputBootstrap
                                inputName={`items.${key}.weight`}
                                formik={formik}
                                label={'Weight'}
                                error={getError('weight', key)}
                                value={getValue('weight', key)}
                                step={0.5}
                                type={'number'}
                            />
                        </div>

                        <div className="col-md-2">
                            <FormikInputBootstrap
                                inputName={`items[${key}].length`}
                                formik={formik}
                                label={'Length'}
                                type={'number'}
                                error={getError('length', key)}
                                value={getValue('length', key)}
                            />
                        </div>
                        <div className="col-md-2">
                            <FormikInputBootstrap
                                inputName={`items[${key}].width`}
                                formik={formik}
                                label={'Width'}
                                type={'number'}
                                error={getError('width', key)}
                                value={getValue('width', key)}

                            />
                        </div>

                        
                        <div className="col-md-2">
                            <FormikInputBootstrap
                                inputName={`items[${key}].height`}
                                formik={formik}
                                label={'Height'}
                                type={'number'}
                                error={getError('height', key)}
                                value={getValue('height', key)}
                            />
                        </div>
                        

                        

                        <div className="col-md-2">
                            <FormikInputBootstrap
                                inputName={`items[${key}].type`}
                                formik={formik}
                                label={'Package Type'}
                                type={'select'}
                                error={getError('type', key)}
                                value={getValue('type', key)}
                                options={getPackageTypeOptions()}
                            />
                        </div>
                        <div className="col-md-2 ">
                        
                            <FormikInputBootstrap
                                inputName={`items.${key}.value`}
                                formik={formik}
                                label={'Value'}
                                error={getError('value', key)}
                                value={getValue('value', key)}
                                step={0.01}
                                type={'number'}
                            />
                        </div>
                        <div className="col-md-11">

                            <FormikInputBootstrap
                                inputName={`items.${key}.content_description`}
                                formik={formik}
                                label={'Content Description'}
                                type={'textarea'}
                                error={getError('content_description', key)}
                                value={getValue('content_description', key)}
                            />
                        </div>
                        
                        <div className="col-md-1 text-right jhgfds">
                            <button
                                className="btn btn-danger mt-4"
                                type="button"
                                onClick={() => {
                                    removeItem(key)
                                }}
                                disabled={formik.values.items.length <= 1}
                            >X</button>
                        </div>
                    </div>

                )
            })}
            {formik.errors.items && formik.touched.items && typeof formik.errors.items == 'string' ? <Warning message={formik.errors.items} /> : null}

            <div className="text-center">
                <button className="btn btn-secondary" type={'button'} onClick={addItem}>+</button>
            </div>
        </fieldset>
    )
}