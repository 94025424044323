export default function getInsuranceValues() {
    return [
        {
            cover: 500,
            premium: 500 * 0.025
        },
        {
            cover: 1000,
            premium: 1000 * 0.025
        },
        {
            cover: 1500,
            premium: 1500 * 0.025
        },
        {
            cover: 2000,
            premium: 2000 * 0.025
        },
        {
            cover: 2500,
            premium: 2500 * 0.025
        },
        {
            cover: 3000,
            premium: 3000 * 0.025
        },
        {
            cover: 3500,
            premium: 3500 * 0.025
        },
        {
            cover: 4000,
            premium: 4000 * 0.025
        }
    ];
}