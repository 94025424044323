import { useEffect } from "react";
import { useRecoilState } from "recoil";
import availableBusinessesState from "../../atoms/availableBusinessesState";
import currentBusinessState from "../../atoms/currentBusinessState";
import currentUserState from "../../atoms/currentUserState";
import Api from "../../lib/Api";

export default function GetAvailableBusinesses(){
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

    const [availableBusiness, setAvailableBusiness] = useRecoilState(availableBusinessesState);

    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);


    useEffect(() => {
        if(currentUser && currentUser.id){
            Api.get('/my-business')
                .then(res => {
                    setAvailableBusiness(res);
                })
        }
    }, [currentUser]);

    useEffect(() => {
        if(availableBusiness && availableBusiness.length > 0 && currentBusiness === null){
            setCurrentBusiness(availableBusiness[0]);
        }
    }, [availableBusiness]);



    return (
        <></>
    );
}