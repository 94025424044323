import React from "react";

export default function ContactUsPage() {

    return (
        <>
            <section className="contact-image">
                <img src="/image5.jpg" alt="" />
            </section>
            <section className="contact-page my-row align-items-center justify-content-center ">
                <div className="w-100">
                    <div className="container bg-1d5b44 p-3 mt-5 mb-5">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="contact-mp">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.7137950294646!2d151.00181501578095!3d-33.871266126619346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12bcaef54f8eff%3A0xdbd56da2a2e6286a!2sDTDC%20Australia!5e0!3m2!1sen!2sin!4v1655982757411!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="p-3">
                                <form action="" className="contact-form pb-5">
                                    <h2 className="new-h2-sty1 w-100 text-center text-white">Contact Us</h2>
                                    <input type="text" className="form-control shadow-sm" placeholder="Your name" />
                                    <input type="mail" className="form-control shadow-sm" placeholder="Email address" />
                                    <textarea className="form-control shadow-sm" placeholder="Message" />
                                    <div className="text-center"><button className="fsdghsd">Send</button></div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                
            </section>
        </>
    )
}