import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas'
import Api from "../lib/Api";
import Warning from './util/Warning'

export default function BusinessSignatureUpdate({ business }) {
    const [errors, setErrors] = useState([]);

    const [draw, setDraw] = useState(true);

    const nav = useNavigate()

    const [file, setFile] = useState();

    let sigPad = {};

    const handleSubmit = async (e) => {
        const data = new FormData();

        data.append('signature', file);
        const res = await Api.filePost(`/business-setting-update/${business.setting.id}`, data);
        if (res.success) {
            nav('/dashboard/my-business');
        }
        else {
            if (res.errors) {
                const myErrors = [];
                for (const x in res.errors) {
                    if (Object.hasOwnProperty.call(res.errors, x)) {
                        const errorList = res.errors[x];
                        myErrors.push(errorList[0]);
                    }
                }

                setErrors(myErrors);
            }
            else {
                setErrors([res.message ?? "Something Went Wrong"]);
            }
        }
    }
    return (
        <>
            {!draw &&
                <>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit()
                    }}>
                        <div className="form-group enter-csv">
                            <label>Update Authorized Signature</label>
                            <input name="signature" className="form-control" type={'file'} 
                                onChange={(e) => {
                                    setFile(e.target.files[0]);
                                }}
                            />
                        </div>
                        {errors.map((e, key) => <Warning key={key} message={e} />)}
                        <button className="btn btn-primary">Save</button>
                        <button type="button" className="btn btn-info " onClick={() => {
                            setDraw(!draw)
                        }}>Draw Sign Instead</button>
                    </form>
                    
                </>
            }
            {draw && 
                <>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit()
                    }}>
                        <div className="form-group">
                            <label>Please Draw You Signature</label>
                            <div style={{ border: '1px solid black', width: 'min-content' }}>
                                <SignatureCanvas ref={(ref) => sigPad = ref} penColor='black'
                                    canvasProps={{ className: 'sigCanvas', width:300, height: 200 }} 
                                    onEnd={() => {
                                        sigPad.getCanvas().toBlob((blob) => {
                                            setFile(blob)
                                        })
                                    }}    
                                />
                            </div>
                        </div>
                        {errors.map((e, key) => <Warning key={key} message={e} />)}
                        <button  className="btn btn-primary">Save</button>
                        <button className="btn btn-info" type="button" onClick={() => {
                            setDraw(!draw)
                        }}>Upload Signature Instead</button>
                    </form>

                    

                </>
            }

        </>

    )
}