import Api from "./Api";
import prepareRequestForQuotation from "./prepareRequestForQuotation";

export default async function getQuoteForShipment(shipment, courier_service, quotation, currentBusinessId, couponCode, batch_id = null){
    try {
        if(!courier_service.service_is_active == 'yes'){
            return null;
        }
        // console.log(quotation);
        const body = prepareRequestForQuotation(shipment, quotation);
        
        const response = await Api.post(`/${currentBusinessId}/get_quote`, {
            service: courier_service,
            shipment: body,
            shipment_id: shipment.id,
            coupon_code: couponCode,
            batch_id: batch_id
        });

        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
}


