import { Link, NavLink, Outlet, useNavigate } from "react-router-dom"
import "./../styles/adminlte.min.css"
import './../plugins/fontawesome-free/css/all.min.css'
import { useEffect, useState } from "react"
import NavItemWithChildren from "../components/NavItemWithChildren"
import StateManager from "../components/util/StateManager"
import { useRecoilState } from "recoil"
import availableBusinessesState from "../atoms/availableBusinessesState"
import currentBusinessState from "../atoms/currentBusinessState"
import currentUserState from "../atoms/currentUserState"
import logUserOut from "../lib/logUserOut"
import Loading from "../components/Loading"

export default function DashboardLayout() {
    const [isCollapsed, setIsCollapsed] = useState(true)

    const [availableBusiness, setAvailableBusiness] = useRecoilState(availableBusinessesState);

    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);

    const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

    const navigate = useNavigate();


    useEffect(() => {
        if(currentUser && !currentUser.id){
            //redirect to login
            navigate("/auth/login")
        }
    }, [currentUser])




    return (
        <div className={isCollapsed ? 'sidebar-mini layout-fixed sidebar-collapse' : "hold-transition sidebar-mini"}>
            <div className="wrapper">
                <nav className="main-header navbar navbar-expand navbar-dark navbar-light fixed-top">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#" role="button" onClick={() => {
                                setIsCollapsed(!isCollapsed)
                            }}><i className="fas fa-bars"></i></a>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <Link to={'/'} className='nav-link' >Home</Link>
                        </li>
                    </ul>

                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <select className="nav-link"
                                value={currentBusiness?.id}
                                onChange={(e) => {
                                    let found = false;
                                    let key = 0;
                                    while (!found && key < availableBusiness.length) {
                                        if (availableBusiness[key] && availableBusiness[key].id && availableBusiness[key].id == e.target.value) {
                                            setCurrentBusiness(availableBusiness[key])
                                            found = true;
                                        }
                                        key += 1;

                                    }
                                }}
                            >
                                {availableBusiness && availableBusiness.map &&
                                    availableBusiness.map(business => <option key={business.id} value={business.id}>{business.name}</option>)
                                }
                            </select>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link log_out_btn" href="#" role="button"
                                onClick={() => {
                                    logUserOut(setCurrentUser, navigate);
                                }}
                            >
                                Log Out <i className="fas fa-sign-out-alt"></i>
                            </a>
                        </li>
                    </ul>
                </nav>


                <aside className="main-sidebar sidebar-dark-primary elevation-4 h-100vh">
                    <a href="/index3.html" className="brand-link">
                        <img src={process.env.REACT_APP_LOGO_LOCATION} alt="AdminLTE Logo" className="brand-image p-1 bg-white elevation-3" />
                        <span className="brand-text font-weight-light">{process.env.REACT_APP_APP_NAME}</span>
                    </a>


                    <div className="sidebar">
                        <div className="user-panel mt-3 pb-3 d-flex">
                            <div className="info">
                                <a href="#" className="d-block">{currentUser && currentUser.name ? currentUser.name : "Guest"}</a>
                            </div>
                        </div>

                        <nav className="mt-4" onClick={() => {
                            setIsCollapsed(true)
                        }}>
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                <li className="nav-item">
                                    <NavLink to={'/dashboard/'} className='nav-link'>
                                        <i class="fas fa-tachometer-alt"></i>
                                        <p>
                                            Dashboard
                                        </p>
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink to={'/dashboard/orders'} className='nav-link'>
                                        <i className="fas fa-luggage-cart"></i>
                                        <p>
                                            Shipments
                                        </p>
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink to={'/dashboard/quick_quote'} className='nav-link'>
                                        <i className="fas fa-luggage-cart"></i>
                                        <p>
                                            Quick Quote
                                        </p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">

                                    <NavLink to={'/dashboard/shipments'} className='nav-link'>
                                        <i className="fas fa-shipping-fast"></i>
                                        <p>
                                            Orders
                                        </p>
                                    </NavLink>
                                </li>

                                <li className="nav-item">

                                    <NavLink to={'/dashboard/batch'} className='nav-link'>
                                        <i className="fas fa-shipping-fast"></i>
                                        <p>
                                            Batch Shipment
                                        </p>
                                    </NavLink>
                                </li>

                                <li className="nav-item">

                                    <NavLink to={'/dashboard/wallet'} className='nav-link'>
                                        <i className="fas fa-wallet"></i>
                                        <p>
                                            Wallet
                                        </p>
                                    </NavLink>
                                </li>

                                <li className="nav-item">

                                    <NavLink to={'/dashboard/my-business'} className='nav-link'>
                                        <i className="fas fa-briefcase"></i>
                                        <p>
                                            My Business
                                        </p>
                                    </NavLink>
                                </li>

                                {currentBusiness?.enable_auto_description_feature == 1 && 
                                    <li className="nav-item">
                                        <NavLink to={'/dashboard/content-description'} className='nav-link'>
                                            <i className="fas fa-briefcase"></i>
                                            <p>
                                                Override Content Description
                                            </p>
                                        </NavLink>
                                    </li>

                                }

                                <li className="nav-item">

                                    <NavLink to={'/dashboard/my-documents'} className='nav-link'>
                                        <i class="fas fa-file-alt"></i>
                                        <p>
                                            My Documents
                                        </p>
                                    </NavLink>
                                </li>

                                <li className="nav-item">

                                    <NavLink to={'/dashboard/warehouse'} className='nav-link'>
                                        <i className="fas fa-warehouse"></i>
                                        <p>
                                            Address Book
                                        </p>
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink to={'/dashboard/customers'} className='nav-link'>
                                        <i className="fas fa-users"></i>
                                        <p>
                                            Customers
                                        </p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">

                                    <NavLink to={'/dashboard/invoices'} className='nav-link'>
                                        <i class="fas fa-file-invoice"></i>
                                        <p>
                                            Invoices
                                        </p>
                                    </NavLink>
                                </li>

                            </ul>
                        </nav>
                    </div>
                </aside>

                


                <div className="content-wrapper pad-t-58 pad-b-58">
                    <section className="content">
                        <StateManager />
                        {currentUser && currentUser.id &&
                            <Outlet />
                        }
                        {!currentUser &&
                            <Loading />
                        }

                        {currentUser && !currentUser.id &&
                            <Link to={'/auth/login'} className="btn btn-primary">Log In</Link>
                        }



                    </section>

                </div>

                <footer className="main-footer fixed-bottom mr-0">
                    <div className="ml-4p5rem text-white">
                        <div className="float-right d-none d-sm-block">
                            <b>Version</b> 3.2.0
                        </div>
                        <strong>Copyright &copy; {new Date().getFullYear()} <a href="{{ route('home') }}">{process.env.APP_NAME}</a>.</strong> All rights reserved.
                    </div>
                </footer>


                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    )
}