import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Loading from "../../components/Loading";
import WalletPrintableArea from "../../components/WalletPrintableArea";
import Api from "../../lib/Api";

export default function WalletPrint(){

    const { business_id } = useParams();

    const [loading, setLoading] = useState(true);
    const [wallet, setWallet] = useState(null)

    let [searchParams, setSearchParams] = useSearchParams();

    const filterConfig = JSON.parse(searchParams.get('filterConfig'))

    useEffect(() => {
        try {
            setLoading(true)
            Api.get(`/${business_id}/wallet`, filterConfig)
                .then(res => {
                    if (res.success) {
                        setWallet(res.wallet);
                        setLoading(false)

                        window.print()
                    }
                    else {
                        alert(res.message ? `${res.message}` : 'Oops, something went wrong');
                    }
                })
        } catch (error) {
            alert('Oops, something went wrong');
        }
    }, [business_id])

    if(loading){
        return (
            <Loading />
        )
    }

    return (

        <WalletPrintableArea
            wallet={wallet}
            filterOptions={filterConfig}
            isPrintMode={true}
        />
    )
}