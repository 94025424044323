import { useFormik } from "formik"
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import Otp from "../../components/Otp";
import Warning from "../../components/util/Warning";
import Api from "../../lib/Api";
import handleFormikBackendValidation from "../../lib/handleFormikBackendValidation";
import signupValidation from "../../validation/signupValidation"

export default function Signup() {

    const [loading, setLoading] = useState(false)

    const [otp, setOtp] = useState(null)

    let navigate = useNavigate();

    const handleSubmit = async (values, actions) => {
        try {
            setLoading(true)
            const res = await Api.post('/signup', values);
            if (res.errors) {
                handleFormikBackendValidation(res, actions);

            }
            else {
                console.log(res);
                if (res.success) {
                    setOtp(res.otp)
                }
            }
            setLoading(false)
        }
        catch (error) {
            alert('Oops, something went wrong');
            setLoading(false)
        }

    }

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            business: '',
            billing_name: '',
            billing_email: '',
            billing_phone: '',
            billing_add1: '',
            billing_add2: '',
            billing_add3: '',
            billing_city: '',
            billing_zip: '',
            billing_state: '',
            billing_country: '',
            billing_tax_code: ''
        },
        onSubmit: handleSubmit,
        validationSchema: signupValidation
    });


    if (otp) {
        return (
            <Otp
                otp={otp}
                setOtp={setOtp}
                loading={loading}
                setLoading={setLoading}
                navigate={navigate}
            />
        )

    }


    return (
        <main className="text-center login-form-container">
            <div className="form-signin">
                <form onSubmit={formik.handleSubmit}>
                    <img className="mb-4 singup-icon" src={`${process.env.REACT_APP_LOGO_LOCATION}`} alt="" />
                    <h1 className="h3 mb-3 fw-bold text-dark">Sign Up And Join With BIZ Express</h1>

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" placeholder="John Doe" name='name'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                        />
                        <label>Name</label>
                    </div>
                    {formik.errors.name && formik.touched.name ? <Warning message={formik.errors.name} /> : null}


                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" placeholder="name@example.com" name='email'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                        />
                        <label>Email address</label>
                    </div>
                    {formik.errors.email && formik.touched.email ? <Warning message={formik.errors.email} /> : null}

                    <div className="form-floating mb-3">
                        <input type="password" name='password' className="form-control" placeholder="Password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                        />
                        <label>Password</label>
                    </div>
                    {formik.errors.password && formik.touched.password ? <Warning message={formik.errors.password} /> : null}

                    <div className="form-floating mb-3">
                        <input type="password" name='password_confirmation' className="form-control" placeholder="Confirm Password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password_confirmation}
                        />
                        <label>Confirm Password</label>
                    </div>
                    {formik.errors.password_confirmation && formik.touched.password_confirmation ? <Warning message={formik.errors.password_confirmation} /> : null}

                    <Input
                        type={'text'}
                        name={'business'}
                        label={'Business Name'}
                        formik={formik}
                    />
              
                    {['billing_name',
                        'billing_email',
                        'billing_phone',
                        'billing_add1',
                        'billing_add2',
                        'billing_add3',
                        'billing_city',
                        'billing_zip',
                        'billing_state',
                        'billing_country',
                        'billing_tax_code'].map((i, k) => <Input
                            name={i}
                            key={k}
                            type={'text'}
                            label={i}
                            formik={formik}
                        />)}

                    <div className="checkbox mb-3">

                    </div>

                    {loading &&
                        <Loading />
                    }
                    {!loading &&
                        <button className=" btn btn-primary" type="submit">Sign Up</button>
                    }

                    <p className="mt-5 mb-3 text-muted">
                        Already have an account?
                        <Link to={'/auth/login'}> Log In</Link>
                    </p>



                </form>
            </div>
        </main>
    )
}


function Input({ type, name, label, formik }) {
    return (
        <>
            <div className="form-floating mb-3">
                <input type={type} name={name} className="form-control" placeholder={label}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[name]}
                />
                <label>{snakeToWord(label)}</label>
            </div>
            {formik.errors[name] && formik.touched[name] ? <Warning message={formik.errors[name]} /> : null}


        </>
    )
}

function snakeToWord(str) {

    //split the above string into an array of strings 
    //whenever a blank space is encountered

    const arr = str.split("_");

    //loop through each element of the array and capitalize the first letter.


    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

    }

    //Join all the elements of the array back into a string 
    //using a blankspace as a separator 
    const str2 = arr.join(" ");
    return str2
}