import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../../../lib/Api";
import { useRecoilState } from "recoil";
import currentBusinessState from "../../../atoms/currentBusinessState";
import Loading from "../../../components/Loading";
import batchHelper from "../../../lib/batchHelper";
import BatchShipmentView from "../../../components/BatchShipmentView";
import courierServicesState from "../../../atoms/courierServicesState";

export default function BatchShow() {

    const { id } = useParams();

    const [loading, setLoading] = useState(true);

    const [batch, setBatch] = useState(null);
    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState)

    const [courierServices, setCourierServices] = useRecoilState(courierServicesState)

    const loadBatch = async (id) => {

        const res = await Api.get(`/${currentBusiness.id}/batch/${id}`)
        if (res.success) {
            setBatch(res.batch);
            setLoading(false)
        }
        else {
            setLoading(true)
            setBatch(null);
        }


    }

    useEffect(() => {
        if (currentBusiness && currentBusiness.id) {
            loadBatch(id)
                .catch(error => {
                    setBatch(null)
                    setLoading(true)
                    alert(error.message ?? "Something went wrong")
                })
        }

    }, [currentBusiness])

    if (loading) {
        return <Loading />
    }
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">Batch : {batch.name}</h3>

                <div className="card-tools">
                </div>
            </div>
            <div className="card-body">
                <div className="">
                    {<BatchShipmentView
                        removeShipmentFromBatch={(id) => {
                            batchHelper.removeShipmentFromBatch(id, setBatch, currentBusiness, batch)
                        }}
                        currentBatch={batch}
                        setCurrentBatch={setBatch}
                        handleCourierServiceChangeForBatch={(batchId, courierServiceId) => {
                            batchHelper.handleCourierServiceChangeForBatch(batchId, courierServiceId, setBatch, courierServices, currentBusiness, batch)
                        }}
                        recheckPrice={(shipmentId) => {
                            batchHelper.addShipmentToBatch(shipmentId, setBatch, currentBusiness, courierServices, batch)
                        }} 
                    />}
                </div>

            </div>

        </div>
    )
}