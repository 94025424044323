const values = ['Satchel', 'Box', 'Pallet', 'Roll', 'Drum', 'Crate', 'Envelope', 'Bag', 'Carton'];

export default function getPackageTypeOptions(){
    
    return values.map(item => {
        return {
            label: item,
            value: item
        }
    })
}

export function packageTypeList(){
    return values;
}