import { object, string, email, number, date, array } from 'yup'
import getAuStateListJson from '../lib/getAuStateListJson';
import getCountryListJson from '../lib/getCountryListJson';
import { packageTypeList } from '../lib/getPackageTypeOptions';
import { productTypeList } from '../lib/getProductTypeOptions';
const quickQuoteValidation = object({
    pickup_city: string().required(),
    pickup_zip: string().required(),
    pickup_country: string().required().min(2).max(2).oneOf(getCountryListJson().map(country => country.value)),
    delivery_city: string().required(),
    delivery_zip: string().required(),
    delivery_country: string().required().min(2).max(2).oneOf(getCountryListJson().map(country => country.value)),
    // content_description: string().required().min(5).max(2000),
    pickup_date: date().required(),
    items: array().of(
        object().shape({
            height: number().required().min(1),
            width: number().required().min(1),
            length: number().required().min(1),
            weight: number().required().min(0.5),
            type: string().required().oneOf(packageTypeList()),
            value: number().required().min(1),
            content_description: string().required()
        })
    ).min(1, "You need atleast one item"),
    pickup_type: string().required().oneOf(['residential', 'commercial']),
    delivery_type: string().required().oneOf(['residential', 'commercial']),
    product_type: string().required().oneOf(productTypeList())
})

export default quickQuoteValidation;