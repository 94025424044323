import { useState } from "react";
import { useRecoilState } from "recoil";
import availableCustomersState from "../../../atoms/availableCustomersState";
import currentBusinessState from "../../../atoms/currentBusinessState";
import CustomersAdd from "../../../components/CustomersAdd";
import CustomersContainer from "../../../components/CustomersContainer";
import Loading from "../../../components/Loading";
import Api from "../../../lib/Api";
import handleFormikBackendValidation from "../../../lib/handleFormikBackendValidation";

export default function CustomerIndex() {

    const [showModal, setShowModal] = useState(false)

    const [editCustomer, setEditCustomer ] = useState(null);

    const [customers, setCustomers] = useRecoilState(availableCustomersState);

    const [loading, setLoading ] = useState(false)

    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState)

    const resetForm = (res) => {
        if(res && res.customers){
            setCustomers(res.customers);
        }
        setShowModal(false)
        setEditCustomer(null)
        setLoading(false)
    }

    const saveCustomerData = async (values, actions) => {
        try {
            setLoading(true)
            
            const res = await Api.post(`/${currentBusiness.id}/customer`, values)

            if(res.success){
                resetForm(res)
            }
            else{
                if(res.errors){
                    handleFormikBackendValidation(res, actions);
                }
                else{
                    alert(res.message ? `${res.message}` : "Something went Wrong");
                }
            }
            setLoading(false)
        } catch (error) {
            alert(error.message ? `${error.message}` : "Something went Wrong");
            setLoading(false)
        }
    }

    const addCustomer = () => {
        setEditCustomer(null)
        setShowModal(true)
    }

    const editCustomerFunc = (id) => {
        const customer = customers.find(item => item.id == id);
        setEditCustomer(customer)
        setShowModal(true)
    }

    const deleteCustomer = (id) => {
        const customer = customers.find(item => item.id == id);
        if(window.confirm(`Are you sure? Delete Customer : ${customer.name} (${customer.email})?`)){
            Api.delete(`/${currentBusiness.id}/customer/${id}`)
                .then(res => {
                    if(res.success){
                        setCustomers(res.customers);
                    }
                })
        }
        
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">Customers</h3>

                <div className="card-tools">
                    <button
                        className="btn btn-primary"
                        onClick={addCustomer}
                    >Add New</button>
                </div>
            </div>
            <div className="card-body">
                <CustomersContainer 
                    customers={customers}
                    editCustomer={editCustomerFunc}
                    deleteCustomer={deleteCustomer}
                />
            </div>
            {showModal &&
                <CustomersAdd 
                    customer={editCustomer}
                    loading={loading}
                    handleSubmit={saveCustomerData}
                    close={resetForm}
                />
            }
        </div>
    )
}