export default function BatchShipmentError({error}) {
    let errorList = {}
    if(error.errors){
        errorList = error.errors
    }

    const errorArray = [];

    for (const x in errorList) {
        if (Object.hasOwnProperty.call(errorList, x)) {
            const e = errorList[x];

            errorArray.push(`${x} : ${e[0] ?? 'error'}`)
            
        }
    }
    return (
        <div style={{padding: '1rem', borderRadius: '1rem', background: 'yellowgreen'}}>
            <div className="alert alert-warning">{error.message ?? "Something went wrong"}</div>
            {errorArray.map(e => <div className="alert alert-warning" style={{marginBottom: 0}}>{e}</div>)}

        </div>
    )
}