import { Outlet } from "react-router-dom";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import StateManager from "../components/util/StateManager";

export default function StandardLayout({children}){
    return (
        <>
            <StateManager />
            <Navigation />
            <Outlet />
            <Footer />
        </>
    )
}