import { useFormik } from "formik";
import { useState } from "react";
import { useRecoilState } from "recoil";
import availableBusinessesState from "../atoms/availableBusinessesState";
import Api from "../lib/Api";
import handleFormikBackendValidation from "../lib/handleFormikBackendValidation";
import Loading from "./Loading";
import Modal from "./util/Modal";
import Warning from "./util/Warning";

export default function CreateBusinessForSomeoneElse({ closeForm }) {

    const [availableBusiness, setAvailableBusiness ] = useRecoilState(availableBusinessesState)
    const [loading, setLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            email: '',
            parent_business: '',
            default_markup_percentage: ''
        },
        onSubmit: async (values, actions) => {
            setLoading(true)
            try {
                
                const res = await Api.post('/invite_business', values);
                if (res.success) {
                    closeForm()
                }
                else {
                    if (res.errors) {
                        handleFormikBackendValidation(res, actions)
                        setLoading(false)
                    }
                    else{
                        alert(res.message ? `${res.message}` : "Oops, Something went wrong");
                        setLoading(false)
                    }
                }
            }
            catch (error) {
                console.log(error);
                alert('Oops, something went wrong')
                setLoading(false)
            }
        }
    })
    return (
        <Modal
            close={closeForm}
            title={'Create Business for Someone Else'}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                    <label>Email</label>
                    <input className="form-control" name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                    />
                    {formik.errors.email && formik.touched.email ? <Warning message={formik.errors.email} /> : null}

                </div>

                

                <div className="form-group">
                    <label>Default Markup Percentage</label>
                    <input className="form-control" type={'number'} step={0.01} name="default_markup_percentage"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.default_markup_percentage}
                    />
                </div>

                <div className="alert alert-info">
                    Invite is valid for 24 hours. 
                    Only the last invite recieved on a given email will be honured
                </div>
                {loading &&
                    <Loading />
                }

                {!loading &&
                    <button type="submit" className="btn btn-primary">Invite</button>
                }
                
            </form>
        </Modal>
    )
}