import getQuotation from "./getQuotation";
import prepareRequestForQuotation from "./prepareRequestForQuotation";

const { default: Api } = require("./Api");
const { default: getQuoteForShipment } = require("./getQuoteForShipment");

const removeShipmentFromBatch = async (shipment_id, setCurrentBatch, currentBusiness, currentBatch) => {
    const res = await Api.post(`/${currentBusiness.id}/shipment/delete_shipment_from_batch`, {
        shipment_id,
        batch_id: currentBatch.id
    })

    if(res.success){
        setCurrentBatch(res.batch)
    }
}

const fetchBatchQuote = async (shipment, courierService, rerender = false, setCurrentBatch, currentBusiness, currentBatch) => {
        
    const quotation = await getQuotation(shipment, currentBusiness.id);
    if(quotation.success){
        const mainQuotation = quotation.quotation;
        
        
        let quotes = [];
        if(courierService.api_id == 1 && !courierService.fetch_individually){
            quotes = await Api.post(`/${currentBusiness.id}/get_quote_multiple`, {...prepareRequestForQuotation(shipment, mainQuotation), batch_id: currentBatch.id})
        }
        else{
            quotes = await getQuoteForShipment(shipment, courierService, mainQuotation, currentBusiness.id, "", currentBatch.id);
        }

        const res = await Api.get(`/${currentBusiness.id}/batch/${currentBatch.id}`);

        if(res.success){
            setCurrentBatch(res.batch)
        }
    }
    else{
        const myCurrentBatch = {...currentBatch};

        if(!myCurrentBatch.shipments.find(s => s.id == shipment.id)){
            myCurrentBatch.shipments.push(shipment);
        }
        myCurrentBatch.shipments.forEach((s, i) => {
            if(shipment.id == s.id){
                myCurrentBatch.shipments[i].error = quotation;
            }
            
        });
        setCurrentBatch(myCurrentBatch);
    }
}


const fetchAllQuotes = (courierServiceId, setCurrentBatch, courierServices, currentBusiness, currentBatch) => {
    const courierService = courierServices.find(service => service.id == courierServiceId)

    currentBatch.shipments.forEach(shipment => {
        fetchBatchQuote(shipment, courierService, false, setCurrentBatch, currentBusiness, currentBatch)
    })
    
}


const handleCourierServiceChangeForBatch = async (batchId, courierServiceId, setCurrentBatch, courierServices, currentBusiness, currentBatch) => {
    const res = await Api.post(`/${currentBusiness.id}/shipment/update_courier_service_for_batch`, {
        batch_id: batchId,
        courier_service_id: courierServiceId
    })

    setCurrentBatch(res.batch);
    fetchAllQuotes(courierServiceId, setCurrentBatch, courierServices, currentBusiness, currentBatch)
    
}


const addShipmentToBatch = async (shipmentId, setCurrentBatch, currentBusiness, courierServices, currentBatch) => {
    if (currentBusiness && currentBusiness.id) {
        try {    
            const res = await Api.post(`/${currentBusiness.id}/shipment/add_to_batch`, {
                shipment_id: shipmentId,
                batch_id: currentBatch ? currentBatch.id : null
            })
            if(!res.success) {
                throw Error(res.message ?? "Something went wrong");
            }  

            setCurrentBatch(res.batch);
            if(res.batch.dtdc_courier_service_id){
                const shipment = res.batch.shipments.find(s => s.id == shipmentId);
                const courierService = courierServices.find(c => c.id == res.batch.dtdc_courier_service_id);
                console.log({
                    shipment,
                    courierService
                });
                if(shipment && courierService){
                    try {
                        await fetchBatchQuote(shipment, courierService, true, setCurrentBatch, currentBusiness, currentBatch)                                                    
                    } catch (error) {
                        console.error(error)
                    }
                }
            }
        } catch (error) {
            alert(error.message ?? "Something went wrong");
        }


    }
}


const batchHelper = {
    handleCourierServiceChangeForBatch,
    fetchAllQuotes,
    fetchBatchQuote,
    removeShipmentFromBatch,
    addShipmentToBatch
}

export default batchHelper