import { useEffect, useState } from "react";
import Select from 'react-select'
import { useRecoilState } from "recoil";
import importErrorState from "../atoms/importErrorState";
import getAuStateListJson from "../lib/getAuStateListJson";
import getCountryListJson from "../lib/getCountryListJson";
import getPackageTypeOptions from "../lib/getPackageTypeOptions";
import getProductTypeOptions from "../lib/getProductTypeOptions";

export default function ImportedShipmentTableEdit({
    value: initialValue,
    row: { index },
    column: { id },
    row,
    column,
    updateTableData
}) {

    // console.log(row);
    const [value, setValue] = useState(initialValue)

    const [errors, setError] = useRecoilState(importErrorState);
    const onChange = e => {
        setValue(e.target.value)
    }

    const onBlur = () => {
        updateTableData(index, id, value)
    }



    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])


    if (id == 'package_type') {
        let selectedOption = '';

        const myOption = getPackageTypeOptions().find(item => item.value == initialValue)

        if(myOption){
            selectedOption = myOption;
        }

        
        return (
            <>
                <Select
                    defaultValue={selectedOption}
                    options={getPackageTypeOptions()}
                    onChange={(val) => {
                        setValue(val.value)
                    }}
                    onBlur={onBlur}
                />
                {errors[`data.${index}.${id}`] &&
                    <div className="alert alert-warning" >
                        {errors[`data.${index}.${id}`]}
                    </div>
                }
            </>
        );
    }


    if (id == 'product_type') {
        let selectedOption = '';

        const myOption = getProductTypeOptions().find(item => item.value == initialValue)

        if(myOption){
            selectedOption = myOption;
        }


        return (
            <>
                <Select
                    defaultValue={selectedOption}
                    options={getProductTypeOptions()}
                    onChange={(val) => {
                        setValue(val.value)
                    }}
                    onBlur={onBlur}
                />
                {errors[`data.${index}.${id}`] &&
                    <div className="alert alert-warning" >
                        {errors[`data.${index}.${id}`]}
                    </div>
                }
            </>
        );
    }

    if (id == 'pickup_date') {

        return (
            <>
                <input className="form-control" type={'date'} value={value} onChange={onChange} onBlur={onBlur} />

                {errors[`data.${index}.${id}`] &&
                    <div className="alert alert-warning" >
                        {errors[`data.${index}.${id}`]}
                    </div>
                }
            </>
        )
    }

    if (id == 'delivery_type' || id == 'pickup_type') {
        let selectedOption = null;

        if (initialValue == 'residential') {
            selectedOption = {
                label: "residential",
                value: "residential"
            };
        }

        if (initialValue == 'commercial') {
            selectedOption = {
                label: "commercial",
                value: "commercial"
            }
        }
        return (
            <>
                <Select
                    defaultValue={selectedOption}
                    options={[
                        {
                            label: "residential",
                            value: "residential"
                        },
                        {
                            label: "commercial",
                            value: "commercial"
                        }
                    ]}
                    onChange={(val) => {
                        setValue(val.value)
                    }}
                    onBlur={onBlur}
                />
                {errors[`data.${index}.${id}`] &&
                    <div className="alert alert-warning" >
                        {errors[`data.${index}.${id}`]}
                    </div>
                }
            </>
        );
    }

    if (id == 'delivery_country' || id == 'pickup_country') {
        let selectedOption = null;
        getCountryListJson().forEach(item => {
            if (item.value == initialValue) {
                selectedOption = item;
            }
        });
        return (
            <>
                <Select
                    defaultValue={selectedOption}
                    options={getCountryListJson()}
                    onChange={(val) => {
                        setValue(val.value)
                    }}
                    onBlur={onBlur}
                />
                {errors[`data.${index}.${id}`] &&
                    <div className="alert alert-warning" >
                        {errors[`data.${index}.${id}`]}
                    </div>
                }
            </>
        )
    }

    if (
        (id == 'delivery_state' && row.values.delivery_country == 'AU') || 
        (id == 'pickup_state' && row.values.pickup_country == 'AU')
    ) {
        let selectedOption = null;
        getAuStateListJson().forEach(item => {
            if(item.value == value){
                selectedOption = item;
            }
        })
        return (
            <>
                <Select
                    defaultValue={selectedOption}
                    options={getAuStateListJson()}
                    onChange={(val) => {
                        setValue(val.value)
                    }}
                    onBlur={onBlur}                    
                />

                {
                    errors[`data.${index}.${id}`] &&
                    <div className="alert alert-warning" >
                        {errors[`data.${index}.${id}`]}
                    </div>
                }
            </>
        )
    }

    return (
        <>
            <input className="form-control" value={value} onChange={onChange} onBlur={onBlur} />

            {errors[`data.${index}.${id}`] &&
                <div className="alert alert-warning" >
                    {errors[`data.${index}.${id}`]}
                </div>
            }
        </>
    )
}