import React from "react";

export default function TermsCondition() {
    return(
    <>
        <section>
            <div className="container mt-5 mb-5">
                <h2 className="new-h2-sty1 text-center"> Terms and Conditions </h2>
                <p className="p-sty1">1.  Quotes are valid for 30 days. </p>
                <p className="p-sty1">2.  Prices will only be valid upon receipt of a signed credit application and       CouriersPlease has agreed to extend credit.			</p>
                <p className="p-sty1">3.  All rates quoted are exclusive of GST as well as any additional charges   (such as, but not limited to, fuel surcharge,  insurance or any other charges).	</p>
                <p className="p-sty1">4.  The CouriersPlease standard fuel surcharge applies to all Base and Kilo type service codes but only to Interstate and Country destinations.  The CouriersPlease standard fuel surcharge does not apply to same state metro or same state regional locations serviced directly by CouriersPlease Franchisees, nor does it apply to Per Item type service codes, eg. P (Parcel)/Y(Satchel) rates.	</p>
                <p className="p-sty1">5.  The Couriers Please standard fuel surcharge is variable and subject to adjustment monthly.</p>
                <p className="p-sty1">6.  Rates are based on the volumes provided and Couriers Please reserves the right to review and adjust pricing if these volumes do not eventuate. 	</p>
                <p className="p-sty1">7.  Rates are reviewed annually.		</p>
                <p className="p-sty1">8.  All consignments are subject to electronic cube and reweigh check.</p>
                <p className="p-sty1">9.  Consignments will be billed at the greater of dead weight or volumetric weight, based on a cubic allowance of 250 kg per cubic metre, and rounded up to the nearest whole kilogram.</p>
                <p className="p-sty1">10. If the goods are re-weighed or re-measured and are found to have weight under-declared, the Customer shall pay any additional freight, fuel surcharge, insurance and other charges applicable to the correct weight as determined by the Carrier, and in accordance with the Customer’s base and kilo rate schedule.</p>
                <p className="p-sty1">11. Parcel or satchel rate card consignments that exceed the item weight and do not have a base and kilo rate will be billed at our standard base & kilo tariff.	</p>
                <p className="p-sty1">12. Customer generated EDI Labels are to meet CouriersPlease minimum requirements.	</p>
                <p className="p-sty1">13. If goods are not ready for collection by the Carrier at the designation time or place requested by the Customer, or  the freight is unsuitable or outside of the CouriersPlease freight profile, the Customer will pay to the Carrier a  futile fee of $20.	</p>
                <p className="p-sty1">14. Prices will be billed in accordance with our latest Location  Master. (Please refer to our website for the latest version)	</p>
                <p className="p-sty1">15. An account service fee of $3 will apply to each weekly invoice issued.	</p>
                <p className="p-sty1">16. Due to the continued escalation of fuel prices and the pressure this is putting on our franchisees and our wider business we need to apply an “Extraordinary Fuel Charge” of 2.5% on all domestic services with effect Monday 11th April. This surcharge is a temporary measure and will be reviewed each month, based on the previous 30 days average fuel price.  Any fuel excise relief as a result of Government intervention will be reflected in our calculations.  The surcharge will be removed once the fuel index reduces to $1.45 including GST or less (refer to Terminal Gate Price as per Australian Institute of Petroleum website).  "Aviation Security and Dangerous Goods Declaration" The sender acknowledges that freight may be carried by air and will be subject to aviation security and clearing procedures, and the sender declares that the freight does not contain any dangerous or prohibited goods, explosives or incendiary devices   A false declaration is a criminal offence.</p>
                <br />
                <br />
            </div>
        </section>
    </>
    )
}