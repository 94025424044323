import { useFormik } from "formik";
import { useState } from "react";
import Api from "../lib/Api";
import Loading from "./Loading";
import Warning from "./util/Warning";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import handleFormikBackendValidation from "../lib/handleFormikBackendValidation";
import paymentGatewayEnums from "../enums/paymentGatewayEnums";
import { useRecoilState } from "recoil";
import currentBusinessState from "../atoms/currentBusinessState";
import currentUserState from "../atoms/currentUserState";

export default function WalletTopupForm({ wallet }) {

    const [loading, setLoading] = useState(false)

    const [stripe, setStripe] = useState(null)

    const [stripeOption, setStripeOption] = useState(null);

    const [transaction, setTransaction] = useState(null)

    const [currentUser, setCurrentUser] = useRecoilState(currentUserState)
    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState)

    const handleSubmit = async (values, actions) => {
        try {
            setLoading(true)
            if(values.gateway != paymentGatewayEnums.stripe){
                delete values.name;
                delete values.business;
            }
            const res = await Api.post(`/wallet/${wallet.id}`, values);
            if (res.success) {
                if (res.gateway == paymentGatewayEnums.stripe) {
                    setStripe(loadStripe(res.data.api_key));
                    setStripeOption({
                        clientSecret: res.data.client_secret
                    });
                    setTransaction(res.data.wallet_transaction);

                    setLoading(false)
                }

                if(res.gateway == paymentGatewayEnums.paypal){
                    let url = null;
                    res.data.links.forEach(item => {
                        if(!url){
                            if(item.rel == 'approve'){
                                url = item.href
                            }
                        }
                    });

                    if(!url){
                        alert("Something went wrong")
                    }
                    else{
                        window.location.href = `${url}`
                    }
                }
            }
            else {
                if (res.errors) {
                    handleFormikBackendValidation(res, actions);
                }
                else {
                    alert(res.message ? `${res.message}` : "Oops, something went wrong")
                }
                setLoading(false)
            }
            

        } catch (error) {
            alert('Oops, Something went Wrong')
            setLoading(false)
        }
    }


    const formik = useFormik({
        initialValues: {
            amount: 0.00,
            gateway: paymentGatewayEnums.paypal,
            name: currentUser.name,
            business: currentBusiness.name
        },
        onSubmit: handleSubmit
    });

    if (stripe && stripeOption && transaction) {
        return (
            <Elements stripe={stripe} options={stripeOption}>
                <CheckoutForm
                    transaction={transaction}
                />
            </Elements>
        )
    }


    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                    <label>Amount (in {process.env.REACT_APP_CURRENCY})</label>
                    <input type={'number'} step={0.01} className="form-control" name="amount"
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.errors.amount && formik.touched.amount ? <Warning message={formik.errors.amount} /> : null}
                </div>

                <div className="form-group">
                    <label>Gateway</label>
                    <select className="form-conrol" name="gateway"
                        value={formik.values.gateway}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    >
                        <option value={paymentGatewayEnums.paypal}>{paymentGatewayEnums.paypal}</option>
                        <option value={paymentGatewayEnums.stripe}>{paymentGatewayEnums.stripe}</option>
                    </select>

                    {formik.errors.gateway && formik.touched.gateway ? <Warning message={formik.errors.gateway} /> : null}
                </div>

                {formik.values.gateway == paymentGatewayEnums.stripe &&
                    <>
                        <div className="form-group">
                            <label>Name</label>
                            <input type={'text'} className="form-control" name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.errors.name && formik.touched.name ? <Warning message={formik.errors.name} /> : null}
                        </div>

                        <div className="form-group">
                            <label>Business</label>
                            <input type={'text'} className="form-control" name="business"
                                value={formik.values.business}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.errors.business && formik.touched.business ? <Warning message={formik.errors.business} /> : null}
                        </div>
                    </>
                }

                {loading &&
                    <Loading />
                }

                {!loading &&
                    <button className="btn btn-primary">Top Up</button>
                }

            </form>
        </div>
    )
}