import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import currentBusinessState from "../../atoms/currentBusinessState";
import Loading from "../../components/Loading";
import Modal from "../../components/util/Modal";
import WalletTopupForm from "../../components/WalletTopupForm";
import WalletTransactions from "../../components/WalletTransactions";
import Api from "../../lib/Api";
import CsvDownloadButton from 'react-json-to-csv';
import WalletPrintableArea from "../../components/WalletPrintableArea";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import ReactPDF from "@react-pdf/renderer";
import { useNavigate } from "react-router-dom";

export default function WalletPage() {
    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [showAddForm, setShowAddForm] = useState(false);

    const [wallet, setWallet] = useState(null)

    const [filterOptions, setFilterOptions] = useState({
        from_date: "",
        to_date: "",
        payment_type: ""
    });


    const [csvData, setCsvData] = useState([]);



  

    useEffect(() => {
        if (wallet) {
            const newCsvData = [];
            wallet.transactions.forEach(item => {
                newCsvData.push({
                    ID: item.id,
                    description: item.description,
                    amount: parseInt(item.amount).toFixed(2),
                    type: item.is_credit ? "Credit" : "Debit",
                    gateway: item.payment_gateway,
                    gateway_ref: item.payment_id,
                    payment_confirmed_at: item.paid_at
                })
            });

            setCsvData(newCsvData)
        }
        else {
            setCsvData([])
        }
    }, [wallet])

    useEffect(() => {
        if (currentBusiness && currentBusiness.id) {
            try {
                setLoading(true)
                Api.get(`/${currentBusiness.id}/wallet`, filterOptions)
                    .then(res => {
                        if (res.success) {
                            setWallet(res.wallet);
                            setLoading(false)
                        }
                        else {
                            alert(res.message ? `${res.message}` : 'Oops, something went wrong');
                        }
                    })
            } catch (error) {
                alert('Oops, something went wrong');
            }

        }
        else {
            setLoading(true)
            setWallet(null)
        }
    }, [currentBusiness, filterOptions])

    const printWallet = () => {
        const url = `/dashboard/wallet/${currentBusiness.id}/print?filterConfig=${JSON.stringify(filterOptions)}`
        navigate(url)
        
    }



    if (loading || !wallet) {
        return (
            <div className="login-form-container justify-around vh-100">
                <Loading />
            </div>
        )
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">My Wallet</h3>

                    <div className="card-tools">
                        <button className="btn btn-primary"
                            onClick={() => {
                                setShowAddForm(!showAddForm)
                            }}
                        >Top Up</button>


                        <CsvDownloadButton
                            className="btn btn-secondary"
                            filename="transactions.csv"
                            data={csvData}
                        >DownloadCsv</CsvDownloadButton>

                        <button className="btn btn-secondary" onClick={printWallet}>Print</button>

                    </div>
                </div>

                <WalletPrintableArea
                    wallet={wallet}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    csvData={csvData}

                />


            </div>

            {showAddForm &&
                <Modal
                    title={"Add Balance"}
                    close={() => {
                        setShowAddForm(false)
                    }}
                >
                    <WalletTopupForm wallet={wallet} />
                </Modal>
            }
        </>
    )
}