import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import availableBusinessesState from "../../../atoms/availableBusinessesState";
import currentUserState from "../../../atoms/currentUserState";
import BusinessCreateEdit from "../../../components/BusinessCreateEdit";
import CreateBusinessForSomeoneElse from "../../../components/CreateBusinessForSomeoneElse";
import Api from "../../../lib/Api";

export default function BusinessIndex() {

    const [availableBusiness, setAvailableBusiness] = useRecoilState(availableBusinessesState);

    const [currentUser, setCurrentUser] = useRecoilState(currentUserState)

    const [editBusiness, setEditBusiness] = useState(null)

    const [showPopup, setShowPopup] = useState(false)

    const [allowCreatingBusiness, setAllowCreatingBusiness] = useState(false)

    const [showSomeoneElsesBusinessPopup, setShowSomeoneElsesBusinessPopup] = useState(false)

    const nav = useNavigate();


    useEffect(() => {
        if(currentUser && currentUser.id && availableBusiness && availableBusiness.length){
            const userId = availableBusiness.id;
            let canInvite = false;

            availableBusiness.forEach(business => {
                if(business.owner_id == userId){
                    canInvite(true)
                }
            });

            setAllowCreatingBusiness(canInvite)
        }
        else{
            setAllowCreatingBusiness(false)
        }
    }, [currentUser, availableBusiness])



    const addBusiness = () => {
        setEditBusiness(null)
        setShowSomeoneElsesBusinessPopup(false)
        setShowPopup(true)
    }

    const createBusinessForSomeoneElse = () => {
        setEditBusiness(null)
        setShowSomeoneElsesBusinessPopup(true)
        setShowPopup(false)
    }

    const closeForm = () => {
        setEditBusiness(null)
        setShowPopup(false)
        setShowSomeoneElsesBusinessPopup(false)
    }

    const editBusinessFunction = (business) => {
        setEditBusiness(business)
        setShowSomeoneElsesBusinessPopup(false)
        setShowPopup(true)
    }

    const leaveBusiness = async (business) => {
        if (window?.confirm(`Are you sure you want to leave business : ${business.name}`) === true) {
            try {
                const res = await Api.post(`/my-business/${business.id}/leave`);
                if (res.success) {
                    setAvailableBusiness(res.business)
                }
                else {
                    alert(res.message ? `${res.message}` : "Oops, something went wrong");
                }
            } catch (error) {
                alert("Oops, something went wrong");
            }
        }
    }

    const deleteBusiness = async (business) => {
        if(business.is_main){
            alert("Can't delete this business");
            return;
        }
        if (window?.confirm(`Are you sure you want to delete this business : ${business.name}?`) === true) {
            const res = await Api.delete(`/my-business/${business.id}`);
            console.log(res);
            if (res.success) {
                setAvailableBusiness(res.business);
            }
            else {
                alert(res.message ? `${res.message}` : 'Oops, something went wrong');
            }
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">My Businesses</h3>

                <div className="card-tools">
                    <button className="btn btn-primary me-2" onClick={addBusiness}>Add New</button>
                    <button className="btn btn-info" onClick={createBusinessForSomeoneElse}
                        disabled={allowCreatingBusiness}
                        title={allowCreatingBusiness ? '' : "Only Business Owners Can Invite Business"}
                    >Create Business for Someone Else</button>
                </div>
            </div>
            <div className="card-body">
                <div className="">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Name</th>
                                <th scope="col">Owner</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {availableBusiness.map(business => {
                                return (
                                    <tr key={business.id}>
                                        <th scope="row">{business.id}</th>
                                        <td>{business.name}</td>
                                        <td>{business.owner.name}</td>
                                        <td>
                                            {business.owner_id == currentUser.id &&
                                                <div>
                                                    <button className="btn btn-primary" onClick={() => {
                                                        editBusinessFunction(business)
                                                    }}>Edit</button>
                                                    <button className="btn btn-danger" 
                                                        onClick={() => {
                                                            deleteBusiness(business)
                                                        }}
                                                        
                                                        disabled={business.is_main}
                                                    >Delete</button>

                                                    <button className="btn settings-btn" 
                                                        onClick={() => {
                                                            nav(`/dashboard/${business.id}/settings`)
                                                        }}
                                                    >Settings</button>

                                                </div>
                                            }

                                            {business.owner_id != currentUser.id &&

                                                <button className="btn btn-warning" onClick={() => {
                                                    leaveBusiness(business)
                                                }}>Leave</button>


                                            }
                                        </td>
                                    </tr>
                                )
                            })

                            }
                        </tbody>

                    </table>
                </div>

            </div>
            {showPopup &&
                <BusinessCreateEdit
                    closeForm={closeForm}
                    business={editBusiness}
                    setAvailableBusiness={setAvailableBusiness}
                />
            }
            {showSomeoneElsesBusinessPopup &&
                <CreateBusinessForSomeoneElse
                    closeForm={closeForm}
                    business={editBusiness}
                    setAvailableBusiness={setAvailableBusiness}
                />
            }
        </div>
    );
}