import { useEffect } from "react";
import { useRecoilState } from "recoil";
import csrfCookieState from "../../atoms/csrfCookieState";
import Api from "../../lib/Api";

export default function EnsureCsrfCookie(){
    const [isCsrfCookieSet, setIsCsrfCookieSet] = useRecoilState(csrfCookieState);

    useEffect(() => {
        if(!isCsrfCookieSet){
            Api.get('/../../sanctum/csrf-cookie')
                .then(() => {
                    setIsCsrfCookieSet(true)
                })
        }
    }, [isCsrfCookieSet])

    return (
        <></>
    )
}