import { useFormik } from "formik"
import { useState } from "react";
import Api from "../lib/Api";
import getAuStateListJson from "../lib/getAuStateListJson";
import getCountryListJson from "../lib/getCountryListJson";
import handleFormikBackendValidation from "../lib/handleFormikBackendValidation";
import billingAddressValidation from "../validation/billingAddressValidation"
import Loading from "./Loading";
import FormikInputBootstrap from "./util/FormikInputBootstrap";

export default function BillingAddressForm({ business, callback = () => {} }) {

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values, actions) => {
        if(business && business.id){
            setLoading(true);
            const res = await Api.post(`/${business.id}/billingAddress`, values);

            if(res.success){
                setLoading(false);
                callback(res);
            }
            else{
                if(res.errors){
                    handleFormikBackendValidation(res,actions);
                    setLoading(false);
                }
                else{
                    alert(res.message ?? "something Went wrong");
                    setLoading(false)
                }
            }
        }
        else{
            alert("Please Choose a business");
        }
    }

    const formik = useFormik({
        initialValues: {
            'name': '',
            'phone': '',
            'email': '',
            'add1': '',
            'add2': '',
            'add3': '',
            'city': '',
            'zip': '',
            'state': '',
            'country': '',
            'tax_code': ''
        },
        onSubmit: handleSubmit,
        validationSchema: billingAddressValidation
    });

    return (
        <div className="card pt-5 pb-4">
            <div className="container">
                <h4 className="text-center"><strong>Before you can generate invoice, you need to set a billing address</strong></h4>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <FormikInputBootstrap
                                formik={formik}
                                inputName={'name'}
                                label={'Name'}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormikInputBootstrap
                                formik={formik}
                                inputName={'email'}
                                label={'Email'}
                            />
                        </div>

                        <div className="col-md-6">
                            <FormikInputBootstrap
                                formik={formik}
                                inputName={'phone'}
                                label={'Phone'}
                                type={'tel'}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormikInputBootstrap
                                formik={formik}
                                inputName={'add1'}
                                label={'Address 1'}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormikInputBootstrap
                                formik={formik}
                                inputName={'add2'}
                                label={'Address 2 (optional)'}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormikInputBootstrap
                                formik={formik}
                                inputName={'add3'}
                                label={'Address 3 (optional)'}
                            />
                        </div>

                        <div className="col-md-6">
                            <FormikInputBootstrap
                                formik={formik}
                                inputName={'city'}
                                label={'City'}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormikInputBootstrap
                                formik={formik}
                                inputName={'zip'}
                                label={'Zip Code'}
                            />
                        </div>

                        <div className="col-md-6">
                            {formik.values.country == 'AU' &&
                                <FormikInputBootstrap
                                    formik={formik}
                                    inputName={'state'}
                                    label={'State'}
                                    type={'select'}
                                    options={getAuStateListJson()}
                                />
                            }

                            {formik.values.country != 'AU' &&
                                <FormikInputBootstrap
                                    formik={formik}
                                    inputName={'state'}
                                    label={'State'}
                                />
                            }
                        </div>
                        <div className="col-md-6">
                            <FormikInputBootstrap
                                formik={formik}
                                inputName={'country'}
                                label={'Country'}
                                type={'select'}
                                options={getCountryListJson()}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormikInputBootstrap
                                formik={formik}
                                inputName={'tax_code'}
                                label={'Tax Code (Optional)'}
                                
                            />
                        </div>
                    </div>

                    <div className="w-100 text-center">
                        {!loading &&
                            <button className="btn btn-primary" type="submit">Save</button>
                        }
                        {loading && 
                            <Loading />
                        }
                    </div>

                </form>
            </div>
        </div>
    )
}