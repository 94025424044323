import { Grid, _ } from 'gridjs-react'
import "gridjs/dist/theme/mermaid.css";
import { useMemo } from 'react';
import TableEdit from './ImportedShipmentTableEdit';
import { useTable } from 'react-table'
import Loading from './Loading';

export default function ImportedShipmentTable({ csvData, updateTableData, saveData, validateResult, isValidated, setValidatedFalse, loading }) {
    const columns = useMemo(
        () => [
            {
                Header: 'delivery_name',
                accessor: 'delivery_name'
            },
            {
                Header: 'delivery_phone',
                accessor: 'delivery_phone'
            },
            {
                Header: 'delivery_email',
                accessor: 'delivery_email'
            },
            {
                Header: 'delivery_company',
                accessor: 'delivery_company'
            },
            {
                Header: 'delivery_add1',
                accessor: 'delivery_add1'
            },
            {
                Header: 'delivery_add2',
                accessor: 'delivery_add2'
            },
            {
                Header: 'delivery_add3',
                accessor: 'delivery_add3'
            },
            {
                Header: 'delivery_city',
                accessor: 'delivery_city'
            },
            {
                Header: 'delivery_state',
                accessor: 'delivery_state'
            },
            {
                Header: 'delivery_country',
                accessor: 'delivery_country'
            },
            {
                Header: 'delivery_zip',
                accessor: 'delivery_zip'
            },
            {
                Header: 'pickup_name',
                accessor: 'pickup_name'
            },
            {
                Header: 'pickup_phone',
                accessor: 'pickup_phone'
            },
            {
                Header: 'pickup_email',
                accessor: 'pickup_email'
            },
            {
                Header: 'pickup_company',
                accessor: 'pickup_company'
            },
            {
                Header: 'pickup_add1',
                accessor: 'pickup_add1'
            },
            {
                Header: 'pickup_add2',
                accessor: 'pickup_add2'
            },
            {
                Header: 'pickup_add3',
                accessor: 'pickup_add3'
            },
            {
                Header: 'pickup_city',
                accessor: 'pickup_city'
            },
            {
                Header: 'pickup_state',
                accessor: 'pickup_state'
            },
            {
                Header: 'pickup_country',
                accessor: 'pickup_country'
            },
            {
                Header: 'pickup_zip',
                accessor: 'pickup_zip'
            },
            {
                Header: 'pickup_date',
                accessor: 'pickup_date'
            },
            {
                Header: 'content_description',
                accessor: 'content_description'
            },
            {
                Header: 'package_value',
                accessor: 'package_value'
            },
            {
                Header: 'package_weight',
                accessor: 'package_weight'
            },
            {
                Header: 'package_length',
                accessor: 'package_length'
            },
            {
                Header: 'package_height',
                accessor: 'package_height'
            },
            {
                Header: 'package_width',
                accessor: 'package_width'
            },
            {
                Header: 'package_type',
                accessor: 'package_type'
            },
            {
                Header: 'product_type',
                accessor: 'product_type'
            },
            {
                Header: 'delivery_type',
                accessor: 'delivery_type'
            },
            {
                Header: 'pickup_type',
                accessor: 'pickup_type'
            }
        ],
        [])

    const data = useMemo(() => csvData, [csvData])

    const defaultColumn = {
        Cell: TableEdit
    };

    const tableInstance = useTable({ columns, data, defaultColumn, autoResetPage: false, updateTableData })

    const {

        getTableProps,

        getTableBodyProps,

        headerGroups,

        rows,

        prepareRow,

    } = tableInstance

    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">Import Excel</h3>

                <div className="card-tools">
                </div>
            </div>
            <div className="card-body">
                <div className="container-fluid">
                    <div className='table-container'>
                        {!isValidated &&
                            <table className='table table-striped' {...getTableProps()}>

                                <thead>

                                    {// Loop over the header rows

                                        headerGroups.map(headerGroup => (

                                            // Apply the header row props

                                            <tr {...headerGroup.getHeaderGroupProps()}>

                                                {// Loop over the headers in each row

                                                    headerGroup.headers.map(column => (

                                                        // Apply the header cell props

                                                        <th {...column.getHeaderProps()}>

                                                            {// Render the header

                                                                column.render('Header')}

                                                        </th>

                                                    ))}

                                            </tr>

                                        ))}

                                </thead>

                                {/* Apply the table body props */}

                                <tbody {...getTableBodyProps()}>

                                    {// Loop over the table rows

                                        rows.map(row => {

                                            // Prepare the row for display

                                            prepareRow(row)

                                            return (

                                                // Apply the row props

                                                <tr {...row.getRowProps()}>

                                                    {// Loop over the rows cells

                                                        row.cells.map(cell => {

                                                            // Apply the cell props

                                                            return (

                                                                <td {...cell.getCellProps()}>

                                                                    {// Render the cell contents

                                                                        cell.render('Cell')}

                                                                </td>

                                                            )

                                                        })}

                                                </tr>

                                            )

                                        })}

                                </tbody>

                            </table>
                        }

                        {isValidated &&
                            <div >
                                <p>Can not edit data after validation. If you edit, you may need to validate again</p>
                                <button type='button' className='btn btn-primary' onClick={setValidatedFalse}>Edit</button>

                            </div>
                        }

                    </div>

                    {!loading &&
                        <>
                            {!isValidated &&
                                <button className='btn btn-primary' onClick={validateResult}
                                >Validate Entry</button>
                            }

                            {isValidated &&
                                <button className='btn btn-success' onClick={saveData}>Save</button>
                            }
                        </>
                    }

                    {loading && 
                        <Loading />
                    }






                </div>

            </div>
        </div>

    )
}