import { useState } from "react"
import Api from "../lib/Api";
import Loading from "./Loading";
import Warning from "./util/Warning";

export default function DocumentEditFormForOrder({ doc, callback = () => { } }) {
    const [loading, setLoading] = useState(false)

    const [errors, setErrors] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const res = await Api.filePost(`/update_order_doc/${doc.id}`, new FormData(e.target));
            if (res.success) {
                callback()
            }
            else {
                if (res.errors) {
                    const myErrors = [];
                    for (const x in res.errors) {
                        if (Object.hasOwnProperty.call(res.errors, x)) {
                            const errList = res.errors[x];
                            myErrors.push(errList[0]);
                        }
                    }
                    setErrors(myErrors);
                }
            }

            setLoading(false)
        }

        catch(err){
            alert(err.message ?? "Something Went Wrong");
            setLoading(false)
        }
        
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label>{doc.name}</label>
                <input type={doc.type} name="value" className="form-control" />
            </div>
            {errors.map(e => <Warning message={e} />)}
            {!loading &&
                <button className="btn btn-primary">Save</button>
            }
            {loading && 
                <Loading />
            }
            
        </form>
    )
}