import { useFormik } from "formik";
import { useState } from "react";
import Api from "../lib/Api";
import handleFormikBackendValidation from "../lib/handleFormikBackendValidation";
import Loading from "./Loading";
import Modal from "./util/Modal";
import Warning from "./util/Warning";

export default function BusinessCreateEdit({ closeForm, business = null, setAvailableBusiness }) {
    const [loading, setLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            id: business && business.id ? business.id : '',
            name: business && business.name ? business.name : '',
        },
        onSubmit: async (values, actions) => {
            setLoading(true)
            try {
                
                const res = await Api.post('/my-business', values);
                if (res.success) {
                    setAvailableBusiness(res.business)
                    closeForm()
                }
                else {
                    if (res.errors) {
                        handleFormikBackendValidation(res, actions)
                        setLoading(false)
                    }
                    else{
                        alert(res.message ? `${res.message}` : "Oops, Something went wrong");
                        setLoading(false)
                    }
                }
            }
            catch (error) {
                console.log(error);
                alert('Oops, something went wrong')
                setLoading(false)
            }
        }
    })
    return (
        <Modal
            close={closeForm}
            title={business ? "Edit Business" : "Create Business"}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                    <label>Name</label>
                    <input className="form-control" name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                    />
                    {formik.errors.name && formik.touched.name ? <Warning message={formik.errors.name} /> : null}

                </div>
                {loading &&
                    <Loading />
                }

                {!loading &&
                    <button type="submit" className="btn btn-primary">Save</button>
                }
                
            </form>
        </Modal>
    )
}