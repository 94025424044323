import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Api from "../../lib/Api";
import handleFormikBackendValidation from "../../lib/handleFormikBackendValidation";
import Loading from "../Loading";
import Warning from "../util/Warning";

export default function BusinessInviteAccept({ invite, code }) {

    const [isValidated, setIsValidated] = useState(false);
    const [loading, setLoading] = useState(true);

    const [message, setMessage] = useState(null);

    const [invitee, setInvitee] = useState(null)

    const [formLoading, setFormLoading] = useState(false);


    useEffect(() => {
        Api.post(`/invite/${invite.id}/check`, {
            code: code
        })
            .then(res => {
                if (res.success) {
                    setLoading(false);
                    setIsValidated(true);

                    setInvitee(res.user);
                }
                else {
                    setLoading(false);
                    setIsValidated(false);
                    setMessage(res.message ?? "Something Went Wrong");
                }
            })
    }, []);

    const acceptInvite = async (values, actions) => {
        setFormLoading(true)
        try {
            values = { ...values, code };
            const res = await Api.post(`/invite/${invite.id}/accept`, values);

            if (res.success) {
                window.location.href = '/'
                // setFormLoading(false)
            }
            else {
                if (res.errors) {
                    handleFormikBackendValidation(res, actions);
                    setFormLoading(false)
                }
                else {
                    alert(res.message ?? "Something went wrong");
                    setFormLoading(false)
                }
            }
        } catch (error) {
            setFormLoading(false)
        }

    }

    const formik = useFormik({
        initialValues: {
            name: '',
            email: invite.email,
            password: '',
            password_confirmation: '',
            business_name: ''
        },
        onSubmit: acceptInvite
    })


    if (loading) {
        return <Loading />
    }

    if (!isValidated) {
        return (
            <div className="p-5">
                <Warning message={message ?? "Something went wrong!"} />
            </div>
        )
    }

    return (
        <div className="container p-5">
            <div>
                <h5>{invite.inviter.name} has requested you to add a business.</h5>

                <form onSubmit={formik.handleSubmit} className="p-2">
                    <div className="row">
                        {!invite.invitee &&
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type={'text'} name='name' className="form-control"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                    />
                                </div>
                            </div>
                        }

                        {!invite.invitee &&
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type={'text'} name='email' className="form-control"
                                        value={formik.values.email}
                                        readOnly
                                    />
                                </div>
                            </div>
                        }
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Business Name</label>
                                <input type={'text'} name='business_name' className="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.business_name}
                                />
                            </div>
                        </div>
                        {!invite.invitee &&
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type={'password'} name='password' className="form-control"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                    />
                                </div>
                            </div>
                        }
                        {!invite.invitee &&
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Password Confirmation</label>
                                    <input type={'password'} name='password_confirmation' className="form-control"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password_confirmation}
                                    />
                                </div>
                            </div>
                        }
                    </div>


                    <div className="text-center">
                        {formLoading &&
                            <Loading />
                        }
                        {!formLoading &&
                            <button className="btn btn-primary">Signup And Accept</button>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}