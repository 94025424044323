import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import currentBusinessState from "../atoms/currentBusinessState";
import Api from "../lib/Api";
import Loading from "./Loading";
import Modal from "./util/Modal";
import { Link, useNavigate } from "react-router-dom";
import getInsuranceValues from "../lib/getInsuranceValues";
import dangerousItemsState from "../atoms/dangerousItemsState";
import BusinessDocumentUpdate from "./BusinessDocumentUpdate";
import getReasonsForSending from "../lib/getReasonsForSending";
import courierServicesState from "../atoms/courierServicesState";

export default function BookingModal({ quote, close, shipment, couponCode = '' }) {

    

    const [courierServices, setCourierServices] = useRecoilState(courierServicesState)
    const [currnetBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);

    const [loading, setLoading] = useState(false);

    const [insuranceValue, setInsuranceValue] = useState(0);

    const [dangerousItems, setDangerousItems] = useRecoilState(dangerousItemsState);

    const [seletedDangerousItems, setSeletedDangerousItems] = useState([]);

    const [requiredDocs, setRequiredDocs] = useState(null);

    const [isOutbound, setIsOutbound] = useState(true); 

    const [allowBooking, setAllowBooking] = useState(false);

    const [editDoc, setEditDoc] = useState(null)

    const [reasonForSending, setReasonForSending] = useState(getReasonsForSending()[0]);

    const [gstOnInsurance, setGstOnInsurance] = useState(0);

    console.log("Printing Quote");
    console.log(quote);
    console.log("Printing Shipment");
    console.log(shipment);
    console.log("Courier Services");
    console.log(courierServices);
    console.log("end debug print");

    useEffect(() => {
        setAllowBooking(false)
        Api.post(`/${currnetBusiness.id}/documets_required/${shipment.id}`, {
            isOutbound,
            seletedDangerousItems
        })
            .then(res => {
                if(res.success){
                    if(res.documents){
                        if(res.documents.length < 1){
                            setRequiredDocs(null)
                            setAllowBooking(true)
                        }
                        else{
                            setRequiredDocs(res.documents)
                            setAllowBooking(false)
                        }
                    }
                    else{
                        setRequiredDocs(res.documents)
                        setAllowBooking(true)
                    }
                }
                else{
                    setRequiredDocs(null)
                }
            })
    }, [shipment, seletedDangerousItems, isOutbound])


    useEffect(() => {
        calculateInsuranceGst(insuranceValue);
    }, [insuranceValue, quote, courierServices, shipment])

    const navigate = useNavigate();

    const bookQuotation = () => {
        if (currnetBusiness && currnetBusiness.id) {
            setLoading(true)
            Api.post(`/${currnetBusiness.id}/order/${shipment.id}/${quote.id}`, {
                coupon_code: couponCode,
                insurance: insuranceValue,
                dangerous_items: seletedDangerousItems,
                is_outbound: isOutbound,
                reason_for_sending: reasonForSending
            })
                .then(res => {
                    if (res.id) {
                        // success
                        navigate("/dashboard/shipments", { replace: true });
                    }
                    else {
                        alert(res.message ? `${res.message}` : "Something Went Wrong");
                    }

                    setLoading(false)
                })

                .catch(e => {
                    alert(e.message ? `${e.message}` : "Something Went Wrong")
                })
        }

    }

    const handleChange = (value) => {
        const selected = [...seletedDangerousItems];
        if (selected.includes(value)) {
            selected.splice(selected.indexOf(value), 1);
        }
        else {
            selected.push(value)
        }

        setSeletedDangerousItems(selected)
    }


    const calculateInsuranceGst = (basePrice) => {
        basePrice = parseFloat(basePrice)
        if(!shipment){
            setGstOnInsurance(0);
            return;
        }

        //GST only applicable for Domestic
        if(shipment.delivery_country != "AU" || shipment.pickup_country != 'AU'){
            setGstOnInsurance(0);
            return;
        }

        if(!courierServices.find){
            setGstOnInsurance(0);
            return;
        }

        if(!quote.dtdc_courier_id){
            setGstOnInsurance(0);
            return;
        }

        const selectedCourierService = courierServices.find((service) => service.id == quote.dtdc_courier_id )
    
        if(!selectedCourierService){
            setGstOnInsurance(0);
            return;
        }

        if(selectedCourierService.does_gst_apply && selectedCourierService.does_gst_apply == "no"){
            setGstOnInsurance(0);
            return;
        }

        setGstOnInsurance(parseFloat(basePrice * 10 / 100));
    }


    return (
        <Modal
            close={close}

            title={`Book Quotation : ${quote.name}?`}
        >
            <div>
                <h3 className="text-center are-you-sure">Are you sure? </h3>
                <p className="are-you-sure-text">Book <span>{quote.name}</span> for
                    <span className="text-success"> {((quote.price / 100) + insuranceValue + gstOnInsurance).toFixed(2)} {process.env.REACT_APP_CURRENCY_CODE} </span>?
                    {gstOnInsurance != 0 && 
                        <>
                        <br />
                        <small>Includes Additional {gstOnInsurance} as GST on Insurance</small>
                        </>
                    }
                    
                </p>

                <div className="form-group">
                    <label>Insurance</label>
                    <select className="form-control" value={insuranceValue}
                        onChange={(e) => {
                            setInsuranceValue(parseFloat(e.target.value));
                        }}
                        disabled={shipment.product_type == 'documents'}
                    >
                        <option value={0}>No Insurance</option>
                        {getInsuranceValues().map(insurance => <option value={insurance.premium}>{process.env.REACT_APP_CURRENCY_CODE} {insurance.cover}</option>)}
                    </select>
                    <small>{shipment.product_type == 'documents' ? 'Insurance not availale on Documents' : ''}</small>
                </div>
                
                <div className="form-group">
                    <label>Reason for sending</label>
                    <select className="form-control" value={reasonForSending} 
                        onChange={(e) => {
                            setReasonForSending(e.target.value)
                        }}
                    >
                        {getReasonsForSending().map((reason, key) => <option key={key} value={reason}>{reason}</option>)}
                    </select>
                </div>


                <div className="row">
                    <div className="col-md-6">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSeletedDangerousItems([])
                                }
                            }}

                            checked={seletedDangerousItems.length < 1}
                            disabled={seletedDangerousItems.length < 1}
                        />
                        <label className="form-check-label">
                            No Dangerous Items
                        </label>
                    </div>
                    </div>
                    {dangerousItems.map(item => {
                        return (
                            <div className="col-md-6" key={item.id}>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={item.id}
                                        onChange={() => {
                                            handleChange(item.id)
                                        }}
                                        checked={seletedDangerousItems.includes(item.id)}
                                    />
                                    <label className="form-check-label">
                                        {item.name}
                                    </label>
                                </div>
                            </div>
                        )
                    })}

                </div>

                <div className="row">
                    <div className="form-group">
                        <label>Shipment Type</label>
                        <select className="form-control"
                            value={isOutbound}
                            onChange={(e) => {
                                setIsOutbound(e.target.value)
                            }}
                        >
                            <option value={1}>OutBound</option>
                            <option value={0}>InBound</option>
                        </select>
                    </div>
                </div>
                
                {requiredDocs && 
                
                <div>
                    <p>Additional Documents</p>
                    <span>Please <Link to={`/dashboard/my-documents`}>Fill in the following Documents</Link> to proceed with Order</span>
                    <ul>
                        {requiredDocs.map(doc => <li onClick={() => {
                            setEditDoc(doc)
                        }}><a href="#">{doc.name}</a></li>)}
                    </ul>
                </div>
                }
                {editDoc && 
                    <BusinessDocumentUpdate 
                        setDocuments={(docs) => {
                            const ids = docs.filter(item => {
                                return item.file != null;
                            }).map(item => item.id)
                            const newDocs = requiredDocs.filter(doc => {
                                return !ids.includes(doc.id)
                            })

                            if(newDocs.length < 1){
                                setRequiredDocs(null)
                                setAllowBooking(true)
                            }
                            else{
                                setRequiredDocs(newDocs)
                            }
                        }}
                        document={editDoc}
                        close={() => {
                            setEditDoc(null)
                        }}
                        documentId={editDoc.id}
                        businessId={currnetBusiness.id}
                    />
                }

                <div className="row">

                    {loading &&
                        <Loading />
                    }

                    {!loading &&
                        <>

                            <div className="col-6 text-center book-btn-width">
                                <button className="btn btn-danger" onClick={close}>Cancel</button>
                            </div>
                            <div className="col-6 text-center book-btn-width" >
                                <button className="btn btn-success"
                                    onClick={bookQuotation}
                                    disabled={!allowBooking}
                                >Book</button>
                            </div>
                        </>
                    }

                </div>
            </div>
        </Modal>
    )
}