import Api from "./Api";

export default async function logUserOut(setUser, navigate, url = '/'){
    const res = await Api.post('/logout', {})

    if(res.success){
        setUser({});
        navigate(url);
        if(window && window.location){
            window.location.reload()
        }
    }
    else{
        alert(res.message ?? "Something Went Wrong");
    }

    return res;
}