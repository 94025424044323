import { useEffect } from "react";
import { useRecoilState } from "recoil";
import availableCustomersState from "../../atoms/availableCustomersState";
import currentBusinessState from "../../atoms/currentBusinessState";
import Api from "../../lib/Api";

export default function GetAvailableCustomers(){
    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);

    const [availableCustomers, setAvailableCustomers] = useRecoilState(availableCustomersState)

    useEffect(() => {
        if(currentBusiness && currentBusiness.id){
            setAvailableCustomers([])
            Api.get(`/${currentBusiness.id}/customers`)
                .then(res => {
                    if(res.success){
                        setAvailableCustomers(res.customers);
                    }
                })
        }
        else{
            setAvailableCustomers([]);
        }
    }, [currentBusiness])

}