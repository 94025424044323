import dayjs from "dayjs";
import { useState } from "react";
import { useRecoilState } from "recoil";
import currentBusinessState from "../../atoms/currentBusinessState";
import BillingAddressForm from "../../components/BillingAddressForm";
import Loading from "../../components/Loading";
import Api from "../../lib/Api";

export default function Invoices() {

    const [fromDate, setFormDate] = useState(dayjs().format('YYYY-MM-DD'))
    const [toDate, setToDate] = useState(dayjs().format('YYYY-MM-DD'))

    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);

    const [invoices, setInvoices] = useState([])




    const openInv = (date) => {
        if (!currentBusiness && !currentBusiness.id) {
            return;
        }

        window.open(`/dashboard/invoices/${currentBusiness.id}/?date=${date}`, '_blank')
    }

    const loadInvoices = async (e) => {
        e.preventDefault();
        let startDate;
        let endDate;
        if (dayjs(fromDate).isAfter(dayjs(toDate))) {
            endDate = dayjs(fromDate);
            startDate = dayjs(toDate);
        }
        else {
            endDate = dayjs(toDate);
            startDate = dayjs(fromDate);
        }

        const myInvoices = [];


        const res = await Api.get(`/${currentBusiness.id}/inv_dates`, {
            start_date: fromDate,
            end_date: toDate
        })
        if(res.success){
            res.dates.forEach(day => {
                myInvoices.unshift({
                    id: `B${currentBusiness.id}D${dayjs(day).format('YYYYMMDD')}`,
                    date: dayjs(day)
                })
            })
        }

        setInvoices(myInvoices)
        
    }

    if (!currentBusiness) {
        return <Loading />
    }

    if (!currentBusiness.billing_address) {
        return <BillingAddressForm business={currentBusiness} callback={(res) => {
            setCurrentBusiness(res.business)
        }} />;
    }
    return (
        <>
            <div className="card">
                <form onSubmit={loadInvoices}>
                    <div className="cpn-cd-bg rounded shadow mt-4">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>From Date</label>
                                    <input type={'date'} name="from_date" className="form-control"
                                        onChange={(e) => {
                                            setFormDate(e.target.value)
                                        }}
                                        value={fromDate}
                                        required={true}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input type={'date'} name="to_date" className="form-control"
                                        onChange={(e) => {
                                            setToDate(e.target.value)
                                        }}
                                        value={toDate}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 text-center"><button className="btn btn-primary">Get Invoices</button></div>
                        </div>
                    </div>
                </form>

                <div className="card-body mt-4">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">ID</th>
                                <th scope="col">View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices.length < 1 &&
                                <>
                                    <p className="text-center">No Invoice For Selected Date Range</p>
                                </>
                            }

                            {invoices.length > 0 && currentBusiness && currentBusiness.id &&
                                invoices.map((inv, index) => {
                                    return (
                                        <tr>
                                            <th>{inv.date.format('DD-MMM-YYYY')}</th>
                                            <td>{inv.id}</td>
                                            <td>
                                                <button className="btn btn-primary"
                                                    onClick={() => {
                                                        openInv(inv.date.format('YYYY-MM-DD'))
                                                    }}
                                                >View</button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
           </div>
        </>

    );
}