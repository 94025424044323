import CourierServices from "./CourierServices";
import EnsureCsrfCookie from "./EnsureCsrfCookie";
import GetAvailableBusinesses from "./GetAvailableBusinesses";
import GetAvailableCustomers from "./GetAvailableCustomers";
import GetAvailableDangerousItem from "./GetAvailableDangerousItem";
import GetCurrentUser from "./GetCurrentUser";

export default function StateManager() {
    return (
        <>
            <EnsureCsrfCookie />
            <GetCurrentUser />
            <GetAvailableBusinesses />
            <CourierServices />
            <GetAvailableCustomers />
            <GetAvailableDangerousItem />
        </>
    )
}