import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil"
import availableBusinessesState from "../atoms/availableBusinessesState";
import courierServicesState from "../atoms/courierServicesState"
import currentBusinessState from "../atoms/currentBusinessState";
import Api from "../lib/Api";
import convertServerErrorToArray from "../lib/convertServerErrorToArray";
import getCourierServicesRequiringIndividualQuote from "../lib/getCourierServicesRequiringIndividualQuote";
import getQuotation from "../lib/getQuotation";
import getQuoteForShipment from "../lib/getQuoteForShipment";
import prepareRequestForQuotation from "../lib/prepareRequestForQuotation";
import Loading from "./Loading";
import QuotaionContainer from "./QuotaionContainer";
import ShipmentEditForm from "./ShipmentEditForm";
import SingleQuote from "./SingleQuote";

export default function ShipmentBookingForm({ shipment, setShipment, publicCoupons }) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);

    const [availableBusiness, setAvailableBusiness] = useRecoilState(availableBusinessesState)

    const [issuesWithShipment, setIssuesWithShipment] = useState(null)

    const [courierServices, setCourierServices] = useRecoilState(courierServicesState)

    const [quotes, setQuotes] = useState([]);

    const [couponCode, setCouponCode] = useState('');


    const updateCoupon = (code) => {
        setCouponCode(code)
        if (quotes.length < 1) {
            return;
        }

        Api.post(`/${currentBusiness.id}/refresh_coupon_code/${shipment.id}`, {
            coupon_code: code
        })
            .then(res => {
                if (res.success) {
                    setQuotes(res.quotes);
                }
            })
    }

    
    const getQuotes = async () => {
        try {
            setQuotes([]);
            setLoading(true)
            const res = await getQuotation(shipment, currentBusiness.id);
            if (res.errors) {
                setIssuesWithShipment(res);
                setLoading(false)
            }
            if (res.success) {
                let count = 0;
                let multipleQuoteDone = false;
                const quotation = res.quotation;
                const courierServicesRequiringIndividualQuote = getCourierServicesRequiringIndividualQuote(courierServices)

                let i = 0;
                courierServicesRequiringIndividualQuote.forEach((service) => {
                    getQuoteForShipment(shipment, service, quotation, currentBusiness.id, couponCode)
                        .then(recievedQuote => {
                            setQuotes(recievedQuote);
                            count++
                            if (count >= courierServicesRequiringIndividualQuote.length && multipleQuoteDone) {
                                setLoading(false)
                            }
                        })
                        .catch(error => {
                            count++
                            if (count >= courierServicesRequiringIndividualQuote.length && multipleQuoteDone) {
                                setLoading(false)
                            }
                        })


                })

                // console.log(prepareRequestForQuotation(shipment, quotation));

                Api.post(`/${currentBusiness.id}/get_quote_multiple`, prepareRequestForQuotation(shipment, quotation, couponCode))
                    .then(res => {
                        setQuotes(res)
                        multipleQuoteDone = true;
                        if (count >= courierServicesRequiringIndividualQuote.length && multipleQuoteDone) {
                            setLoading(false)
                        }
                    })
                    .catch(e => {
                        multipleQuoteDone = true;
                        if (count >= courierServicesRequiringIndividualQuote.length && multipleQuoteDone) {
                            setLoading(false)
                        }
                    })

            }
        } catch (error) {
            setLoading(false)
            alert(error.message ? error.message : "Something Went Wrong");
        }

    }

    if (shipment.business_id != currentBusiness.id) {
        let is_safe_to_switch_business = false;

        if (availableBusiness && availableBusiness.length) {
            availableBusiness.forEach(business => {

                if (!is_safe_to_switch_business) {
                    if (business.id == shipment.business_id) {
                        setCurrentBusiness(business)
                        setQuotes([])
                    }
                }
            });
        }

        if (!is_safe_to_switch_business) {
            return (
                <div className="alert alert-danger">
                    Wrong Business Selected
                </div>
            )
        }

    }





    return (
        <>
            {issuesWithShipment &&
                <ShipmentEditForm
                    shipment={shipment}
                    setShipment={setShipment}
                    initialErrors={convertServerErrorToArray(issuesWithShipment)}
                    successCallback={() => {
                        setIssuesWithShipment(null)
                        navigate('/dashboard/shipments');

                    }}
                />
            }

            {!issuesWithShipment &&
                <div className="text-center cpn-cd-bg rounded">
                    {loading &&
                        <Loading />
                    }
                    {!loading &&
                        <input type={'text'} className="form-control apply-cp-code" placeholder={'Coupon Code'}
                            onKeyUp={e => {
                                updateCoupon(e.target.value)
                            }}

                            onChange={e => {
                                updateCoupon(e.target.value)
                            }}

                            value={couponCode}
                        />
                    }
                    {!loading && !issuesWithShipment &&
                        <button className="btn btn-primary me-2" onClick={() => {
                            setIssuesWithShipment([])
                            setQuotes([])
                        }}>Edit Shipment</button>
                    }
                    {!loading &&
                        <button className="btn btn-success" onClick={getQuotes}>Get Quotes</button>
                    }
                </div>
            }

            {!issuesWithShipment && !loading && publicCoupons.length > 0 && 
                <div style={{width: '100%', maxWidth: '100%', overflowX: 'auto', padding: '1rem', display: 'block ruby'}}>
                    {publicCoupons.map(coupon => {
                        return (
                            <div className="" style={{
                                maxWidth: '300px',
                                width: '100%',
                                padding: '1rem',
                                background: 'white',
                                borderRadius: '1rem',
                                marginLeft: '0.5rem',
                                marginRight: '0.5rem',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                updateCoupon(coupon.code)
                            }}
                            >
                                <h1>{coupon.code}</h1>
                                <p>{coupon.description}</p>
                            </div>
                        )
                    })}
                </div>
            }

            <QuotaionContainer
                quotes={quotes}
                shipment={shipment}
                couponCode={couponCode}
            />



        </>

    )
}