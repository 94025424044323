export default function convertServerErrorToArray(res){
    if(res.errors){
        const output = []
        for (const x in res.errors) {
            if (Object.hasOwnProperty.call(res.errors, x)) {
                const element = res.errors[x];
                element.forEach(e => {
                    output.push(e)
                });              
            }
        }
        return output;
    }

    return []
}