import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import availableBusinessesState from "../../../atoms/availableBusinessesState";
import currentBusinessState from "../../../atoms/currentBusinessState";
import Loading from "../../../components/Loading";
import WarehouseAdd from "../../../components/WarehouseAdd";
import WarehouseContainer from "../../../components/WarehouseContainer";
import Api from "../../../lib/Api";

export default function WarehouseIndex() {
    const [availableBusiness, setAvailableBusiness] = useRecoilState(availableBusinessesState);
    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);

    const [warehouses, setWarehouses] = useState(null)
 
    const [showModal, setShowModal] = useState(false);

    const [editWarehouse, setEditWarehouse] = useState(null);


    useEffect(() => {
        if(currentBusiness && currentBusiness.id){
            setWarehouses(null);
            Api.get(`/${currentBusiness.id}/warehouses`)
                .then(res => {
                    if(res.success){
                        setWarehouses(res.warehouses.filter(item => item.deleted_at == null));
                    }
                    else{
                        alert(res.message ? `${res.message}` : "Something went wrong");
                        window.location.reload()
                    }
                })
                .catch(e => {
                    alert(e.message ? `${e.message}` : "Something went wrong");
                    window.location.reload()
                })
        }
    }, [currentBusiness]);

    const deleteWarehouse = (warehouse) => {
        
            if(window.confirm("Are you sure you wnt to remove warehouse : " + warehouse.name)){
                Api.delete(`/${currentBusiness.id}/warehouse/${warehouse.id}`)
                    .then(res => {
                        if (res.success) {

                            handleWarehouseUpdate()
                        }
                        else {
                            alert(`${res.message ?? 'Something Went Wrong'}`);
                        }
                    })
            }
    }

    const handleWarehouseUpdate = async () => {    
        const currentBizId = currentBusiness.id
        const res = await Api.get('/my-business')
        setAvailableBusiness(res);
        res.forEach((biz, key) => {
            if(biz.id == currentBizId){
                setCurrentBusiness(res[key]);
            }
        });
    } 

    if (!currentBusiness || !warehouses) {
        return <Loading />
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">Address Book</h3>

                <div className="card-tools">
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            setEditWarehouse(null)
                            setShowModal(true)
                        }}
                    >Add New</button>
                </div>
            </div>
            <div className="card-body">
                <WarehouseContainer
                    warehouses={warehouses}
                    setShowModal={setShowModal}
                    setEditWarehouse={setEditWarehouse}
                    deleteWarehouse={deleteWarehouse}
                />
            </div>
            {showModal &&
                <WarehouseAdd
                    warehouse={editWarehouse ?? null}
                    close={() => {
                        setShowModal(false)
                        setEditWarehouse(null)
                    }}
                    currentBusiness={currentBusiness}
                    setWarehouses={(value) => {
                        handleWarehouseUpdate()
                    }}
                />
            }
        </div>
    )
}