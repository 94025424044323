import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import otpTypeEnums from "../enums/otpTypeEnums";
import Api from "../lib/Api";
import handleFormikBackendValidation from "../lib/handleFormikBackendValidation";
import Loading from "./Loading";
import Warning from "./util/Warning";

export default function Otp({ otp, loading, setOtp, setLoading, redirectUrl }) {

    const navigate = useNavigate();


    const handleSubmit = (values, action) => {
        setLoading(true)
        Api.post(`/validate_otp/${otp.id}`, values)
            .then(res => {
                if (res.errors) {
                    handleFormikBackendValidation(res, action);
                }
                
                if (res.success) {
                    navigate(redirectUrl ?? '/auth/login', { replace: true });
                }
                setLoading(false);
            })

    }

    const resendOtp = async () => {
        setLoading(true)
        const res = Api.post(`/resend_otp/${otp.id}`)
        if (res.success) {
            setOtp(res.otp);
        }
        else {
            alert("Oops, something went wrong");
        }

        setLoading(false)
    }

    const formik = useFormik({
        initialValues: {
            code: '',
            password: '',
            password_confirmation: ''
        },
        onSubmit: handleSubmit
    });


    return (
        <>
            <main className="text-center login-form-container">
                <div className="form-signin">
                    <form onSubmit={formik.handleSubmit}>
                        <img className="mb-4" src={`${process.env.REACT_APP_LOGO_LOCATION}`} alt="" width="72" height="57" />
                        <h1 className="h3 mb-3 fw-normal">Enter Otp</h1>
                        <p>We've sent an OTP to your selected email. Please enter the otp below.</p>

                        <div className="form-floating mb-3">
                            <input type="password" className="form-control" name='code'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.code}
                            />
                            <label>OTP</label>
                        </div>
                        {formik.errors.code && formik.touched.code ? <Warning message={formik.errors.code} /> : null}

                        {otp.otp_type == otpTypeEnums.password_reset &&
                            <>
                                <div className="form-floating mb-3">

                                    <label>Password</label>
                                    <input type="password" name='password' className="form-control" placeholder="Password"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                    />
                                </div>
                                {formik.errors.password && formik.touched.password ? <Warning message={formik.errors.password} /> : null}

                                <div className="form-floating mb-3">

                                    
                                    <input type="password" name='password_confirmation' className="form-control" placeholder="Confirm Password"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password_confirmation}
                                    />
                                    <label>Confirm Password</label>
                                </div>
                                {formik.errors.password_confirmation && formik.touched.password_confirmation ? <Warning message={formik.errors.password_confirmation} /> : null}

                            </>
                        }

                        <div className="checkbox mb-3">

                        </div>

                        <p>
                            Didn't recieve OTP? <a onClick={() => {
                                resendOtp()
                            }}>Resend</a>
                        </p>

                        {loading &&
                            <Loading />
                        }
                        {!loading &&
                            <button className=" btn btn-primary" type="submit">Sign Up</button>
                        }
                    </form>
                </div>
            </main>
        </>
    );
}