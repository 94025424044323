import ReactTableBody from "./util/ReactTableBody";

export default function CustomersContainer({customers, editCustomer, deleteCustomer}){

    
    const headers = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: "Name",
            accessor: 'name'
        },
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "Phone",
            accessor: "phone"
        },
        {
            Header: "Add1",
            accessor: "add1"
        },
        {
            Header: "Add2",
            accessor: "add2"
        },
        {
            Header: "Add3",
            accessor: "add3"
        },
        {
            Header: "City",
            accessor: "city"
        },
        {
            Header: "Zip",
            accessor: "zip"
        },
        {
            Header: "Country",
            accessor: "country"
        },
        {
            Header: "Action",
            accessor: d => d.id,
            Cell: props => <><button className="btn btn-primary" onClick={() => {
                editCustomer(props.value)
            }}>Edit</button>
            <button className="btn btn-danger" onClick={() => {
                deleteCustomer(props.value)
            }}>Delete</button></>
        },

    ];

    return (
        <div className="customers_container">
            <ReactTableBody body={customers} headers={headers} />
        </div>
    );
}