export default function getCourierServicesRequiringIndividualQuote(courier_services){
    const output = [];

    const owcIncludedCourier = [];
    const services = initialFilter(courier_services);
    services.forEach(service => {
        if(service.api_id != 2){
            output.push(service);
        }
        else{
            if(owcIncludedCourier.indexOf(service.service_code) == -1){
                owcIncludedCourier.push(service.service_code);
                output.push(service);
            }
        }
    });

    return output;
}

const initialFilter = (courier_services) => {
    return courier_services.filter(service => {
        return service.api_id == 2 || service.fetch_individually == 1;
    })
}