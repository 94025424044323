import { useState } from "react"
import Api from "../lib/Api"
import BusinessDocumentUpdate from "./BusinessDocumentUpdate"
import {Buffer} from 'buffer';
import showB64File from "../lib/showB64File";

export default function DocumentIndex({ documents, currentBusiness, setDocuments }) {
    const [editDocument, setEditDocument ] = useState(null)

    const loadFile = async (document_id) => {
        try{
            if(currentBusiness && currentBusiness.id){
                const res = await Api.get(`/${currentBusiness.id}/view_document/${document_id}`)
                if(res.success){
                    const file = res.file;
                    const type = res.type;
                    showB64File(file, type)
                }
                else{
                    alert(res.message ?? "something went wrong");
                }
            }
        }
        catch(error){
            alert(error.message ?? "Something Went Wrong")
        }
        
    }    
    
    return (
        <div className="container" style={{background: 'white'}}>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Document</th>
                        <th scope="col">File</th>
                        <th scope="col">Update</th>
                    </tr>
                </thead>
                <tbody>
                    {documents.map(doc => {
                        return (
                            <tr key={doc.id}>
                                <td>{doc.document}</td>
                                
                                <td>
                                    <a className={`btn btn-secondary ${doc.file == null ? 'disabled' : ''}`}
                                        onClick={() => {
                                            loadFile(doc.id)
                                        }}
                                    >View</a>
                                </td>
                                <td>
                                    <button type="button" className="btn btn-success"
                                        onClick={() => {
                                            setEditDocument(doc)
                                        }}
                                    >Update</button>
                                </td>
                            </tr>
                        )
                    })}

                </tbody>
            </table>
            {editDocument && currentBusiness && currentBusiness.id &&
                <BusinessDocumentUpdate 
                    setDocuments={setDocuments}
                    document={editDocument}
                    close={() => {
                        setEditDocument(null)
                    }}
                    documentId={editDocument.id}
                    businessId={currentBusiness.id}
                />
            }
        </div>
    )
}