import { useEffect, useState } from "react";
import Api from "../lib/Api";
import Loading from "./Loading";
import Modal from "./util/Modal";
import Warning from "./util/Warning";

export default function OwcTracking({trackingNo, close}){
    const [loading, setLoading] = useState(true);

    const [error, setError] = useState(null);

    const [trackingData, setTrackingData] = useState(null)

    useEffect(() => {
        Api.get(`/track/${trackingNo}`)
            .then(res => {
                if(res.success){
                    setTrackingData(res.data)
                }
                else{
                    setError(res.error ?? "Something Went Wrong")
                }
            })
            .catch(e => {
                setError(e.error ?? "Something Went Wrong")
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <Modal 
            title={`Tracking Info : ${trackingNo}`}
            close={close}
        >
            {loading && 
                <Loading />
            }
            {!loading && 
                <>
                    {error && 
                        <Warning message={error} />
                    }
                    {trackingData &&
                        <>
                            {trackingData.map((d, k) => {
                                return (
                                    <div key={k} className="m-2 p-2 track_bg">
                                        <p>Status : {d.status}</p>
                                        <p>Description : {d.description}</p>
                                        <p>Date : {d.date}</p>
                                    </div>
                                )
                            })}
                        </>
                    }
                </>
            }
        </Modal>
    );
}