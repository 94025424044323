import { useState } from "react"
import Api from "../lib/Api";
import Loading from "./Loading";
import Warning from "./util/Warning";

export default function DocumentAddFormForOrder({ order, callback = () => { } }) {
    const [loading, setLoading] = useState(false)

    const [errors, setErrors] = useState([]);

    const [inputType, setInputType] = useState('text');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (order && order.id) {
            setLoading(true);

            try {
                const data = new FormData(e.target);
                data.append('type', inputType)
                const res = await Api.filePost(`/add_order_doc/${order.id}`, data)
                if(res.success){
                    callback()
                }
                else{
                    if(res.errors){
                        const myErrors = [];
                        for (const x in res.errors) {
                            if (Object.hasOwnProperty.call(res.errors, x)) {
                                const errList = res.errors[x];
                                myErrors.push(errList[0])
                            }
                        }
                        setErrors(myErrors)
                        setLoading(false)
                    }
                    else{
                        alert(res.message ?? "Something went wrong");
                        setLoading(false)
                    }
                }
            } catch (error) {
                alert(error.message ?? "Something went wrong");

                setLoading(false)
            }
        }

    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label>Document Name</label>
                <input type={'text'} className='form-control' name="name" />
            </div>
            <div className="form-group">
                <label>Document Type</label>
                <select className="form-control" value={inputType}
                    onChange={(e) => {
                        setInputType(e.target.value)
                    }}
                >
                    <option value={'text'}>Text</option>
                    <option value={'file'}>File</option>
                </select>
            </div>
            <div className="form-group">
                <label>Value</label>
                <input name="value" className="form-control" type={inputType} />
            </div>
            {errors.map(e => <Warning message={e} />)}
            {!loading &&
                <button className="btn btn-primary">Save</button>
            }
            {loading &&
                <Loading />
            }

        </form>
    )
}