import { useMemo } from "react";
import { usePagination, useSortBy, useTable } from "react-table/dist/react-table.development";

export default function ReactTableBody({ body, headers }) {
    const data = useMemo(() => [...body], [body]);
    const columns = useMemo(() => [...headers], [headers]);

    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 },
    },
        useSortBy,
        usePagination
    );


    const {

        getTableProps,

        getTableBodyProps,

        headerGroups,

        prepareRow,

        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },

    } = tableInstance

    return (
        <>
        <table {...getTableProps()} className="table table-striped">

            <thead>

                {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()}>

                            {// Loop over the headers in each row

                                headerGroup.headers.map(column => (

                                    // Apply the header cell props

                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>

                                        {// Render the header

                                            column.render('Header')}

                                    </th>

                                ))}

                        </tr>

                    ))}

            </thead>

            {/* Apply the table body props */}

            <tbody {...getTableBodyProps()}>

                {// Loop over the table rows

                    page.map(row => {
                        // Prepare the row for display
                        prepareRow(row)

                        return (

                            // Apply the row props

                            <tr {...row.getRowProps()}>

                                {// Loop over the rows cells

                                    row.cells.map(cell => {

                                        // Apply the cell props

                                        return (

                                            <td {...cell.getCellProps()}>

                                                {// Render the cell contents

                                                    cell.render('Cell')}

                                            </td>

                                        )

                                    })}

                            </tr>

                        )

                    })}

            </tbody>

        </table>
        <div className="pagination justify-content-between">
            <div>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <i className="fas fa-angle-double-left"></i>
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                <i className="fas fa-chevron-left"></i>
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                <i className="fas fa-chevron-right"></i>
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <i className="fas fa-angle-double-right"></i>
                </button>{' '}
            </div>
            <div>
                <span>
                Page{' '}
                <strong>
                    {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
                </span>
                <span>
                | Go to page:{' '}
                <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                    }}
                />
                </span>{' '}
                <select
                value={pageSize}
                onChange={e => {
                    setPageSize(Number(e.target.value))
                }}
                >
                {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                    </option>
                ))}
                </select>
            </div>
      </div>
      </>
    )
}