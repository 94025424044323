import { useFormik } from "formik"
import { useState } from "react"
import Loading from "../../components/Loading"
import Otp from "../../components/Otp"
import Warning from "../../components/util/Warning"
import Api from "../../lib/Api"
import handleFormikBackendValidation from "../../lib/handleFormikBackendValidation"

export default function PasswordReset() {


    const [loading, setLoading] = useState(false)

    const [otp, setOtp] = useState(null);


    const handleSubmit = async (values, actions) => {
        setLoading(true)
        try {
            const res = await Api.post('/password-reset', values);
            if (res.success) {
                setLoading(false);
                setOtp(res.otp);
            }
            else {
                if (res.errors) {
                    handleFormikBackendValidation(res, actions);
                    setLoading(false);
                    setOtp(null);
                }
                else {
                    alert(res.message ?? "Something went wrong");
                    setLoading(false);
                    setOtp(null);
                }
            }
        } catch (error) {
            alert(error.message ?? "Something went wrong");
            setLoading(false)
            setOtp(null);
        }
    }

    const formik = useFormik({
        onSubmit: handleSubmit,
        initialValues: {
            email: '',
        }
    })


    if(otp){
        return (
            <Otp 
                otp={otp}
                setOtp={setOtp}
                loading={loading}
                setLoading={setLoading}
            />
        )
    }

    return (
        <>
            <main className="text-center login-form-container">
                <div className="form-signin">
                    <form onSubmit={formik.handleSubmit}>
                        <img className="mb-4 singup-icon" src={`${process.env.REACT_APP_LOGO_LOCATION}`} alt="" />
                        <h1 className="h3 mb-3 fw-normal">Enter Your Registered Email</h1>

                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" placeholder="name@example.com" name='email'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            <label>Email address</label>
                        </div>
                        {formik.errors.email && formik.touched.email ? <Warning message={formik.errors.email} /> : null}

                        {loading &&
                            <Loading />
                        }
                        {!loading &&
                            <button className=" btn btn-primary" type="submit">Send Otp</button>
                        }

                    </form>
                </div>
            </main>
        </>
    )
}