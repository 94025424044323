import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loading from './Loading';

export default function CheckoutForm({ transaction }) {

    const stripe = useStripe()
    const elements = useElements();

    const [loading, setLoading] = useState(true);

    console.log(elements);
    useEffect(() => {
        console.log(elements);
    }, [elements])

    useEffect(() => {
        if (!stripe || !elements) {
            setLoading(true)
        }
        else{
            setLoading(false)
        }
    }, [stripe, elements])

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            setLoading(false)
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${process.env.REACT_APP_SITE_URL}/dashboard/wallet/${transaction.id}/success`,
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error);
            alert(result.error.message ?? "Oops, Looks like something isn't right");
            setLoading(false)
        }
        else {
            console.log(result);
            alert(result.message ?? "Oops, Looks like something isn't right");
            setLoading(false)
        }

        setLoading(false)
    }
    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <br />
            {loading &&
                <Loading />
            }
            {!loading &&
                <button className='btn btn-success'>Pay Now</button>
            }
        </form>
    );
};
