import { useFormik } from "formik";
import QuickQuoteInitialPage from "../../components/QuickQuote/QuickQuoteInitialPage";
import quickQuoteValidation from "../../validation/quickQuoteValidation";
import { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import { useRecoilState } from "recoil";
import currentBusinessState from "../../atoms/currentBusinessState";
import getQuotation from "../../lib/getQuotation";
import getCourierServicesRequiringIndividualQuote from "../../lib/getCourierServicesRequiringIndividualQuote";
import courierServicesState from "../../atoms/courierServicesState";
import getQuoteForShipment from "../../lib/getQuoteForShipment";
import Api from "../../lib/Api";
import prepareRequestForQuotation from "../../lib/prepareRequestForQuotation";
import handleFormikBackendValidation from "../../lib/handleFormikBackendValidation";
import QuotaionContainer from "../../components/QuotaionContainer";
import PlaceOrder from "../../components/QuickQuote/PlaceOrder";

export default function QuickQuote() {
    const [quotes, setQuotes] = useState([]);
    const [loading, setLoading] = useState(false)
    const [quoteLoading, setQuoteLoading] = useState(false)
    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);
    const [issuesWithShipment, setIssuesWithShipment] = useState(null)
    const [courierServices, setCourierServices] = useRecoilState(courierServicesState)
    const [shipment, setShipment] = useState(null)
    const couponCode = '';
    const pages = Object.freeze({
        home: 'home',
        quotes: 'quotes',
        order: 'order'
    });
    const [quote, setQuote] = useState(null)
    const [currentPage, setCurrentPage] = useState(pages.home)


    const formik = useFormik({
        initialValues: {
            pickup_city: "",
            pickup_zip: "",
            delivery_city: "",
            delivery_zip: "",
            delivery_country: "AU",
            pickup_country: "AU",
            items: [
                {
                    height: '',
                    weight: '',
                    length: '',
                    value: '',
                    type: ''
                }
            ],
            pickup_date: '',
            product_type: 'parcel',
            pickup_type: 'residential',
            delivery_type: 'residential',
            taillift_at_pickup: false,
            taillift_at_delivery: false,
        },
        validationSchema: quickQuoteValidation,
        onSubmit: async (values, actions) => {
            try {
                setLoading(true)

                const res = await Api.post(`/${currentBusiness.id}/shipment/quick`, values);
                if (!res.success) {
                    if (res.errors) {
                        handleFormikBackendValidation(res, actions)
                    }
                    else {
                        alert(res.message ? `${res.message}` : "Something Went Wrong");
                    }
                }
                else {
                    setShipment(res.shipment);
                    // formik.setFieldValue('shipment_id', res.shipment.id)
                    getQuotes(res.shipment)
                    setCurrentPage(pages.quotes)
                }
            } catch (error) {
                alert(error.message ?? "Something Went Wrong")
            }
            setLoading(false)

        }
    })





    const getQuotes = async (shipment) => {
        try {
            setQuotes([]);
            setQuoteLoading(true)
            console.log()
            const res = await getQuotation(shipment, currentBusiness.id, true);
            if (res.errors) {
                setIssuesWithShipment(res);
                setQuoteLoading(false)
            }
            if (res.success) {
                let count = 0;
                let multipleQuoteDone = false;
                const quotation = res.quotation;
                const courierServicesRequiringIndividualQuote = getCourierServicesRequiringIndividualQuote(courierServices)

                let i = 0;
                courierServicesRequiringIndividualQuote.forEach((service) => {
                    getQuoteForShipment(shipment, service, quotation, currentBusiness.id, couponCode)
                        .then(recievedQuote => {
                            setQuotes(recievedQuote);
                            count++
                            if (count >= courierServicesRequiringIndividualQuote.length && multipleQuoteDone) {
                                setQuoteLoading(false)
                            }
                        })
                        .catch(error => {
                            count++
                            if (count >= courierServicesRequiringIndividualQuote.length && multipleQuoteDone) {
                                setQuoteLoading(false)
                            }
                        })


                })


                Api.post(`/${currentBusiness.id}/get_quote_multiple`, prepareRequestForQuotation(shipment, quotation, couponCode))
                    .then(res => {
                        setQuotes(res)
                        multipleQuoteDone = true;
                        if (count >= courierServicesRequiringIndividualQuote.length && multipleQuoteDone) {
                            setQuoteLoading(false)
                        }
                    })
                    .catch(e => {
                        multipleQuoteDone = true;
                        if (count >= courierServicesRequiringIndividualQuote.length && multipleQuoteDone) {
                            setQuoteLoading(false)
                        }
                    })

            }
        } catch (error) {
            setQuoteLoading(false)
            alert(error.message ? error.message : "Something Went Wrong");
        }

    }

    useEffect(() => {
        setQuotes([])
    }, [formik.values])



    if (loading) {
        return <Loading />
    }
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">Quick Quote</h3>

                <div className="card-tools">

                </div>
            </div>
            <div className="card-body">
                <div className="bg-white pb-4">
                    <div className="container">
                        {currentPage == pages.home &&
                            <QuickQuoteInitialPage
                                formik={formik}

                            />
                        }
                        {currentPage == pages.quotes &&
                            <>
                                {quoteLoading &&
                                    <Loading />
                                }
                                <QuotaionContainer
                                    quotes={quotes}
                                    shipment={shipment}
                                    couponCode={couponCode}
                                    quickQuoteCallback={(quote) => {
                                        setQuote(quote)
                                        setCurrentPage(pages.order)
                                    }}
                                />
                            </>

                        }
                        {currentPage == pages.order && 
                            <PlaceOrder 
                                shipment={shipment} 
                                quote={quote}
                            />
                        }
                    </div>
                </div>
            </div>


        </div>

    );
}