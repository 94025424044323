export default function getPickupDeliveryTypeOptions(){
    return [
        {
            label: "residential",
            value: "residential"
        },
        {
            label: "commercial",
            value: "commercial"
        }
    ]
}