import { object, string, email, ref } from 'yup'
import getCountryListJson from '../lib/getCountryListJson';
import getAuStateListJson from '../lib/getAuStateListJson';
const signupValidation = object({
    name: string().required().min(2).max(150),
    email: string().email().required(),
    password: string().required().min(8),
    password_confirmation: string().required().min(8).oneOf([ref('password')], 'Password does not match'),
    business: string().required().max(50),
    billing_name: string().required(),
    billing_email: string().email().required(),
    billing_phone: string().required().min(8).max(12),
    billing_add1: string().required().min(3).max(200),
    billing_add2: string().min(3).max(200),
    billing_add3: string().min(3).max(200),
    billing_city: string().required().min(3).max(200),
    billing_zip: string().required().max(200),
    billing_state: string().required().test('au-invalid-state', 'Selected State is Invalid', (value, context) => {
        if (context.parent.country == 'AU') {
            let output = false;
            const states = getAuStateListJson();
            for (let i = 0; i < states.length; i++) {
                const element = states[i];
                if (element.value == value) {
                    output = true;
                }
                if (output) {
                    break;
                }
            }

            return output;
        }
        return true;
    }),
    billing_country: string().required().oneOf(getCountryListJson().map(country => country.value)),
    billing_tax_code: string().max(200)

});

export default signupValidation;