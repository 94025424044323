import { useEffect, useState } from "react";
import Api from "../lib/Api";
import Carousel from "./Carousel";

const SingleNews = ({ news }) => {
    return (
        <a href={news.url ?? '#'} 
            target={`${news.url && news.open_in_new_tab ? '_blank' : '_self'}`} 
            className="item"
        >
            <img src={`${process.env.REACT_APP_BASE_URL}/../../storage/${news.body}`} className="dash-card-style p-0" alt="" />
        </a>
    )
}

export default function News() {

    const [news, setNews] = useState([]);

    useEffect(() => {
        Api.get('/news').then(res => {
            if (res.success) {
                setNews(res.news);
            }
        })
    }, [])

    return (
        <>
            <div className="dtdc_ad_section">
                <div id="couriers-logo" className="owl-carousel mt-4 mb-30">
                    <Carousel conf={{
                        slidesToShow: 1,
                        speed: 100,
                        autoplaySpeed: 5000,
                        nav: false,
                        responsive: []
                    }}>
                        
                        {news.map(n => <SingleNews news={n} />)}
                    </Carousel>
                </div>
                
            </div>
        </>
        
    )
}