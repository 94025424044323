import { useRecoilState } from "recoil";
import currentUserState from "../../atoms/currentUserState";
import { useFormik } from "formik"
import loginValidation from "../../validation/loginValidation";
import Api from "../../lib/Api";
import handleFormikBackendValidation from "../../lib/handleFormikBackendValidation";
import { useState } from "react";
import Loading from "../../components/Loading";
import Warning from "../../components/util/Warning";
import Otp from "../../components/Otp";
import { Link, useNavigate } from "react-router-dom";


export default function Login() {
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

    const [otp, setOtp] = useState(null)

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    const handleFormSubmit = async (values, actions) => {
        try {
            setLoading(true)
            const res = await Api.post('/login', values);
            if (res.errors) {
                handleFormikBackendValidation(res, actions);
            }
            if (res.success) {
                console.log(res);
                setCurrentUser(res.user);

                if (res.otp) {
                    setOtp(res.otp);
                    setLoading(false)
                }

                if (!res.otp) {
                    navigate('/dashboard')
                }

            }
            setLoading(false)
        }
        catch {
            alert("Oops, Something went wrong!");
            setLoading(false)
        }

    }

    const formik = useFormik({
        validationSchema: loginValidation,
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: handleFormSubmit
    })

    if (otp) {
        return (
            <Otp
                loading={loading}
                setLoading={setLoading}
                otp={otp}
                setOtp={setOtp}
                redirectUrl={'/dashboard'}
            />
        )
    }

    return (
        <>
            <main className="text-center login-form-container vh-100">
                <div className="form-signin">
                    <form onSubmit={formik.handleSubmit}>
                        <img className="mb-4 singup-icon" src={`${process.env.REACT_APP_LOGO_LOCATION}`} alt="" />
                        <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

                        <div className="form-floating mt-3">
                            <input type="text" className="form-control" placeholder="name@example.com" name='email'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            <label>Email address</label>
                        </div>
                        {formik.errors.email && formik.touched.email ? <Warning message={formik.errors.email} /> : null}

                        <div className="form-floating mt-4">
                            <input type="password" name='password' className="form-control" placeholder="Password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                            />
                            <label>Password</label>
                        </div>
                        {formik.errors.password && formik.touched.password ? <Warning message={formik.errors.password} /> : null}


                        <div className="checkbox mb-3">
                            <label>
                                <input type="checkbox" value="remember-me" /> Remember me
                            </label>
                        </div>
                        <p className="text-muted">
                            <Link to={'/auth/forgot-password'} className="text-muted">Forgot Password?</Link>
                        </p>
                        
                        {loading &&
                            <Loading />
                        }
                        {!loading &&
                            <button className=" btn btn-primary" type="submit">Sign in</button>
                        }

                        <p className="mt-5 mb-3 text-muted">
                            Don&apos;t have an account?
                            <Link to={'/auth/signup'} > Sign Up!</Link>
                        </p>



                    </form>
                </div>
            </main>
        </>
    )
}