import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import currentBusinessState from "../atoms/currentBusinessState";
import BusinessInviteAccept from "../components/Invite/BusinessInviteAccept";
import Loading from "../components/Loading";
import Warning from "../components/util/Warning";
import Api from "../lib/Api";

export default function AcceptBusinessInvite() {
    const { invite_id } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const code = searchParams.get('code');

    const [invite, setInvite] = useState(null)


    const [error, setError] = useState(null);

    const [currentUser, setCurrentUser] = useRecoilState(currentBusinessState);

    const [invitee, setInvitee] = useState(null);


    useEffect(() => {
        setInvite(null);
        setInvitee(null);
        Api.get(`/invite/${invite_id}`)
            .then(res => {
                if (res.success) {
                    if (!res.user) {
                        setInvite(res.invite)
                    }
                    else {
                        setInvite(res.invite);
                    }
                }
                else {
                    setError(res.message ?? "Something Went Wrong")
                }
            })
            .catch(() => {
                setError("Could not load invite details. It may have expired.");
            })



    }, []);

    if (error) {
        return (
            <div className="p-4 m-4 text-center">
                <Warning
                    message={error}
                />
            </div>
        )
    }

    if (!invite) {
        return <Loading />
    }

    return (
        <BusinessInviteAccept
            invite={invite}
            code={code}
        />
    )
}


