import { useParams } from "react-router-dom";
import ShipmentEditForm from "../../../components/ShipmentEditForm";
import { useEffect, useState } from "react";
import currentBusinessState from "../../../atoms/currentBusinessState";
import { useRecoilState } from "recoil";
import Loading from "../../../components/Loading";
import Api from "../../../lib/Api";

export default function ShipmentEdit(){
    const { shipment_id } = useParams();

    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);

    const [shipment, setShipment] = useState(null)


    useEffect(() => {
        if (currentBusiness && currentBusiness.id) {
            Api.get(`/${currentBusiness.id}/shipment/${shipment_id}`)
                .then(res => {
                    if (res.success) {
                        setShipment(res.data);
                        
                    }
                    else {
                        alert(res.message ? `${res.message}` : "Oops, something went wrong");
                        setShipment(null);
                    }
                })
        }
        else{
            setShipment(null)
        }

    }, [currentBusiness]);

    if(!shipment){
        return <Loading />
    }


    return (
        <ShipmentEditForm
            shipment={shipment}
            setShipment={setShipment}
            successCallback={() => {
                window.close()
            }}
        />
    )
}