import { useEffect } from "react";
import { useRecoilState } from "recoil";
import csrfCookieState from "../../atoms/csrfCookieState";
import currentUserState from "../../atoms/currentUserState";
import Api from "../../lib/Api";

export default function GetCurrentUser() {
    const [cuerrntUser, setCurrentUser] = useRecoilState(currentUserState);

    const [isCsrfCookieSet, setIsCsrfCookieSet] = useRecoilState(csrfCookieState);

    useEffect(() => {
        if (isCsrfCookieSet) {
            Api.get('/current_user')
                .then(res => {
                    console.log('Setting Current User as Below : ');
                    console.log(res);
                    setCurrentUser(res);
                })
        }

    }, [isCsrfCookieSet])
    return <></>
}