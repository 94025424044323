import { useEffect, useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useRecoilState } from "recoil";
import currentBusinessState from "../atoms/currentBusinessState";
import Api from "../lib/Api";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat"
import Loading from "./Loading";

export default function DashboardChart({dashboardData}) {

    const [data, setData] = useState(null);

    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);



    dayjs.extend(customParseFormat)


    useEffect(() => {
        if (dashboardData) {

            const initialData = {}
            const startDate = dayjs(`${dashboardData.start_date}`, 'YYYY-MM-DD');
            for (let i = 0; i < 7; i++) {
                initialData[startDate.add(i, 'M').format('YYYYMM')] = {
                    orders: 0,
                    shipments: 0,
                    warehouses: 0,
                    customers: 0
                }
            }

            const elements = ['orders', 'shipments', 'warehouses', 'customers'];
            for (let i = 0; i < elements.length; i++) {
                const key = elements[i];
                for (let i = 0; i < dashboardData[key].length; i++) {
                    const element = dashboardData[key][i];
                    const date = dayjs(new Date(element.created_at));
                    initialData[date.format('YYYYMM')][key]++
                }
            }

            const finalData = [];

            for (const key in initialData) {
                if (Object.hasOwnProperty.call(initialData, key)) {
                    const element = initialData[key];
                    finalData.push({
                        name: dayjs(`${key}01`, 'YYYYMMDD').format('MMM-YY'),
                        orders: element.orders,
                        shipments: element.shipments,
                        warehouses: element.warehouses,
                        customers: element.customers
                    });
                }
            }

            console.log(finalData);

            setData(finalData);

        }
    }, [dashboardData])


    if (!data) {
        return <Loading />
    }

    return (
        <div style={{aspectRatio: '3'}}>
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                width={500}
                height={500}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="orders" stroke="#8884d8" />
                <Line type="monotone" dataKey="shipments" stroke="#82ca9d" />
                <Line type="monotone" dataKey="customers" stroke="#f20f1a" />
                <Line type="monotone" dataKey="warehouses" stroke="#0ff281" />
            </LineChart>
        </ResponsiveContainer>
        </div>
    )
}