import Warning from "./util/Warning"

export default function DocumentationUpdateSingleInput({ name, input, setValue, errors }) {
    return (
        <div className="col-md-6">
            <div className="form-group">
                <label>{input.name}</label>
                <input className="form-control" name={name} type={input.type} />
                {input.description &&
                    <small>{input.description}</small>
                }
                
            </div>
        </div>
    )
}