import { object, string, email, number } from 'yup'
import getAuStateListJson from '../lib/getAuStateListJson';
import getCountryListJson from '../lib/getCountryListJson';
const billingAddressValidation = object({
    name: string().required(),
    email: string().email().required(),
    phone: string().required().min(8).max(12),
    add1: string().required().min(3).max(200),
    add2: string().min(3).max(200),
    add3: string().min(3).max(200),
    city: string().required().min(3).max(200),
    zip: string().required().max(200),
    state: string().required().test('au-invalid-state', 'Selected State is Invalid', (value, context) => {
        if (context.parent.country == 'AU') {
            let output = false;
            const states = getAuStateListJson();
            for (let i = 0; i < states.length; i++) {
                const element = states[i];
                if (element.value == value) {
                    output = true;
                }
                if (output) {
                    break;
                }
            }

            return output;
        }
        return true;
    }),
    country: string().required().oneOf(getCountryListJson().map(country => country.value)),
    tax_code: string().max(200)
})

export default billingAddressValidation;