import { useState } from "react";
import getCountryListJson from "../../lib/getCountryListJson";
import getPickupDeliveryTypeOptions from "../../lib/getPickupDeliveryTypeOptions";
import getProductTypeOptions from "../../lib/getProductTypeOptions";
import ItemsContainer from "../ItemsContainer";
import FormikInputBootstrap from "../util/FormikInputBootstrap";
import CitySugesstion from "../util/CitySugesstion";

export default function QuickQuoteInitialPage({ formik, handleNext }) {
    const [showPickupZipSuggestion, setShowPickupZipSuggestion] = useState(false)
    const [showPickupCitySuggestion, setShowPickupCitySuggestion] = useState(false)
    const [showDeliveryCitySuggestion, setShowDeliveryCitySuggestion] = useState(false)
    const [showDeliveryZipSuggestion, setShowDeliveryZipSuggestion] = useState(false)

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-md-6">
                    <label>Pickup</label>
                    <br />
                    <FormikInputBootstrap
                        inputName={'pickup_country'}
                        formik={formik}
                        label={'Pickup Country'}
                        type='select'
                        options={getCountryListJson()}
                    />

                    <FormikInputBootstrap
                        inputName={'pickup_city'}
                        formik={formik}
                        label={'City'}
                        onKeyUp={() => {
                            setShowPickupCitySuggestion(true)
                        }}
                    />
                    {showPickupCitySuggestion &&
                        <CitySugesstion
                            input={formik.values.pickup_city}
                            country={formik.values.pickup_country}
                            callback={(city, zip) => {
                                formik.setFieldValue('pickup_city', city)
                                formik.setFieldValue('pickup_zip', zip)
                                setShowPickupCitySuggestion(false)
                                setShowPickupZipSuggestion(false)
                            }}
                        />
                    }


                    <FormikInputBootstrap
                        inputName={'pickup_zip'}
                        formik={formik}
                        label={'Zip'}
                        onKeyUp={() => {
                            setShowPickupZipSuggestion(true)
                        }}
                    />

                    {showPickupZipSuggestion &&
                        <CitySugesstion
                            input={formik.values.pickup_zip}
                            country={formik.values.pickup_country}
                            callback={(city, zip) => {
                                formik.setFieldValue('pickup_city', city)
                                formik.setFieldValue('pickup_zip', zip)
                                setShowPickupCitySuggestion(false)
                                setShowPickupZipSuggestion(false)
                            }}
                        />
                    }
                </div>
                <div className="col-md-6">
                    <label>Delivery</label>
                    <br />
                    <FormikInputBootstrap
                        inputName={'delivery_country'}
                        formik={formik}
                        label={'Delivery Country'}
                        type='select'
                        options={getCountryListJson()}
                    />

                    <FormikInputBootstrap
                        inputName={'delivery_city'}
                        formik={formik}
                        label={'City'}
                        onKeyUp={() => {
                            setShowDeliveryCitySuggestion(true)
                        }}
                    />

                    {showDeliveryCitySuggestion &&
                        <CitySugesstion
                            input={formik.values.delivery_city}
                            country={formik.values.delivery_country}
                            callback={(city, zip) => {
                                formik.setFieldValue('delivery_city', city)
                                formik.setFieldValue('delivery_zip', zip)
                                setShowDeliveryCitySuggestion(false)
                                setShowDeliveryZipSuggestion(false)
                            }}
                        />
                    }

                    <FormikInputBootstrap
                        inputName={'delivery_zip'}
                        formik={formik}
                        label={'Zip'}
                        onKeyUp={() => {
                            setShowDeliveryZipSuggestion(true)
                        }}
                    />

                    {showDeliveryZipSuggestion &&
                        <CitySugesstion
                            input={formik.values.delivery_zip}
                            country={formik.values.delivery_country}
                            callback={(city, zip) => {
                                formik.setFieldValue('delivery_city', city)
                                formik.setFieldValue('delivery_zip', zip)
                                setShowDeliveryCitySuggestion(false)
                                setShowDeliveryZipSuggestion(false)
                            }}
                        />
                    }
                </div>

            </div>
            <div className="row">
                <div className="col-12" style={{ paddingTop: '2rem' }}>
                    <h5>Package Details</h5>
                </div>



                <div className="col-md-6">
                    <FormikInputBootstrap
                        inputName={'pickup_date'}
                        formik={formik}
                        label={'Pick Up Date'}
                        type={'date'}
                    />
                </div>




                <div className="col-md-6">
                    <FormikInputBootstrap
                        inputName={'product_type'}
                        formik={formik}
                        label={'Product Type'}
                        type='select'
                        options={getProductTypeOptions()}
                    />
                </div>

                <div className="col-md-6">
                    <FormikInputBootstrap
                        inputName={'pickup_type'}
                        formik={formik}
                        label={'Pickup Location Type'}
                        type='select'
                        options={getPickupDeliveryTypeOptions()}
                    />
                </div>

                <div className="col-md-6">
                    <FormikInputBootstrap
                        inputName={'delivery_type'}
                        formik={formik}
                        label={'Delivery Location Type'}
                        type='select'
                        options={getPickupDeliveryTypeOptions()}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <ItemsContainer
                        formik={formik}
                    />
                </div>
            </div>
            <button className="btn btn-primary" >Get Quote</button>
        </form>
    )
}