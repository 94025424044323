import { useEffect, useState } from "react";

export default function Invoice({ invoices, business, invoice_no }) {

    const [total, setTotal] = useState({
        gst: 0,
        taxable: 0,
        total: 0,
    });

    useEffect(() => {
        const myTotal = {
            gst: 0,
            taxable: 0,
            total: 0,
        };
        invoices.forEach(inv => {
            let taxableValue = 0;
            if (inv.gst > 0) {
                taxableValue = parseInt(inv.insurance_price) + parseInt(inv.base_price) - parseInt(inv.coupon_discount);
            }
            if((inv.final_amount - taxableValue) < 5){
                taxableValue = inv.final_amount;
            }
            myTotal.gst = parseInt(myTotal.gst) + parseInt(inv.gst);
            myTotal.total = parseInt(parseInt(myTotal.total) + parseInt(inv.final_amount));
            myTotal.taxable = parseInt(taxableValue) + parseInt(myTotal.taxable);
        });

        setTotal(myTotal);

    }, [invoices])



    return (
        <>
            <div class="wrapper p-4">
                <section class="invoice">
                    <div class="row m-0">
                        <div class="col-12">
                            <h2 class="page-header text-center">
                                {process.env.REACT_APP_APP_NAME} <img src={`${process.env.REACT_APP_LOGO_LOCATION}`} alt="" className="wid-200" />
                                

                            </h2>
                        </div>
                    </div>
                    <div class="row invoice-info">
                        <div class="col-sm-4 invoice-col">
                            From
                            <div>
                                <strong>{process.env.REACT_APP_APP_NAME}</strong><br />
                                DTDT Australia Pty Ltd<br />
                                Unit 9, 10 Ferngrove Place<br /> 
                                Chester Hill, NSW 2162<br />
                                Email: sales@dtdcaustralia.com.au <br />                                 
                                Phone: 1300 658 775
                            </div>
                        </div>
                        <div class="col-sm-4 invoice-col">
                            To
                            <div>
                                <strong>{business.billing_address.name}</strong><br />
                                {business.billing_address.add1}<br />
                                {(business.billing_address.add2 || business.billing_address.add3) &&
                                    <>
                                        {business.billing_address.add2} {business.billing_address.add2 ? ',' : ''} {business.billing_address.add3}<br />
                                    </>    
                                }
                                {business.billing_address.city} - {business.billing_address.zip}<br />
                                {business.billing_address.state}, {business.billing_address.country}<br />
                                Phone: {business.billing_address.phone}<br />
                                Email: {business.billing_address.email}
                            </div>
                        </div>
                        <div class="col-sm-4 invoice-col">
                            <b>Invoice #{invoice_no}</b><br />
                            <br />
                            <b>Payment Due:</b> PAID<br />
                            <b>Account:</b> B{business.id}
                        </div>
                    </div>
                    <div class="row" style={{ marginTop: '1rem' }}>
                        <div class="col-12 table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Qty</th>
                                        <th>Product</th>
                                        <th>Order #</th>
                                        <th>Tracking No.</th>
                                        <th>Description</th>
                                        <th>Base Amount</th>

                                        <th>Gst</th>
                                        <th>Subtotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoices.map(inv => {


                                        return (
                                            <tr>
                                                <td>1</td>
                                                <td>{inv.quote.name}</td>
                                                <td>
                                                    {inv.id}
                                                </td>
                                                <td>
                                                    {inv.tracking_number ?? "Unavailable"}
                                                </td>
                                                <td>Courier From {inv.shipment.pickup_city}, {inv.shipment.pickup_country} to {inv.shipment.delivery_city}, {inv.shipment.delivery_country}</td>
                                                <td>{(parseInt(inv.final_amount - inv.gst) / 100).toFixed(2)}</td>
                                                <td>{(parseInt(inv.gst) / 100).toFixed(2)}</td>
                                                <td>{(parseInt(inv.final_amount) / 100).toFixed(2)}</td>
                                            </tr>
                                        );
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">

                            {/* <p class="text-muted well well-sm shadow-none" style={{ marginTop: '10px' }}>
                                Etsy doostang zoodles disqus groupon greplin oooj voxy zoodles, weebly ning heekya handango imeem plugg dopplr
                                jibjab, movity jajah plickers sifteo edmodo ifttt zimbra.
                            </p> */}
                        </div>
                        <div class="col-6">

                            <div class="table-responsive">
                                <table class="table">
                                    <tr>
                                        <th style={{ width: '50%' }}>Non Taxable:</th>
                                        <td>{(parseInt(total.total - (total.taxable + total.gst)) / 100).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <th>Taxable </th>
                                        <td>{(parseInt(total.taxable) / 100).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <th>Tax:</th>
                                        <td>{(parseInt(total.gst) / 100).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <th>Total:</th>
                                        <td>{(parseInt(total.total) / 100).toFixed(2)}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}



