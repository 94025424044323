import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function FeaturesPage() {

    return(
        <>
            <section className="top-section feature-page">
                <div className="background-section">
                    <div className="row align-items-center m-0">
                        <div className="col-md-12 p-0">
                            <img src="/feature-bnr.jpg" className="img-fluid imagesppp"  alt="" />
                            {/* https://www.freepik.com/premium-vector/online-shopping-illustration-with-smartphone-shopping-store_15996000.htm#query=ecommerce&from_query=ecommerce%20features&position=17&from_view=search */}
                        </div>
                    </div>
                </div> 
                <div className="background-overflow">
                    <div className="w-100 text-start ps-5">
                        <h2 className="new-h2-sty1 ">Easy To Use Software</h2>
                        <p className="p-sty1 ">With our advanced tracking and personal process system</p>
                        <div className="yuhfg ">
                            <Link to="/auth/signup" >Sign Up And Save With BIZ Express</Link>
                        </div>    
                    </div>
                </div>
            </section>
            <section className="f-mid">
                <div className="container">
                    <h2 className="f-head">Our advance features</h2>
                    <div className="row">
                        <div className="col-lg-6">
                            <img src="/fimg1.jpg" alt="" className="img-fluid" />
                            {/* https://www.freepik.com/premium-vector/mobile-data-analysis-research-planning-statistics_4123625.htm#query=dashboard&position=20&from_view=search */}
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="blankdiv pt-5"></div>
                                    <div className="card border-lg p-3">
                                        <div className="f-box-img">
                                            <img src="/ficon1.png" alt="" />
                                        </div>
                                        <div className="f-box-text">
                                            <h2>Advanced Tools</h2>
                                            <p>Everything you need at the click of a button - book in a matter of seconds, manage your deliveries, and keep your clients informed.</p>
                                        </div>
                                    </div>
                                    <div className="card border-lg p-3">
                                        <div className="f-box-img">
                                            <img src="/ficon2.png" alt="" />
                                        </div>
                                        <div className="f-box-text">
                                            <h2>Online Tracking</h2>
                                            <p>The DTDC dashboard interface handles everything from making an order to tracking and controlling your parcel. Simply schedule your parcel for collection or drop off, Get it packaged then print and attach the mailing label.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card border-lg p-3">
                                        <div className="f-box-img">
                                            <img src="/ficon3.png" alt="" />
                                        </div>
                                        <div className="f-box-text">
                                            <h2>Analytics</h2>
                                            <p>Your secure dashboard produces analytics and data that allow you to track the effectiveness of your shipment processes all in one place.</p>
                                        </div>
                                    </div>
                                    <div className="card border-lg p-3">
                                        <div className="f-box-img">
                                            <img src="/ficon4.png" alt="" />
                                        </div>
                                        <div className="f-box-text">
                                            <h2>Innovative Software</h2>
                                            <p>With our advanced tracking and personal process system, you are able to view the process of your shipments or deliveries from the comfort of your own home or office via our online tracking software.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section>
                <div className="container">
                    <img src="/blankdashboard.png" className="img-fluid rounded-lg" />
                </div>
            </section> */}
            <section className="f-mid">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-1 col-md-2 ">
                                    <div className="f-box-img">
                                        <img src="/ficon5.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-11 col-md-10 ps-3 mb-1">
                                    <div className="f-box-text">
                                        <h2>Discounted Rates</h2>
                                        <p>We provide quality services through our online portal at affordable rates, making it easier for you and your business to fulfil your shipping needs.</p>
                                    </div>
                                </div>
                            </div>
                        
                            <div className="row">
                                <div className="col-lg-1 col-md-2 ">
                                    <div className="f-box-img">
                                        <img src="/ficon6.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-11 col-md-10  ps-3 mb-1">
                                    <div className="f-box-text">
                                        <h2>Bulk Shipment Fulfilment</h2>
                                        <p>We specialise in sending strenuous amounts of shipments to wherever they need to go. Bulk services include Parcels, Pallets, Crates and many more. Seamless effort, Massive results.</p>
                                    </div>
                                </div>
                            </div>
                        
                            <div className="row">
                                <div className="col-lg-1 col-md-2 ">
                                    <div className="f-box-img">
                                        <img src="/ficon7.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-11 col-md-10  ps-3 mb-1">
                                    <div className="f-box-text">
                                        <h2>Same Day And Express Delivery</h2>
                                        <p>Time Critical delivery for when you need it most, we put your shipments on the next available flight and notify you when the process is complete. We guarantee shipment delivery within 2 hours after availability in capital cities.</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-1 col-md-2 ">
                                    <div className="f-box-img">
                                        <img src="/ficon8.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-11 col-md-10  ps-3 mb-1">
                                    <div className="f-box-text">
                                        <h2>Priority Support</h2>
                                        <p>Our team of dedicated professionals are here to meet your needs and make your experience as user friendly as possible. Talk to us on our live chat support, log a query or contact us directly.</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-1 col-md-2 ">
                                    <div className="f-box-img">
                                        <img src="/ficon9.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-11 col-md-10 ps-3 mb-1 ">
                                    <div className="f-box-text">
                                        <h2>Instant Pricing From Several Different Carriers</h2>
                                        <p>Track, Compare and Browse all at the click of a button. Browse through and compare prices with our range of carrier partners like DHL, Direct Couriers, TNT and more! </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src="/asdfg.png" className="img-fluid mt-4" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="position-relative ab-footer container-fluid">            
                <div className="row align-items-center">
                    <div className="col-md-5"><img src="/feature-ft.png" className="w-100" /></div>
                    <div className="col-md-6">
                        <div className="gcfscc w-100">
                            <div className="container ">
                                <h2 className="new-h2-sty2  position-relative z-index1">Why Choose DTDC Australia?</h2>
                                <p className="p-sty1 position-relative z-index1  me-auto ms-auto fs-18b text-justify">DTDC is an innovative courier service with a rich history in the area of imports and exports, Operating in over 10 000 locations, DTDC has pushed the boundaries of logistics to the max. We make sure that we always get your goods to the required destination at extremely competitive prices. DTDC has been a client favourite since 1990 and has since grown into a fierce competitor in the logistics world. With our exceptionally talented team, advanced systems and immersive tracking options, we set the standard for AAA international and domestic courier services. With DTDC Australia's Biz Express Fulfilment Portal, we offer affordable rates and effective Business Services which enable you to capitalise on your shipping faction and scale up your company. Skip The Hassle by switching to DTDC Australia's Biz Express and experience Bulk Shipping like you have never seen it before!</p>
                                <div className="yuhfg position-relative z-index1"> 
                                    <a href="/contact-us">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
            
        </>
    )
}