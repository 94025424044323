import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import currentBusinessState from "../atoms/currentBusinessState";
import Api from "../lib/Api";
import Loading from "./Loading";
import ReactTableBody from "./util/ReactTableBody";
import dayjs from 'dayjs';
import DocumentsForOrderModal from "./DocumentsForOrderModal";
import OwcTracking from "./OwcTracking";
import courierServicesState from "../atoms/courierServicesState";
import Select from "react-select";

export default function OrdersTableForBatch({orders = []}) {

    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);

    const [viewPendingOrder, setViewPendingOrder] = useState(null)

    const [trackingData, setTrackingData] = useState(null)



    const [courierServices, setCourierServices] = useRecoilState(courierServicesState);

    const courierServiceOptions = [{
        value: "",
        label: "All"
    }, ...courierServices.map(item => {
        return {
            value: item.id,
            label: item.title
        }
    })]


    const showOwcTracking = (tracking_number) => {
        const data = {
            trackingNo: tracking_number,
            close: () => {
                setTrackingData(null)
            }
        }

        setTrackingData(data)
    }


    const headers = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: "Service",
            accessor: "quote.name"
        },
        {
            Header: "Price",
            accessor: "final_amount",
            Cell: (row) => {
                return (row.value / 100).toFixed(2)
            },
        },
        {
            Header: "From Location",
            accessor: "shipment.pickup_name",
            Cell: (row) => {
                // console.log(row);
                // console.log(row.data[row.row.index].service_name);
                const data = row.data[row.row.index]
                return (
                    <div>
                        <p>Name: {row.value}</p>
                        <p>Email: {data.shipment.pickup_phone}</p>
                        <p>Phone: {data.shipment.pickup_phone}</p>
                        <p>Add1: {data.shipment.pickup_add1}</p>
                        <p>Add2: {data.shipment.pickup_add2}</p>
                        <p>Add3: {data.shipment.pickup_add3}</p>
                        <p>City: {data.shipment.pickup_city}</p>
                        <p>Zip: {data.shipment.pickup_zip}</p>
                        <p>State: {data.shipment.pickup_state}</p>
                        <p>Country: {data.shipment.pickup_country}</p>
                    </div>
                )
            }
        },
        {
            Header: "To Location",
            accessor: "shipment.delivery_name",
            Cell: (row) => {
                // console.log(row);
                // console.log(row.data[row.row.index].service_name);
                const data = row.data[row.row.index]
                return (
                    <div>
                        <p>Name: {row.value}</p>
                        <p>Email: {data.shipment.delivery_phone}</p>
                        <p>Phone: {data.shipment.delivery_phone}</p>
                        <p>Add1: {data.shipment.delivery_add1}</p>
                        <p>Add2: {data.shipment.delivery_add2}</p>
                        <p>Add3: {data.shipment.delivery_add3}</p>
                        <p>City: {data.shipment.delivery_city}</p>
                        <p>Zip: {data.shipment.delivery_zip}</p>
                        <p>State: {data.shipment.delivery_state}</p>
                        <p>Country: {data.shipment.delivery_country}</p>
                    </div>
                )
            }
        },
        {
            Header: "Pickup Date",
            accessor: "shipment.pickup_date",
            Cell: props => dayjs(props.value).format("DD-MMM-YYYY")
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: props => {
                return (
                    <>
                        {props.value == 'document_pending' &&
                            <button className="btn btn-secondary"
                                onClick={() => {
                                    setViewPendingOrder(props.data[props.row.index])
                                }}
                            >Pending Docs</button>
                        }
                        <p>{props.value}</p>
                    </>
                )
            }
        },
        {
            Header: "Tracking Number",
            accessor: "tracking_number",
        },
        {
            Header: "Shipping Label",
            accessor: "main_shipping_label",
            Cell: props => <a href={props.value} target={'_blank'} className={props.value && props.value != '' ? "sjduusy" : 'btn btn-disabled'}>Shipping Label </a>
        },
        {
            Header: "Documents",
            Cell: props => {
                const data = props.data[props.row.index]
                return (
                    <>
                        <button className="doc-btn btn"
                            onClick={() => {
                                setViewPendingOrder(props.data[props.row.index])
                            }}
                        >Documents</button>
                        {data.tracking_number &&
                            <button className="doc-btn btn ml-2" style={{ backgroundColor: '#7bce79' }} onClick={() => {
                                showOwcTracking(data.tracking_number)
                            }}>Track</button>
                        }
                    </>
                )
            }
        }
    ];

    if (!orders || !currentBusiness) {
        return <Loading />
    }

    return (
        <>
           

            <ReactTableBody
                body={orders}
                headers={headers}
            />

            {viewPendingOrder &&
                <DocumentsForOrderModal
                    order={viewPendingOrder}
                    close={() => {
                        setViewPendingOrder(null)
                    }}
                />
            }

            {trackingData &&
                <OwcTracking {...trackingData} />
            }
        </>
    )
}