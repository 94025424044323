import Layout from './layouts/Layout';
import {RecoilRoot} from 'recoil';
import Router from './Router';



function App() {
    return (
        <RecoilRoot>
            <Router />
        </RecoilRoot>

    );
}

export default App;
