import { object, string, email } from 'yup'
import getAuStateListJson from '../lib/getAuStateListJson';
import getCountryListJson from '../lib/getCountryListJson';
const warehouseValidation = object({
    name: string().required().min(2),
    email: string().email().required(),
    phone: string().required().min(8).max(12),
    company: string().nullable().max(200),
    add1: string().required().min(3).max(200),
    add2: string().nullable().min(3).max(200),
    add3: string().nullable().min(3).max(200),
    city: string().required(),
    zip: string().required(),
    state: string().required().test('au-invalid-state', 'Selected State is Invalid', (value, context) => {
        if(context.parent.country == 'AU'){
            let output = false;
            const states = getAuStateListJson();
            for (let i = 0; i < states.length; i++) {
                const element = states[i];
                if(element.value == value){
                    output = true;
                }
                if(output){
                    break;
                }                
            }

            return output;
        }
        return true;
    }),
    country: string().required().min(2).max(2).oneOf(getCountryListJson().map(country => country.value))
})

export default warehouseValidation;