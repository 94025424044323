import { useFormik } from "formik"
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import currentBusinessState from "../../atoms/currentBusinessState";
import Api from "../../lib/Api";
import getAuStateListJson from "../../lib/getAuStateListJson";
import handleFormikBackendValidation from "../../lib/handleFormikBackendValidation";
import shipmentValidation from "../../validation/shipmentValidation";
import Loading from "../Loading";
import nullToEmptyStringObjectProperty from '../../lib/nullToEmptyStringObjectProperty.js'
import FormikInputBootstrap from "../util/FormikInputBootstrap";
import BookingModal from "../BookingModal";

export default function PlaceOrder({ shipment, quote }) {

    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);

    const [loading, setLoading] = useState(false)

    const [orderModal, setOrderModal] = useState(false)










    const handleSubmit = async (values, actions) => {


        try {
            setLoading(true)
            const res = await Api.post(`/${currentBusiness.id}/shipment/quick/${shipment.id}`, values)
            if (res.success) {
                setOrderModal(true)
            }
            if (!res.success) {
                if (res.errors) {
                    handleFormikBackendValidation(res, actions)
                }
                else {
                    alert(res.message ? `${res.message}` : "Something Went Wrong");
                }
            }
            setLoading(false)
        } catch (error) {
            alert(error.message ? `${error.message}` : "Something went wrong");
            console.log(error);
            setLoading(false)
        }
    }





    const formik = useFormik({
        initialValues: shipment ? nullToEmptyStringObjectProperty(shipment) : {
            pickup_name: '',
            pickup_email: '',
            pickup_phone: '',
            pickup_company: '',
            pickup_add1: '',
            pickup_add2: '',
            pickup_add3: '',
            pickup_state: '',

            delivery_name: '',
            delivery_email: '',
            delivery_phone: '',
            delivery_company: '',
            delivery_add1: '',
            delivery_add2: '',
            delivery_add3: '',

            delivery_state: '',



        },
        onSubmit: handleSubmit
    })










    return (
        <div className="bg-white pb-4">
            <div className="container">
                <form style={{ paddingTop: '3rem' }} onSubmit={formik.handleSubmit}>

                    <div className="row">
                        <div className="col-md-6">

                            <label>Pickup</label>
                            <br />
                            <FormikInputBootstrap
                                formik={formik}
                                inputName={'pickup_name'}
                                label={'Name'}
                            />

                            <FormikInputBootstrap
                                inputName={'pickup_email'}
                                formik={formik}
                                label={'Email'}
                                type={'email'}
                            />

                            <FormikInputBootstrap
                                inputName={'pickup_phone'}
                                formik={formik}
                                label={'Phone Number'}
                                type={'tel'}
                            />

                            <FormikInputBootstrap
                                inputName={'pickup_add1'}
                                formik={formik}
                                label={'Address 1'}
                            />

                            <FormikInputBootstrap
                                inputName={'pickup_add2'}
                                formik={formik}
                                label={'Address 2'}
                            />

                            <FormikInputBootstrap
                                inputName={'pickup_add3'}
                                formik={formik}
                                label={'Address 3'}
                            />

                            {formik.values.pickup_country == "AU" &&
                                <FormikInputBootstrap
                                    inputName={'pickup_state'}
                                    formik={formik}
                                    label={'State'}
                                    type={'select'}
                                    options={getAuStateListJson()}
                                />
                            }

                            {formik.values.pickup_country != "AU" &&
                                <FormikInputBootstrap
                                    inputName={'pickup_state'}
                                    formik={formik}
                                    label={'State'}
                                />
                            }


                        </div>
                        <div className="col-md-6">

                            <label>Delivery</label>
                            <br />
                            <FormikInputBootstrap
                                formik={formik}
                                inputName={'delivery_name'}
                                label={'Name'}
                            />

                            <FormikInputBootstrap
                                inputName={'delivery_email'}
                                formik={formik}
                                label={'Email'}
                                type={'email'}
                            />

                            <FormikInputBootstrap
                                inputName={'delivery_phone'}
                                formik={formik}
                                label={'Phone Number'}
                                type={'tel'}
                            />

                            <FormikInputBootstrap
                                inputName={'delivery_add1'}
                                formik={formik}
                                label={'Address 1'}
                            />

                            <FormikInputBootstrap
                                inputName={'delivery_add2'}
                                formik={formik}
                                label={'Address 2'}
                            />

                            <FormikInputBootstrap
                                inputName={'delivery_add3'}
                                formik={formik}
                                label={'Address 3'}
                            />



                            {formik.values.delivery_country == "AU" &&
                                <FormikInputBootstrap
                                    inputName={'delivery_state'}
                                    formik={formik}
                                    label={'State'}
                                    type={'select'}
                                    options={getAuStateListJson()}
                                />
                            }

                            {formik.values.delivery_country != "AU" &&
                                <FormikInputBootstrap
                                    inputName={'delivery_state'}
                                    formik={formik}
                                    label={'State'}
                                />
                            }







                        </div>
                    </div>

                    {orderModal &&
                        <BookingModal
                            quote={quote}
                            close={() => {
                                setOrderModal(false)
                            }}
                            shipment={shipment}
                        />
                    }


                    {process.env.NODE_ENV != "production" &&
                        <div>
                            {JSON.stringify(formik.errors)}
                        </div>
                    }

                    {loading &&
                        <Loading />
                    }
                    {!loading &&
                        <button className="btn btn-primary">Save</button>
                    }
                </form>
            </div>
        </div>
    )
}