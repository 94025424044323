import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Footer() {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://widgets.widg.io/widgio-elements.js';
        script.async = true;

        document.body.appendChild(script);
    }, [])

    return (
        <>
            <footer className="footer-style pt-3 pb-3 bg-1d5b44 ">
                <div className="container text-white">
                    <div className="row align-items-center">
                        <div className="col-md-4 text-center text-md-left">
                            <img className="ft_logo" src={`${process.env.REACT_APP_LOGO_LOCATION}`} alt=""  />
                            <p className="text-white mt-3"><small>9/10 Ferngrove Pl,<br /> Chester Hill NSW 2162, <br /> Australia</small></p>
                        </div>
                        <div className="col-md-4">
                            <ul className="dhfxcfgb">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/" >Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/features" >Features</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact-us" >Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <small>
                                Call: +61 1300 658 775
                                <br />
                                Email: sales@dtdcaustralia.com.au
                                <br />
                                &copy; Copyright DTDC Australia. All Rights Reserved 
                                <br />
                                <a href="/privacy-policy" className="text-white">Privacy Statement</a>
                                <span className="me-2 ms-2">|</span>
                                <a href="/terms-condition" className="text-white">Terms of Use</a>                                
                            </small>
                        </div>
                    </div>
                </div>
                <div className="position-absolute"><div className="tb-logo">Developed By <a href="/terms-condition" className="text-white" target={'_blank'}><img src="https://www.themezbazar.com/assets/images/favicon.png" /></a></div></div>
            </footer>
            <allinone-reviews widgetid="5630" class="widgio-widget"></allinone-reviews>
        </>
    )

}