import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import currentBusinessState from "../../../atoms/currentBusinessState";
import Loading from "../../../components/Loading";
import ShipmentsTable from "../../../components/ShipmentsTable";
import Api from "../../../lib/Api";
import { Grid, _ } from 'gridjs-react'
import ReactTableBody from "../../../components/util/ReactTableBody";
import LinkToBookShipment from "../../../components/util/LinkToBookShipment";
import courierServicesState from "../../../atoms/courierServicesState";

import getQuotation from "../../../lib/getQuotation";
import BatchShipmentError from "../../../components/BatchShipmentError";
import prepareRequestForQuotation from "../../../lib/prepareRequestForQuotation";
import getQuoteForShipment from "../../../lib/getQuoteForShipment";
import dayjs from "dayjs";
import BatchShipmentView from "../../../components/BatchShipmentView";
import batchHelper from "../../../lib/batchHelper";


export default function ShipmentsPage() {
    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);

    const [shipments, setShipments] = useState(null);

    const [currentBatch, setCurrentBatch] = useState(null)

    const [courierServices, setCourierServices] = useRecoilState(courierServicesState)
    useEffect(() => {
        setShipments(null)
        if (currentBusiness && currentBusiness.id) {
            try {
                Api.get(`/${currentBusiness.id}/shipments`)
                    .then(res => {
                        if (res.success) {

                            console.log(res);
                            setShipments(res.shipments)
                        }
                        else {
                            alert(res.message ? `${res.message}` : "Something went wrong");
                        }
                    })
            } catch (error) {

            }
        }
    }, [currentBusiness]);

    const deleteShipment = async (id) => {
        try {
            const res = await Api.delete(`/${currentBusiness.id}/shipment/${id}`);
            if(res.success){
                setShipments(shipments.filter(s => s.id != id));
            }
            else{
                alert(res.message ?? "Something Went Wrong");
            }
        } catch (error) {
            alert(error.message ?? "Something Went Wrong");
        }        
    }



    const header = [

        {
            Header: 'ID',
            accessor: 'id', // accessor is the "key" in the data
        },
        {
            Header: "Delivery Name", 
            accessor: "delivery_name"
        },
        {
            Header: "Delivery Add 1",
            accessor: "delivery_add1"
        },
        {
            Header: "Delivery Add 2",
            accessor: "delivery_add2"
        },
        {
            Header: "Delivery Add 3",
            accessor: "delivery_add3"
        },
        {
            Header: "Delivery City",
            accessor: "delivery_city"
        },
        {
            Header: "Delivery Zip",
            accessor: "delivery_zip"
        },        
        {
            Header: "Delivery Country",
            accessor: "delivery_country"
        },
        {
            Header: "Action",
            accessor: d => d.id,
            Cell: props => <>
                <LinkToBookShipment shipmentId={props.value} />
                <button type="button" className="btn btn-secondary"
                    onClick={() => {
                        batchHelper.addShipmentToBatch(props.value, setCurrentBatch, currentBusiness, courierServices, currentBatch)
                    }}

                    // disabled={props.data[props.row.index].batch_id ? true : false}
                >Add To Batch</button>
                <button className="btn btn-danger"
                    onClick={() => {
                        deleteShipment(props.value)
                    }}
                >Delete</button>
            </>
        }
    ]

    if (!shipments) {
        return (
            <div className="login-form-container justify-around vh-100">
                <Loading />
            </div>
        )
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">Shipments</h3>

                <div className="card-tools">
                    <Link to={'/dashboard/shipments/import'} className='btn btn-primary me-2'>Import</Link>
                    <Link to={'/dashboard/shipments/add'} className='btn btn-success'>Add</Link>
                </div>
            </div>
            <div className="card-body">
                <div className="row w-100">
                    <div className={currentBatch ? 'col-9' : 'col-12'}>
                        <div className="w-100">
                            <ReactTableBody 
                                body={shipments} 
                                headers={header} 
                            />
                        </div>
                    </div>

                    {currentBatch && 
                        <div className="col-3">
                            {<BatchShipmentView 
                                removeShipmentFromBatch={(id) => {
                                    batchHelper.removeShipmentFromBatch(id, setCurrentBatch, currentBusiness, currentBatch)
                                }} 
                                currentBatch={currentBatch} 
                                setCurrentBatch={setCurrentBatch} 
                                handleCourierServiceChangeForBatch={(batchId, courierServiceId) => {
                                    batchHelper.handleCourierServiceChangeForBatch(batchId, courierServiceId,setCurrentBatch, courierServices, currentBusiness, currentBatch)
                                }}
                                recheckPrice={(shipmentId) => {
                                    batchHelper.addShipmentToBatch(shipmentId, setCurrentBatch, currentBusiness, courierServices, currentBatch)
                                }} 
                            />}
                        </div>
                    }
                    
                </div>
                

            </div>

            
        </div>
    )
}