import { object, string, email, number, date, array } from 'yup'
import getAuStateListJson from '../lib/getAuStateListJson';
import getCountryListJson from '../lib/getCountryListJson';
import { packageTypeList } from '../lib/getPackageTypeOptions';
import { productTypeList } from '../lib/getProductTypeOptions';
const shipmentValidation = object({
    pickup_name: string().required().min(2),
    pickup_email: string().email().required(),
    pickup_phone: string().required().min(8).max(12),
    pickup_company: string().nullable().max(200),
    pickup_add1: string().required().min(3).max(200),
    pickup_add2: string().nullable().min(3).max(200),
    pickup_add3: string().nullable().min(3).max(200),
    pickup_city: string().required(),
    pickup_zip: string().required(),
    pickup_state: string().required().test('au-invalid-state', 'Selected State is Invalid', (value, context) => {
        if (context.parent.pickup_country == 'AU') {
            let output = false;
            const states = getAuStateListJson();
            for (let i = 0; i < states.length; i++) {
                const element = states[i];
                if (element.value == value) {
                    output = true;
                }
                if (output) {
                    break;
                }
            }

            return output;
        }
        return true;
    }),
    pickup_country: string().required().min(2).max(2).oneOf(getCountryListJson().map(country => country.value)),
    delivery_name: string().required().min(2),
    delivery_email: string().email().required(),
    delivery_phone: string().required().min(8).max(12),
    delivery_company: string().nullable().max(200),
    delivery_add1: string().required().min(3).max(200),
    delivery_add2: string().nullable().min(3).max(200),
    delivery_add3: string().nullable().min(3).max(200),
    delivery_city: string().required(),
    delivery_zip: string().required(),
    delivery_state: string().required().test('au-invalid-state', 'Selected State is Invalid', (value, context) => {
        if (context.parent.delivery_country == 'AU') {
            let output = false;
            const states = getAuStateListJson();
            for (let i = 0; i < states.length; i++) {
                const element = states[i];
                if (element.value == value) {
                    output = true;
                }
                if (output) {
                    break;
                }
            }

            return output;
        }
        return true;
    }),
    delivery_country: string().required().min(2).max(2).oneOf(getCountryListJson().map(country => country.value)),
    // content_description: string().required().min(5).max(2000),
    pickup_date: date().required(),
    items: array().of(
        object().shape({
            height: number().required().min(1),
            width: number().required().min(1),
            length: number().required().min(1),
            weight: number().required().min(0.5),
            type: string().required().oneOf(packageTypeList()),
            value: number().required().min(1),
            content_description: string().required()
        })
    ).min(1, "You need atleast one item"),
    pickup_type: string().required().oneOf(['residential', 'commercial']),
    delivery_type: string().required().oneOf(['residential', 'commercial']),
    product_type: string().required().oneOf(productTypeList())
})

export default shipmentValidation;