import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import paymentGatewayEnums from "../../enums/paymentGatewayEnums";
import Api from "../../lib/Api";

export default function PaymentSuccess() {

    const [loading, setLoading] = useState(true);
    const { transaction_id } = useParams();

    const [intent, setIntent] = useState(null)

    useEffect(() => {
        try {
            Api.get(`/capture/${transaction_id}`)
                .then(res => {

                    if(res.success === undefined){
                        alert(res.message ? `${res.message}` : "Oops, something went wrong");
                        return;
                    }
                    if(res.gateway == paymentGatewayEnums.stripe){
                        if(res.intent){
                            setIntent(res.intent)
                            setLoading(false)
                        }
                    }
                    if(res.gateway == paymentGatewayEnums.paypal){
                        setIntent({
                            status: res.intent.status == "COMPLETED" ? "succeeded" : ""
                        })
                        setLoading(false)
                    }
                    
                })
        } catch (error) {
            alert("Oops, something went wrong")
        }


    }, [])

    if (loading || !intent) {
        return (
            <div className="login-form-container justify-around vh-100">
                <Loading />
            </div>
        )
    }


    return (
        <div className="success_text">
            {intent.status == "succeeded" && 
                <h3 className="text-success">Payment Done Successfully</h3>
            }
            {intent.status != "succeeded" && 
                <h3 className="text-danger">Looks Like Payment Failed</h3>
            }
            <Link to={'/dashboard/wallet'} className={'btn btn-primary'}>Go back to wallet</Link>
        </div>
    )
}