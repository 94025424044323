import { useState } from 'react';
import { usePapaParse } from 'react-papaparse';
import { useRecoilState } from 'recoil';
import Api from '../../../lib/Api';
import importErrorState from '../../../atoms/importErrorState';
import ImportedShipmentTable from '../../../components/ImportedShipmentTable';
import currentBusinessState from '../../../atoms/currentBusinessState';
import currentUserState from '../../../atoms/currentUserState';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function ShipmentImport() {

    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
    const [error, setError] = useRecoilState(importErrorState);

    const [isValidated, setIsValidated] = useState(false)

    const { readString } = usePapaParse();

    const [csvData, setCsvData] = useState({});

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    const updateTableData = (rowIndex, columnId, value) => {

        setCsvData(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    }
                }
                return row
            })
        )
    }

    const csvToJson = async (file) => {
        const stringData = await file.text();
        const maxLength = 500
        if (stringData.split('\\n').length > maxLength - 1) {
            alert(`File too big. Maximum csv length allowed : ${maxLength}`);
            setCsvData({})
            throw Error('CSV Too Big');
        }
        return readString(stringData);
    }

    const saveValidatedData = () => {
        setLoading(true)
        Api.post(`/${currentBusiness.id}/shipments`, {
            data: csvData
        })
            .then(res => {
                if (res.success) {
                    alert("Saved Successfully")
                    navigate('../');
                }
                else {
                    if (res.errors) {
                        setError(res.errors)
                        setIsValidated(false)
                        
                    }
                    else {
                        alert("Something went wrong")
                        console.log(res);
                        
                    }
                }
                setLoading(false)
            })
    }

    const handleSubmit = () => {
        if (document.getElementById('csv_input').files.length < 1) {
            alert("Please enter a CSV");
            setCsvData({})
            return;
        }
        if (!currentBusiness) {
            alert("Please choose a business");
            return;
        }
        const file = document.getElementById('csv_input').files[0];

        csvToJson(file).then(json => {
            json.data.shift()
            const data = prepareDataForImport(json.data.filter(item => item.length && item.length === 37))
            console.log(data);
            setCsvData(data)
        })
    }

    const prepareDataForImport = (inputData) => {
        const mainData = [];
        inputData.map((item, key) => {
            const data = {}
            let delivery_warehouse = null;
            let pickup_warehouse = null;

            let delivery_customer = null;
            let pickup_customer = null;

            if (item[0]) {
                delivery_warehouse = currentBusiness.warehouses.find(warehouse => warehouse.id == item[0]);
            }

            if (item[13]) {
                pickup_warehouse = currentBusiness.warehouses.find(warehouse => warehouse.id == item[13]);
            }

            if (item[1]) {
                pickup_customer = currentBusiness.customers.find(customer => customer.id == item[1]);
            }

            if (item[14]) {
                delivery_customer = currentBusiness.customers.find(customer => customer.id == item[14])
            }





            if (delivery_warehouse) {
                data.delivery_name = delivery_warehouse.name ?? '';
                data.delivery_phone = delivery_warehouse.phone ?? '';
                data.delivery_email = delivery_warehouse.email ?? '';
                data.delivery_company = delivery_warehouse.company ?? '';
                data.delivery_add1 = delivery_warehouse.add1 ?? '';
                data.delivery_add2 = delivery_warehouse.add2 ?? '';
                data.delivery_add3 = delivery_warehouse.add3 ?? '';
                data.delivery_city = delivery_warehouse.city ?? '';
                data.delivery_zip = delivery_warehouse.zip ?? '';
                data.delivery_state = delivery_warehouse.state ?? '';
                data.delivery_country = delivery_warehouse.country ?? '';
                data.delivery_warehouse_id = delivery_warehouse.id;
                data.delivery_customer_id = null;

            }
            else {
                if (delivery_customer) {
                    data.delivery_name = delivery_customer.name ?? '';
                    data.delivery_phone = delivery_customer.phone ?? '';
                    data.delivery_email = delivery_customer.email ?? '';
                    data.delivery_company = delivery_customer.company ?? '';
                    data.delivery_add1 = delivery_customer.add1 ?? '';
                    data.delivery_add2 = delivery_customer.add2 ?? '';
                    data.delivery_add3 = delivery_customer.add3 ?? '';
                    data.delivery_city = delivery_customer.city ?? '';
                    data.delivery_zip = delivery_customer.zip ?? '';
                    data.delivery_state = delivery_customer.state ?? '';
                    data.delivery_country = delivery_customer.country ?? '';
                    data.delivery_customer_id = delivery_customer.id;
                    data.delivery_warehouse_id = null;


                }
                else {
                    data.delivery_name = item[2];
                    data.delivery_phone = item[3];
                    data.delivery_email = item[4];
                    data.delivery_company = item[5];
                    data.delivery_add1 = item[6];
                    data.delivery_add2 = item[7];
                    data.delivery_add3 = item[8];
                    data.delivery_city = item[9];
                    data.delivery_zip = item[10];
                    data.delivery_state = item[11];
                    data.delivery_country = item[12];
                    data.delivery_warehouse_id = null;
                    data.delivery_customer_id = null;
                }
            }


            if (pickup_warehouse) {
                data.pickup_name = pickup_warehouse.name ?? '';
                data.pickup_phone = pickup_warehouse.phone ?? '';
                data.pickup_email = pickup_warehouse.email ?? '';
                data.pickup_company = pickup_warehouse.company ?? '';
                data.pickup_add1 = pickup_warehouse.add1 ?? '';
                data.pickup_add2 = pickup_warehouse.add2 ?? '';
                data.pickup_add3 = pickup_warehouse.add3 ?? '';
                data.pickup_city = pickup_warehouse.city ?? '';
                data.pickup_zip = pickup_warehouse.zip ?? '';
                data.pickup_state = pickup_warehouse.state ?? '';
                data.pickup_country = pickup_warehouse.country ?? '';
                data.pickup_warehouse_id = pickup_warehouse.id;
                data.pickup_customer_id = null;
            }
            else {
                if (pickup_customer) {
                    data.pickup_name = pickup_customer.name ?? '';
                    data.pickup_phone = pickup_customer.phone ?? '';
                    data.pickup_email = pickup_customer.email ?? '';
                    data.pickup_company = pickup_customer.company ?? '';
                    data.pickup_add1 = pickup_customer.add1 ?? '';
                    data.pickup_add2 = pickup_customer.add2 ?? '';
                    data.pickup_add3 = pickup_customer.add3 ?? '';
                    data.pickup_city = pickup_customer.city ?? '';
                    data.pickup_zip = pickup_customer.zip ?? '';
                    data.pickup_state = pickup_customer.state ?? '';
                    data.pickup_country = pickup_customer.country ?? '';
                    data.pickup_warehouse_id = null;
                    data.pickup_customer_id = pickup_customer.id;
                }
                else {
                    data.pickup_name = item[15];
                    data.pickup_phone = item[16];
                    data.pickup_email = item[17];
                    data.pickup_company = item[18];
                    data.pickup_add1 = item[19];
                    data.pickup_add2 = item[20];
                    data.pickup_add3 = item[21];
                    data.pickup_city = item[22];
                    data.pickup_zip = item[23];
                    data.pickup_state = item[24];
                    data.pickup_country = item[25];
                    data.pickup_warehouse_id = null;
                    data.pickup_customer_id = null;                    
                }
            }


            data.pickup_date = item[26]
            data.content_description = item[27]
            data.package_value = item[28]
            data.package_weight = item[29]
            data.package_length = item[30]
            data.package_height = item[31]
            data.package_width = item[32]
            data.package_type = item[33]
            data.product_type = item[34].trim().toLowerCase()
            data.delivery_type = item[35].trim().toLowerCase()
            data.pickup_type = item[36].trim().toLowerCase()
            data.business_id = currentBusiness.id;
            data.uploaded_by = currentUser.id;

            mainData.push(data)
        })

        console.log(mainData);
        return mainData;
    }

    const validateResult = () => {
        if (!currentBusiness) {
            alert("Please Choose a business!");
            return
        }
        setLoading(true)
        Api.post(`/${currentBusiness.id}/validateImport`, { data: csvData })
            .then(res => {
                if (res.errors) {
                    setError(res.errors);
                    setIsValidated(false)
                }
                else {
                    setError({})
                    setIsValidated(true)
                }
                setLoading(false)
            })
            .catch(e => {
                alert(e.message ?? "Something Went Wrong");
                setLoading(false)
            })
    }
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Import Excel</h3>

                    <div className="card-tools">
                    </div>
                </div>
                <div className="card-body">
                    <div className="container">
                        <div className='card shadow-light p-2'>
                            <div className='row align-items-center'>
                                <div className='col-sm-12 '>
                                    <a href='/template.csv' className='btn btn-warning line-height-auto me-3' target={'_blank'}><small>Download CSV</small></a>
                                    <small>You can download this template to import shipments</small>
                                </div>                                
                            </div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                        }} method="POST">
                            <div className="form-group enter-csv">
                                <label>Upload Csv</label>
                                <input type="file" name="csv" id="csv_input" className="form-control" />
                            </div>

                            <button className="btn btn-primary">Import</button>
                        </form>
                    </div>

                </div>
            </div>

            {csvData.length && csvData.length > 0 &&
                <ImportedShipmentTable
                    csvData={csvData}
                    validateResult={validateResult}
                    updateTableData={updateTableData}
                    isValidated={isValidated}
                    saveData={saveValidatedData}
                    setValidatedFalse={() => {
                        setIsValidated(false)
                    }}
                    loading={loading}

                />
            }

        </>

    )
}