import { useFormik } from "formik"
import { useEffect, useState } from "react";
import Select from "react-select";
import { useRecoilState } from "recoil";
import availableCustomersState from "../atoms/availableCustomersState";
import currentBusinessState from "../atoms/currentBusinessState";
import Api from "../lib/Api";
import getAuStateListJson from "../lib/getAuStateListJson";
import getCountryListJson from "../lib/getCountryListJson";
import getPackageTypeOptions from "../lib/getPackageTypeOptions";
import getPickupDeliveryTypeOptions from "../lib/getPickupDeliveryTypeOptions";
import getProductTypeOptions from "../lib/getProductTypeOptions";
import handleFormikBackendValidation from "../lib/handleFormikBackendValidation";
import shipmentValidation from "../validation/shipmentValidation";
import ItemsContainer from "./ItemsContainer";
import Loading from "./Loading";
import FormikInputBootstrap from "./util/FormikInputBootstrap";
import Warning from "./util/Warning";
import nullToEmptyStringObjectProperty from './../lib/nullToEmptyStringObjectProperty.js'
import CitySugesstion from "./util/CitySugesstion";

export default function ShipmentEditForm({ shipment = null, initialErrors = [], setShipment, successCallback = () => { } }) {

    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);
    const [customers, setCustomers] = useRecoilState(availableCustomersState);

    const [loading, setLoading] = useState(false)

    const [customersOptions, setCustomersOption] = useState(null);

    const [warehouseOptions, setWarehouseOption] = useState(null);


    const [showPickupZipSuggestion, setShowPickupZipSuggestion] = useState(false)
    const [showPickupCitySuggestion, setShowPickupCitySuggestion] = useState(false)
    const [showDeliveryCitySuggestion, setShowDeliveryCitySuggestion] = useState(false)
    const [showDeliveryZipSuggestion, setShowDeliveryZipSuggestion] = useState(false)

    const [allowTaillift, setAllowTaillift] = useState(false)

    



    useEffect(() => {
        if (currentBusiness && currentBusiness.id) {
            const options = currentBusiness.warehouses.filter(item => item.deleted_at == null).map(item => {
                return ({
                    label: `${item.name} - ${item.company ?? ''} - ${item.city} - ${item.country}`,
                    value: `${item.id}`
                })
            })

            setWarehouseOption(options)


        }
        else {
            setWarehouseOption([])
        }
    }, [currentBusiness])

    useEffect(() => {
        if (customers && customers.length > 0) {
            const options = customers.map(item => {
                return ({
                    label: `${item.name} - ${item.company ?? ''} - ${item.city} - ${item.country}`,
                    value: `${item.id}`
                })
            })

            setCustomersOption(options)


        }
        else {
            setCustomersOption([])
        }
    }, [customers])





    const handleSubmit = async (values, actions) => {
        // console.log(values);
        // return
        let shouldAllowTailliftBasedOnItems = false;
        

        formik.values.items.forEach(item => {
            if(item.type == 'Pallet'){
                shouldAllowTailliftBasedOnItems = true;
            }
        })

        if(formik.values.product_type != 'crate' && !shouldAllowTailliftBasedOnItems){
            values.taillift_at_delivery = false;
            values.taillift_at_pickup = false;
        }
        
        try {
            setLoading(true)
            const res = await Api.post(`/${currentBusiness.id}/shipment`, values)
            if (res.success) {
                setShipment(res.shipment)
                successCallback()
            }
            if (!res.success) {
                if (res.errors) {
                    handleFormikBackendValidation(res, actions)
                }
                else {
                    alert(res.message ? `${res.message}` : "Something Went Wrong");
                }
            }
            setLoading(false)
        } catch (error) {
            alert(error.message ? `${error.message}` : "Something went wrong");
            console.log(error);
            setLoading(false)
        }
    }





    const formik = useFormik({
        initialValues: shipment ? nullToEmptyStringObjectProperty(shipment) : {
            pickup_name: '',
            pickup_email: '',
            pickup_phone: '',
            pickup_company: '',
            pickup_add1: '',
            pickup_add2: '',
            pickup_add3: '',
            pickup_city: '',
            pickup_zip: '',
            pickup_state: '',
            pickup_country: 'AU',
            delivery_name: '',
            delivery_email: '',
            delivery_phone: '',
            delivery_company: '',
            delivery_add1: '',
            delivery_add2: '',
            delivery_add3: '',
            delivery_city: '',
            delivery_zip: '',
            delivery_state: '',
            delivery_country: 'AU',
            items: [
                {
                    height: '',
                    width: '',
                    length: '',
                    weight: '',
                    type: '',
                    content_description: '',
                    value: ''
                }
            ],
            pickup_date: '',
            product_type: '',
            pickup_type: '',
            delivery_type: '',
            taillift_at_pickup: false,
            taillift_at_delivery: false,
            delivery_warehouse_id: '',
            pickup_warehouse_id: '',
            pickup_customer_id: '',
            delivery_customer_id: '',
           
        },
        onSubmit: handleSubmit,
        validationSchema: shipmentValidation
    })

    let shouldAllowTailliftBasedOnItems = false;

    formik.values.items.forEach(item => {
        if(item.type == 'Pallet'){
            shouldAllowTailliftBasedOnItems = true;
        }
    })






    const setLocation = (id, type, target) => {
        let initialArray;
        if (type == 'customer') {
            initialArray = customers;
        }
        else {
            if (currentBusiness && currentBusiness.warehouses) {
                initialArray = currentBusiness.warehouses
            }
            else {
                initialArray = [];
            }
        }

        const item = initialArray.find(item => item.id == id);

        if (item) {
            let prefix = '';
            if (target == 'pickup') {
                prefix = 'pickup_';
            }
            else {
                prefix = 'delivery_';
            }
            const newItem = {}
            const excludedProps = ['id', 'created_at', 'updated_at', 'deleted_at', 'business_id'];
            for (const x in item) {
                if (Object.hasOwnProperty.call(item, x) && !excludedProps.includes(x)) {
                    const element = item[x];
                    newItem[`${prefix}${x}`] = element ?? '';
                }
            }

            newItem[`${prefix}${type}_id`] = id;
            if (type == 'customer') {
                newItem[`${prefix}warehouse_id`] = null;
            }
            else {
                newItem[`${prefix}customer_id`] = null;
            }

            formik.setValues({
                ...formik.values,
                ...newItem
            })
        }
    }


    useEffect(() => {
        console.log(formik.values);
    }, [formik])

    return (
        <div className="bg-white pb-4">
            <div className="container">
                <form style={{ paddingTop: '3rem' }} onSubmit={formik.handleSubmit}>
                    
                    <div className="row">
                        <div className="col-md-6">
                            <h5>Choose Sender</h5>
                            <div className="individual_item p-2 rounded mb-5">
                                <div className="form-group">
                                    <label>Address Book</label>
                                    {warehouseOptions &&

                                        <Select
                                            options={warehouseOptions}
                                            value={warehouseOptions.find(warehouse => warehouse.value == formik.values.pickup_warehouse_id)}
                                            onChange={val => {
                                                const value = val.value;
                                                setLocation(value, 'warehouse', 'pickup');

                                            }}
                                        // onBlur={formik.handleBlur}
                                        />
                                    }
                                    {!warehouseOptions &&
                                        <Loading />
                                    }

                                </div>
                                <div className="form-group">
                                    <label>Customer</label>
                                    {customersOptions &&
                                        <Select
                                            options={customersOptions}
                                            value={customersOptions.find(customer => customer.value == formik.values.pickup_customer_id)}
                                            onChange={val => {
                                                const value = val.value;
                                                setLocation(value, 'customer', 'pickup');
                                            }}
                                        // onBlur={formik.handleBlur}
                                        />
                                    }
                                    {!customersOptions &&
                                        <Loading />
                                    }

                                </div>
                            </div>

                            <label>Pickup</label>
                            <br />
                            <FormikInputBootstrap
                                formik={formik}
                                inputName={'pickup_name'}
                                label={'Name'}
                            />

                            <FormikInputBootstrap
                                inputName={'pickup_email'}
                                formik={formik}
                                label={'Email'}
                                type={'email'}
                            />

                            <FormikInputBootstrap
                                inputName={'pickup_phone'}
                                formik={formik}
                                label={'Phone Number'}
                                type={'tel'}
                            />

                            <FormikInputBootstrap
                                inputName={'pickup_add1'}
                                formik={formik}
                                label={'Address 1'}
                            />

                            <FormikInputBootstrap
                                inputName={'pickup_add2'}
                                formik={formik}
                                label={'Address 2'}
                            />

                            <FormikInputBootstrap
                                inputName={'pickup_add3'}
                                formik={formik}
                                label={'Address 3'}
                            />

                            {/* <FormikInputBootstrap
                            inputName={'pickup_add3'}
                            formik={formik}
                            label={'Address 3'}
                        /> */}

                            <FormikInputBootstrap
                                inputName={'pickup_country'}
                                formik={formik}
                                label={'Country'}
                                type='select'
                                options={getCountryListJson()}
                            />

                            <FormikInputBootstrap
                                inputName={'pickup_city'}
                                formik={formik}
                                label={'City'}
                                onKeyUp={() => {
                                    setShowPickupCitySuggestion(true)
                                }}
                            />
                            {showPickupCitySuggestion &&
                                <CitySugesstion
                                    input={formik.values.pickup_city}
                                    country={formik.values.pickup_country}
                                    callback={(city, zip) => {
                                        formik.setFieldValue('pickup_city', city)
                                        formik.setFieldValue('pickup_zip', zip)
                                        setShowPickupCitySuggestion(false)
                                        setShowPickupZipSuggestion(false)
                                    }}
                                />
                            }


                            <FormikInputBootstrap
                                inputName={'pickup_zip'}
                                formik={formik}
                                label={'Zip'}
                                onKeyUp={() => {
                                    setShowPickupZipSuggestion(true)
                                }}
                            />

                            {showPickupZipSuggestion &&
                                <CitySugesstion
                                    input={formik.values.pickup_zip}
                                    country={formik.values.pickup_country}
                                    callback={(city, zip) => {
                                        formik.setFieldValue('pickup_city', city)
                                        formik.setFieldValue('pickup_zip', zip)
                                        setShowPickupCitySuggestion(false)
                                        setShowPickupZipSuggestion(false)
                                    }}
                                />
                            }

                            {formik.values.pickup_country == "AU" &&
                                <FormikInputBootstrap
                                    inputName={'pickup_state'}
                                    formik={formik}
                                    label={'State'}
                                    type={'select'}
                                    options={getAuStateListJson()}
                                />
                            }

                            {formik.values.pickup_country != "AU" &&
                                <FormikInputBootstrap
                                    inputName={'pickup_state'}
                                    formik={formik}
                                    label={'State'}
                                />
                            }


                        </div>
                        <div className="col-md-6">
                            <h5>Choose Reciever </h5>
                            <div className="individual_item p-2 rounded mb-5">
                                <div className="form-group">
                                    <label>Address Book</label>
                                    {warehouseOptions &&
                                        <Select
                                            options={warehouseOptions}
                                            value={warehouseOptions.find(warehouse => warehouse.value == formik.values.delivery_warehouse_id)}
                                            onChange={val => {
                                                const value = val.value;
                                                setLocation(value, 'warehouse', 'delivery')
                                            }}
                                        />
                                    }
                                    {!warehouseOptions &&
                                        <Loading />
                                    }

                                </div>
                                <div className="form-group">
                                    <label>Customer</label>
                                    {customersOptions &&
                                        <Select
                                            options={customersOptions}
                                            value={customersOptions.find(customer => formik.values.delivery_customer_id == customer.value)}
                                            onChange={val => {
                                                const value = val.value;
                                                setLocation(value, 'customer', 'delivery');
                                            }}
                                        />
                                    }

                                    {!customersOptions &&
                                        <Loading />
                                    }

                                </div>
                            </div>
                            <label>Delivery</label>
                            <br />
                            <FormikInputBootstrap
                                formik={formik}
                                inputName={'delivery_name'}
                                label={'Name'}
                            />

                            <FormikInputBootstrap
                                inputName={'delivery_email'}
                                formik={formik}
                                label={'Email'}
                                type={'email'}
                            />

                            <FormikInputBootstrap
                                inputName={'delivery_phone'}
                                formik={formik}
                                label={'Phone Number'}
                                type={'tel'}
                            />

                            <FormikInputBootstrap
                                inputName={'delivery_add1'}
                                formik={formik}
                                label={'Address 1'}
                            />

                            <FormikInputBootstrap
                                inputName={'delivery_add2'}
                                formik={formik}
                                label={'Address 2'}
                            />

                            <FormikInputBootstrap
                                inputName={'delivery_add3'}
                                formik={formik}
                                label={'Address 3'}
                            />

                            {/* <FormikInputBootstrap
                            inputName={'delivery_add3'}
                            formik={formik}
                            label={'Address 3'}
                        /> */}

                            <FormikInputBootstrap
                                inputName={'delivery_country'}
                                formik={formik}
                                label={'Country'}
                                type='select'
                                options={getCountryListJson()}
                            />

                            <FormikInputBootstrap
                                inputName={'delivery_city'}
                                formik={formik}
                                label={'City'}
                                onKeyUp={() => {
                                    setShowDeliveryCitySuggestion(true)
                                }}
                            />

                            {showDeliveryCitySuggestion &&
                                <CitySugesstion
                                    input={formik.values.delivery_city}
                                    country={formik.values.delivery_country}
                                    callback={(city, zip) => {
                                        formik.setFieldValue('delivery_city', city)
                                        formik.setFieldValue('delivery_zip', zip)
                                        setShowDeliveryCitySuggestion(false)
                                        setShowDeliveryZipSuggestion(false)
                                    }}
                                />
                            }

                            <FormikInputBootstrap
                                inputName={'delivery_zip'}
                                formik={formik}
                                label={'Zip'}
                                onKeyUp={() => {
                                    setShowDeliveryZipSuggestion(true)
                                }}
                            />

                            {showDeliveryZipSuggestion &&
                                <CitySugesstion
                                    input={formik.values.delivery_zip}
                                    country={formik.values.delivery_country}
                                    callback={(city, zip) => {
                                        formik.setFieldValue('delivery_city', city)
                                        formik.setFieldValue('delivery_zip', zip)
                                        setShowDeliveryCitySuggestion(false)
                                        setShowDeliveryZipSuggestion(false)
                                    }}
                                />
                            }

                            {formik.values.delivery_country == "AU" &&
                                <FormikInputBootstrap
                                    inputName={'delivery_state'}
                                    formik={formik}
                                    label={'State'}
                                    type={'select'}
                                    options={getAuStateListJson()}
                                />
                            }

                            {formik.values.delivery_country != "AU" &&
                                <FormikInputBootstrap
                                    inputName={'delivery_state'}
                                    formik={formik}
                                    label={'State'}
                                />
                            }







                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ paddingTop: '2rem' }}>
                            <h5>Package Details</h5>
                        </div>



                        <div className="col-md-6">
                            <FormikInputBootstrap
                                inputName={'pickup_date'}
                                formik={formik}
                                label={'Pick Up Date'}
                                type={'date'}
                            />
                        </div>




                        <div className="col-md-6">
                            <FormikInputBootstrap
                                inputName={'product_type'}
                                formik={formik}
                                label={'Product Type'}
                                type='select'
                                options={getProductTypeOptions()}
                            />
                        </div>

                        <div className="col-md-6">
                            <FormikInputBootstrap
                                inputName={'pickup_type'}
                                formik={formik}
                                label={'Pickup Location Type'}
                                type='select'
                                options={getPickupDeliveryTypeOptions()}
                            />
                        </div>

                        <div className="col-md-6">
                            <FormikInputBootstrap
                                inputName={'delivery_type'}
                                formik={formik}
                                label={'Delivery Location Type'}
                                type='select'
                                options={getPickupDeliveryTypeOptions()}
                            />
                        </div>


                        <div className="col-md-12">
                            <ItemsContainer
                                formik={formik}
                            />
                        </div>

                        {(formik.values.product_type == 'crate' || shouldAllowTailliftBasedOnItems) &&
                            <>
                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input type={'checkbox'} className="form-check-input" checked={formik.values.taillift_at_delivery} 
                                            onChange={() => {
                                                formik.setFieldValue('taillift_at_delivery', !formik.values.taillift_at_delivery)
                                            }}
                                        />
                                        <label>TailLift At Delivery</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-check">
                                        
                                        <input type={'checkbox'} className="form-check-input" checked={formik.values.taillift_at_pickup} 
                                            onChange={() => {
                                                formik.setFieldValue('taillift_at_pickup', !formik.values.taillift_at_pickup)
                                            }}
                                        />
                                        <label>TailLift At Pickup</label>
                                    </div>
                                </div>
                            </>
                        }

                        {initialErrors.map((error, key) => <Warning key={key} message={error} />)}
                    </div>

                    {process.env.NODE_ENV != "production" &&
                        <div>
                            {JSON.stringify(formik.errors)}
                        </div>
                    }

                    {loading &&
                        <Loading />
                    }
                    {!loading &&
                        <button className="btn btn-primary">Save</button>
                    }
                </form>
            </div>
        </div>
    )
}