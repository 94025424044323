import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import currentBusinessState from "../../atoms/currentBusinessState";
import DashboardChart from "../../components/DashboardChart";
import Loading from "../../components/Loading";
import Api from "../../lib/Api";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Carousel from "../../components/Carousel";
import News from "../../components/News";

export default function DashboardPage() {

    const [chartData, setChartData] = useState(null);
    const [data, setData] = useState(null);

    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);
    dayjs.extend(customParseFormat)

    useEffect(() => {
        if (currentBusiness && currentBusiness.id) {
            setChartData(null);
            setData(null);
            Api.get(`/${currentBusiness.id}/dashboard`)
                .then(res => {
                    if (res.success) {
                        setChartData(res.graphData);
                        setData({
                            orderCount: res.order_count,
                            customerCount: res.customer_count,
                            warehouseCount: res.warehouse_count,
                            shipmentCount: res.shipment_count,
                            walletBalance: res.wallet_balance,
                            latestOrders: res.latest_orders
                        })
                    }
                })
        }
    }, [currentBusiness])

    return (
        <>
            <div className=" pt-1 pl-4 pr-4 pb-4">
                <h5 className="pb-4 mb-5 text-white"><strong>Dashboard</strong></h5>
                <div>
                    {!data &&
                        <Loading />
                    }
                    {data &&
                        <>
                            <div className="row mb-4">
                                <div className="col-sm-24">
                                    <p className="dash-card-style"><img src="/icon1.png" alt="" /> <span className="bcnfht"> {(data.walletBalance / 100).toFixed(2)} </span><br /> Wallet </p>
                                </div>
                                <div className="col-sm-24">
                                    <p className="dash-card-style"><img src="/icon2.png" alt="" /> <span className="bcnfht"> {data.customerCount} </span><br /> customer </p>
                                </div>
                                <div className="col-sm-24">
                                    <p className="dash-card-style"><img src="/icon3.png" alt="" /> <span className="bcnfht"> {data.warehouseCount} </span><br /> warehouse </p>
                                </div>
                                <div className="col-sm-24">
                                    <p className="dash-card-style"><img src="/icon4.png" alt="" /> <span className="bcnfht"> {data.shipmentCount} </span><br /> shipment </p>
                                </div>
                                <div className="col-sm-24">
                                    <p className="dash-card-style"><img src="/icon5.png" alt="" /> <span className="bcnfht"> {data.orderCount} </span><br /> order </p>
                                </div>
                            </div>
                        </>
                    }
                </div>

                <div className="row">
                    {chartData &&
                        <div className="col-sm-7">
                            <div className="dash-card-style p-3 dash-chart-text">


                                <DashboardChart
                                    dashboardData={chartData}
                                />

                            </div>
                        </div>
                    }

                    {data &&
                        <div className="col-sm-5">
                            <div className="dash-card-style p-3 ap-ratio-2">

                                {data &&
                                    <>
                                        <div className="ghfdsh">
                                            <table className="table table-striped text-white">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">From </th>
                                                        <th scope="col">To </th>
                                                        <th scope="col">Pickup Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.latestOrders.map(order => {
                                                        return (
                                                            <tr>
                                                                <th>{order.id}</th>
                                                                <td>{process.env.REACT_APP_CURRENCY_CODE} {(order.final_amount / 100).toFixed(2)}</td>
                                                                <td>{order.shipment.pickup_name} </td>
                                                                <td>{order.shipment.delivery_name} </td>
                                                                <td>{dayjs(order.shipment.pickup_date).format("DD-MMM-YYYY")}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    }

                </div>
                <News />

            </div>

        </>

    )
}