import React, { useState } from 'react'
import useCollapse from 'react-collapsed'

export default function Collapse({children, name}) {
    const [isExpanded, setExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

    return (
        <div>
            <button className='btn collps-d'
                {...getToggleProps({
                    onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                })}
            >
                {/* {isExpanded ? 'SMTP details' : 'Expand'} */}
                {name}
            </button>
            <section {...getCollapseProps()}>
                {children}
            </section>
        </div>
    )
}