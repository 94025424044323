import { useFormik } from "formik";
import { useState } from "react";
import Api from "../lib/Api";
import getAuStateListJson from "../lib/getAuStateListJson";
import getCountryListJson from "../lib/getCountryListJson";
import handleFormikBackendValidation from "../lib/handleFormikBackendValidation";
import nullToEmptyStringObjectProperty from "../lib/nullToEmptyStringObjectProperty";
import warehouseValidation from "../validation/warehouseValidation";
import Loading from "./Loading";
import CitySugesstion from "./util/CitySugesstion";
import FormikInputBootstrap from "./util/FormikInputBootstrap";
import Modal from "./util/Modal";

export default function WarehouseAdd({ setWarehouses, currentBusiness, close, warehouse = null }) {

    const [loading, setLoading] = useState(false)

    const [showCitySugesstion, setShowCitySugesstion] = useState(false)
    const [showZipSugesstion, setShowZipSugesstion] = useState(false)

    const handleSubmit = async (values, actions) => {
        setLoading(true)
        try {
            const res = await Api.post(`/${currentBusiness.id}/warehouse`, values);
            if (res.id) {
                setWarehouses(res)
                close()
            }
            else {
                if (res.errors) {
                    handleFormikBackendValidation(res, actions);
                }
                else {
                    alert(res.message ? `${res.message}` : "Something Went Wrong");
                }
            }
            setLoading(false)
        } catch (error) {
            alert(error.message ? `${error.message}` : "Something Went Wrong");
            setLoading(false);
        }
    }

    const formik = useFormik({
        initialValues: warehouse ? nullToEmptyStringObjectProperty(warehouse) : {
            name: '',
            email: '',
            phone: '',
            company: currentBusiness ? currentBusiness.name : '',
            add1: '',
            add2: '',
            add3: '',
            city: '',
            zip: '',
            state: '',
            country: 'AU'
        },
        onSubmit: handleSubmit,
        validationSchema: warehouseValidation
    })

    return (
        <Modal
            title={warehouse ? `Edit Address Book : ${warehouse.name}` : "Add Address Book"}
            close={close}
        >
            <form onSubmit={formik.handleSubmit}>

                <FormikInputBootstrap
                    formik={formik}
                    inputName={'name'}
                    label={'Contact Name'}
                />

                <FormikInputBootstrap
                    formik={formik}
                    inputName={'email'}
                    label={'Contact Email'}
                />

                <FormikInputBootstrap
                    formik={formik}
                    inputName={'phone'}
                    label={'Contact Phone'}
                    type={'tel'}
                />

                <FormikInputBootstrap
                    formik={formik}
                    inputName={'company'}
                    label={'Company Name'}
                />

                <FormikInputBootstrap
                    formik={formik}
                    inputName={'add1'}
                    label={'Address 1'}
                />

                <div className="row">
                    <div className="col-6">
                        <FormikInputBootstrap
                            formik={formik}
                            inputName={'add2'}
                            label={'Address 2'}
                        />
                    </div>
                    <div className="col-6">
                        <FormikInputBootstrap
                            formik={formik}
                            inputName={'add3'}
                            label={'Address 3'}
                        />
                    </div>
                </div>

                <FormikInputBootstrap
                    formik={formik}
                    inputName={'country'}
                    label={'Country'}
                    type={'select'}
                    options={getCountryListJson()}
                />

                <div className="row">
                    <div className="col-6">
                        <FormikInputBootstrap
                            formik={formik}
                            inputName={'city'}
                            label={'City'}
                            onKeyUp={() => {
                                setShowCitySugesstion(true)
                            }}
                        />

                        {showCitySugesstion &&
                            <CitySugesstion
                                input={formik.values.city}
                                country={formik.values.country}
                                callback={(city, zip) => {
                                    formik.setFieldValue('zip', zip);
                                    formik.setFieldValue('city', city);
                                    setShowCitySugesstion(false)
                                    setShowZipSugesstion(false)
                                }}
                            />
                        }

                    </div>
                    <div className="col-6">
                        <FormikInputBootstrap
                            formik={formik}
                            inputName={'zip'}
                            label={'Zip'}
                            onKeyUp={() => {
                                setShowZipSugesstion(true)
                            }}
                        />

                        {showZipSugesstion &&
                            <CitySugesstion
                                input={formik.values.zip}
                                country={formik.values.country}
                                callback={(city, zip) => {
                                    formik.setFieldValue('zip', zip);
                                    formik.setFieldValue('city', city);
                                    setShowZipSugesstion(false)
                                    setShowCitySugesstion(false)
                                }}
                            />
                        }
                    </div>
                </div>

                {formik.values.country == 'AU' &&
                    <FormikInputBootstrap
                        formik={formik}
                        inputName={'state'}
                        label={'State'}
                        type={'select'}
                        options={getAuStateListJson()}
                    />
                }

                {formik.values.country != 'AU' &&
                    <FormikInputBootstrap
                        formik={formik}
                        inputName={'state'}
                        label={'State'}
                    />
                }

                
                <div className="text-center">
                    {!loading &&
                        <button className="btn btn-primary">Save</button>
                    }
                    {loading &&
                        <Loading />
                    }
                </div>
            </form>
        </Modal>
    )
} 