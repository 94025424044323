import { useState } from "react";
import BookingModal from "./BookingModal";
import SingleQuote from "./SingleQuote";
import Modal from "./util/Modal";
import ShipmentEditForm from "./ShipmentEditForm";

export default function QuotaionContainer({ quotes, shipment, couponCode, quickQuote = false, setShipment, quickQuoteCallback = () => {}}) {

    const [bookingQuote, setBookingQuote] = useState(null)
    const [showShipmentEditForm, setShowShipmentEditForm] = useState(false)

    const bookQuote = (quote) => {
        if(quickQuote){
            setShowShipmentEditForm(true)
        }
        else{
            quickQuoteCallback(quote)
        }
        setBookingQuote(quote)
    }

    if(showShipmentEditForm){
        return <ShipmentEditForm 
            shipment={shipment}
            initialErrors={[]}
            setShipment={setShipment}
            successCallback={() => { }} 
        />
    }

    return (
        <div className="quotation_container">
            {quotes &&
                quotes.sort((a, b) => a.price - b.price).map((quote, index) => <SingleQuote
                    key={index}
                    quote={quote}
                    bookQuote={() => { bookQuote(quote) }}
                />
                )
            }

            {bookingQuote &&
                <BookingModal
                    quote={bookingQuote}
                    close={() => {
                        setBookingQuote(null)
                    }}
                    shipment={shipment}
                    couponCode={couponCode}
                />
            }
        </div>
    )
}