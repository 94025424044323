import CsvDownloadButton from 'react-json-to-csv';
import WalletTransactions from './WalletTransactions';


export default function WalletPrintableArea({ wallet, filterOptions, setFilterOptions, csvData, isPrintMode = false }) {
    return (
        <>
            <div className="card-body">
                <div className="wallet-container">
                    <div className="wallet-balance pt-5 pb-5">
                        <h1><img src="/dollar.png" alt="" /> Available Balance <br /><span> {process.env.REACT_APP_CURRENCY_CODE} {(wallet.balance / 100).toFixed(2)} </span></h1>
                    </div>
                </div>


            </div>

            <div className="card-footer">
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>From Date</label>
                            <input type={'date'} className="form-control" value={filterOptions.from_date} onChange={e => {
                                setFilterOptions({ ...filterOptions, ...{ from_date: e.target.value } })
                            }} 
                            disabled={isPrintMode}
                            />
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="form-group">
                            <label>To Date</label>
                            <input type={'date'} className="form-control" value={filterOptions.to_date} onChange={e => {
                                setFilterOptions({ ...filterOptions, ...{ to_date: e.target.value } })
                            }} 
                            disabled={isPrintMode}
                            />
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Status</label>
                            <select className="form-control" 
                                disabled={isPrintMode}
                            >
                                <option value={''}>Any</option>
                                <option value={'paid'} selected={filterOptions.payment_type == 'paid'}>Paid</option>
                                <option value={'unpaid'} selected={filterOptions.payment_type == 'unpaid'}>Unpaid</option>
                            </select>
                        </div>
                    </div>
                    

                </div>
                <WalletTransactions
                    transactions={wallet.transactions}
                />
            </div>
        </>
    )
}