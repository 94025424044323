export default function getAuStateListJson() {
    return [
        {
            "label": "ACT",
            "value": "ACT"
        },
        {
            "label": "NSW",
            "value": "NSW"
        },
        {
            "label": "NT",
            "value": "NT"
        },
        {
            "label": "QLD",
            "value": "QLD"
        },
        {
            "label": "SA",
            "value": "SA"
        },
        {
            "label": "TAS",
            "value": "TAS"
        },
        {
            "label": "VIC",
            "value": "VIC"
        },
        {
            "label": "WA",
            "value": "WA"
        },
    ];
}