import { useEffect } from "react";
import { useRecoilState } from "recoil"
import dangerousItemsState from "../../atoms/dangerousItemsState"
import Api from "../../lib/Api";

export default function GetAvailableDangerousItem(){
    const [dangerousItems, setDangerousItems] = useRecoilState(dangerousItemsState);

    useEffect(() => {
        Api.get('/dangerous_items')
            .then(res => {
                if(res.success){
                    setDangerousItems(res.dangerous_items);
                }
                else{
                    setDangerousItems([]);
                }
            })
            .catch(e => {
                setDangerousItems([]);
            })
    } ,[])
    return <></>
}