import { useEffect, useState } from "react";
import DocumentationUpdateSingleInput from "./DocumentationUpdateSingleInput";
import Api from './../lib/Api'
import Warning from "./util/Warning";
import currentUserState from "../atoms/currentUserState";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas'


export default function UpdateOrderDocument({ order, inputs, code = '' }) {

    const [data, setData] = useState({});
    const [errors, setErrors] = useState([])
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

    const [signatureBlob, setSignatureBlob] = useState(null);

    const navigate = useNavigate();
    useEffect(() => {
        const myData = {};
        inputs.forEach(inp => {
            myData[inp.id] = ''
        });
        setData(myData);
    }, [])

    const setValue = (evt, name, type) => {
        const myData = { ...data }
        if (type == 'file') {
            myData[name] = evt.target.files[0];
        }
        else {
            myData[name] = evt.target.value;
        }
    }

    let sigPad = {};

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = new FormData(e.target);
            data.append('signature', signatureBlob);

            const res = await Api.filePost(`/customer_document/${order.id}`, data);

            if (res.success) {
                alert("Your Document Has been Saved!")
                if (currentUser && currentUser.id) {
                    navigate('/dashboard')
                }
                else {
                    navigate('/')
                }
            }
            else {
                if (res.errors) {
                    const myErrors = [];
                    for (const x in res.errors) {
                        if (Object.hasOwnProperty.call(res.errors, x)) {
                            const errList = res.errors[x];
                            myErrors.push(errList[0]);
                        }
                    }

                    console.log(myErrors);

                    setErrors(myErrors);
                }
            }
        } catch (error) {
            alert(error.message ?? "Something Went Wrong");
        }

    }
    return <>
        <div className="container">
            <p>Hey, {order.is_outbound ? order.shipment.delivery_name : order.shipment.pickup_name}</p>
            <p>
                Your Shipment from {order.shipment.business.name} is almost ready to be shipped.
                We just need a few documents before we can ship it to you.
            </p>

            <form className="p-4" onSubmit={handleSubmit}>
                <input type={'hidden'} name={'code'} value={code} />
                <div className="row">
                    {inputs.map(input => <DocumentationUpdateSingleInput
                        key={input.id}
                        name={`document_${input.id}`}
                        input={input}
                        setValue={setValue}

                    />)}
                    {(order.is_outbound && order.shipment.delivery_customer_id) || (!order.is_outbound && order.shipment.pickup_customer_id) &&

                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="save_data" value="1" id="flexCheckDefault" />
                            <label class="form-check-label" for="flexCheckDefault">
                                Save this data to automate this process in future?
                            </label>
                        </div>
                    }

                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Sign Here</label>
                            <br />
                            <div style={{ border: '1px solid black', width: 'min-content' }}>
                                <SignatureCanvas ref={(ref) => sigPad = ref} penColor='black'
                                    canvasProps={{ className: 'sigCanvas' }} 
                                    onEnd={() => {
                                        sigPad.getCanvas().toBlob((blob) => {
                                            setSignatureBlob(blob)
                                        })
                                    }}    
                                />
                            </div>

                        </div>
                    </div>

                </div>



                {errors.length > 0 &&
                    errors.map((e, key) => <Warning key={key} message={e} />)
                }

                <button className="btn btn-primary">Save</button>
            </form>
        </div>
    </>
}