import { useState } from "react"
import showB64File from "../lib/showB64File"

export default function DocumentForOrderSingleItem({ doc, edit }) {

    return (
        <>
            <tr key={doc.id}>
                <td>{doc.name}</td>

                <td>
                    {!doc.value &&
                        <p>Pending....</p>
                    }
                    {doc.value && doc.type == 'file' &&
                        <a className={`btn btn-secondary`}
                            onClick={() => {
                                showB64File(doc.value, doc.mime)
                            }}
                        >View</a>
                    }
                    {doc.type != 'file' && 
                        <p>{doc.value}</p>
                    }

                </td>
                <td>
                    <button type="button" className="btn btn-secondary"
                        disabled={!doc.can_edit}
                        onClick={edit}
                    >Update</button>
                </td>
            </tr>

        </>

    )
}