export default function SingleQuote({quote, bookQuote}){
    return (
        <div className="row single_quote">
            <div className="col-2 col-lg-1">
                <img src={`${process.env.REACT_APP_DTDC_PUBLIC_ASSET_URL}/${quote.logo}`} className="courier_service_logo" />
            </div>
            <div className="col-5 col-lg-9">
                <p>{quote.name}</p>
                <p>Delivery : {quote.estd_delivery} 
                    {quote.notes && 
                        <span title={quote.notes}><i style={{color: '#2d9d37', marginLeft: '1rem', cursor: 'help'}} className="fa fa-info-circle green info-green"></i></span>
                    }
                </p>
            </div>
            <div className="col-2 col-lg-1 text-center">
                <p className="bold_font">{process.env.REACT_APP_CURRENCY_CODE} {parseFloat(quote.price / 100).toFixed(2)} </p>
                {quote.discount &&
                    <p><s>{process.env.REACT_APP_CURRENCY_CODE} {parseFloat((quote.price + quote.discount) / 100).toFixed(2)} </s></p>
                }
            </div>
            <div className="col-3 col-lg-1 text-end">
                <button className="btn btn-success" onClick={bookQuote}>Book Now</button>
            </div>
        </div>
    )
}