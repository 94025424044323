import Warning from "./Warning";
import Select from 'react-select'

export default function FormikInputBootstrap({ inputName, label, formik, type = 'text', options = [], error = null, value = null, step= 1, onKeyUp = (val) => {} }) {

    if (options.length > 0 && type == 'select') {
        let selectedOption = '';
        let myValue = value ?? formik.values[inputName];
        options.forEach(item => {
            if (item.value == myValue) {
                selectedOption = item;
            }
        });


        return (
            <div className="form-group">
                <label>{label}</label>
                <Select
                    name={inputName}
                    options={options}
                    value={selectedOption}
                    onChange={(value) => {
                        formik.setFieldValue(`${inputName}`, value.value);
                    }}
                    onBlur={formik.handleBlur}
                />
                {error &&
                    <Warning message={error} />
                }
                {!error && formik.errors[inputName] && formik.touched[inputName] ? <Warning message={formik.errors[inputName]} /> : null}
            </div>

        )
    }

    if (type == 'textarea') {
        return (
            <div className="form-group">
                <label>{label}</label>
                <textarea className="form-control" name={inputName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={value ?? formik.values[inputName]}></textarea>
                {error &&
                    <Warning message={error} />
                }
                {!error && formik.errors[inputName] && formik.touched[inputName] ? <Warning message={formik.errors[inputName]} /> : null}
            </div>
        );
    }

    return (
        <div className="form-group">
            <label>{label}</label>
            <input className="form-control" name={inputName} type={type} step={step}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={value ?? formik.values[inputName]}
                onKeyUp={(e) => onKeyUp(e.target.value)}
            />
            {error &&
                <Warning message={error} />
            }
            {!error && formik.errors[inputName] && formik.touched[inputName] ? <Warning message={formik.errors[inputName]} /> : null}
        </div>
    )
}