import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import availableBusinessesState from "../../atoms/availableBusinessesState";
import BusinessSignature from "../../components/BusinessSignature";
import Collapse from "../../components/Collapse";
import Warning from "../../components/util/Warning";
import Api from "../../lib/Api";

export default function BusinessSettings() {
    const [availableBusiness, setAvailableBusiness] = useRecoilState(availableBusinessesState);
    const [loading, setLoading] = useState(true)
    const { business_id } = useParams();

    const [business, setBusiness] = useState(null);

    const [errors, setErrors] = useState([]);

    const nav = useNavigate()

    useEffect(() => {
        if (business_id && availableBusiness && availableBusiness.length && !business) {
            const myBusiness = availableBusiness.find(item => item.id == business_id);

            if (myBusiness) {
                setBusiness(myBusiness);
                setLoading(false)
            }
            else {
                window.location.reload()
            }
        }
    }, [availableBusiness, business_id, business])

    

    return (
        <div className="card">
            <div className="card-body">
                <div className="container">
                    <div className="mb-5">
                        <div className="row">
                            {/* <div className="col-sm-12">
                                <Collapse name={'Put SMTP details'}>
                                    <form>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>SMTP Host</label>
                                                    <input type={'text'} name="" className="form-control smtp-input" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>SMTP Username</label>
                                                    <input type={'text'} name="" className="form-control smtp-input" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>SMTP Password</label>
                                                    <input type={'password'} name="" className="form-control smtp-input" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>SMTP Mail From</label>
                                                    <input type={'mail'} name="" className="form-control smtp-input" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>SMTP Mail From Name</label>
                                                    <input type={'text'} name="" className="form-control smtp-input" />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label><input type={'checkbox'} name="" className="smtp-input-checkbox" /> Use Business SMTP</label>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn btn-success">Submit</button>
                                    </form>
                                </Collapse>
                            </div> */}
                            <div className="col-sm-12">
                                <Collapse name={'Signature'}>
                                    <BusinessSignature 
                                        business={business}
                                    />
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}