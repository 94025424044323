import ReactTableBody from "./util/ReactTableBody";

export default function WarehouseContainer({ warehouses, setEditWarehouse, setShowModal, deleteWarehouse }) {
    if (!warehouses) {
        throw TypeError("Warehouses is Not Valid Array");
    }

    const headers = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: "Name",
            accessor: 'name'
        },
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "Phone",
            accessor: "phone"
        },
        {
            Header: "Add1",
            accessor: "add1"
        },
        {
            Header: "Add2",
            accessor: "add2"
        },
        {
            Header: "Add3",
            accessor: "add3"
        },
        {
            Header: "City",
            accessor: "city"
        },
        {
            Header: "Zip",
            accessor: "zip"
        },
        {
            Header: "Country",
            accessor: "country"
        },
        {
            Header: "Action",
            accessor: d => d.id,
            Cell: props => <><button className="btn btn-primary" onClick={() => {
                let myWarehouse = null;
                warehouses.forEach(warehouse => {
                    if(warehouse.id == props.value){
                        myWarehouse = warehouse
                    }
                });
                if(myWarehouse){
                    setEditWarehouse(myWarehouse)
                    setShowModal(true)
                }

            }}>Edit</button>
            <button className="btn btn-danger" onClick={() => {
                let myWarehouse = null;
                warehouses.forEach(warehouse => {
                    if(warehouse.id == props.value){
                        myWarehouse = warehouse
                    }
                });
                if(myWarehouse){
                    deleteWarehouse(myWarehouse)
                }
            }}>Delete</button></>
        },

    ];

    return (
        <div className="warehouse_container">
            <ReactTableBody body={warehouses} headers={headers} />
        </div>
    );
}