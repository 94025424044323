import { Route, Routes } from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import StandardLayout from "./layouts/StandardLayout";
import AcceptBusinessInvite from "./pages/AcceptBusinessInvite";
import Login from "./pages/Auth/Login";
import PasswordReset from "./pages/Auth/PasswordReset";
import Signup from "./pages/Auth/Signup";
import BusinessIndex from "./pages/Dashboard/Business/Index";
import CustomerIndex from "./pages/Dashboard/Customer/CustomerIndex";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import PaymentSuccess from "./pages/Dashboard/PaymentSuccess";
import AddShipment from "./pages/Dashboard/Shipment/AddShipment";
import BookShipment from "./pages/Dashboard/Shipment/BookShipment";
import OrdersPage from "./pages/Dashboard/Shipment/OrdersPage";
import ShipmentImport from "./pages/Dashboard/Shipment/ShipmentImport";
import ShipmentsPage from "./pages/Dashboard/Shipment/ShipmentsPage";
import WalletPage from "./pages/Dashboard/WalletPage";
import WarehouseIndex from "./pages/Dashboard/Warehouse/WarehouseIndex";
import FeaturesPage from "./pages/FeaturesPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsCondition from "./pages/TermsCondition";
import IndexPage from "./pages/IndexPage";
import ContactUsPage from "./pages/ContactUsPage";
import Invoices from "./pages/Dashboard/Invoices";
import AllInvoices from "./pages/Dashboard/AllInvoices";
import BusinessDocument from "./pages/Dashboard/BusinessDocument";
import UpdateDocumentationPage from "./pages/UpdateDocumentationPage";
import BusinessSettings from "./pages/Dashboard/BusinessSettings";
import WalletPrint from "./pages/Dashboard/WalletPrint";
import OverrideContentDescription from "./pages/Dashboard/OverrideContentDescription";
import BatchIndex from "./pages/Dashboard/Batch/BatchIndex";
import BatchShow from "./pages/Dashboard/Batch/BatchShow";
import ShipmentEdit from "./pages/Dashboard/Shipment/ShipmentEdit";
import QuickQuote from "./pages/Dashboard/QuickQuote";


export default function Router() {
    return (
        <Routes>
            <Route path='/' element={<StandardLayout />} >
                <Route index element={<IndexPage />} />
                <Route path='/auth/login' element={<Login />} />
                <Route path='/auth/signup' element={<Signup />} />
                <Route path="/auth/forgot-password" element={<PasswordReset />} />
                <Route path="/accept-business-invite/:invite_id" element={<AcceptBusinessInvite />} />
                <Route path='features' element={<FeaturesPage />} />
                <Route path='contact-us' element={<ContactUsPage />} />
                <Route path='privacy-policy' element={<PrivacyPolicy />} />
                <Route path='terms-condition' element={<TermsCondition />} />
                <Route path='/update_document/:order_id' element={<UpdateDocumentationPage />} />
                
            </Route>
            <Route path='dashboard' element={<DashboardLayout />}>
                <Route index element={<DashboardPage />} />
                <Route path='orders' element={<OrdersPage />} />
                <Route path='quick_quote' element={<QuickQuote />} />
                <Route path='shipments' >
                    <Route index  element={<ShipmentsPage />} />
                    <Route path="add" element={<AddShipment />} />
                    <Route path='import' element={<ShipmentImport />} />
                    <Route path=":shipment_id/book" element={<BookShipment />} />
                    <Route path=":shipment_id/edit" element={<ShipmentEdit />} />
                </Route>
                <Route path="invoices" element={<Invoices />} />
                
                <Route path='my-business' element={<BusinessIndex />} />
                <Route path='content-description' element={<OverrideContentDescription />} />
                
                <Route path='my-documents' element={<BusinessDocument />} />
                <Route path='warehouse' element={<WarehouseIndex />} />
                <Route path='customers' element={<CustomerIndex />} />
                <Route path="wallet">
                    <Route index element={<WalletPage />} />
                    <Route path=":transaction_id/success" element={<PaymentSuccess />} />
                    
                </Route>
                <Route path=":business_id/settings" element={<BusinessSettings />} />
                <Route path="batch" element={<BatchIndex />}/>
                <Route path="batch/:id" element={<BatchShow />}/>
                
            </Route>
            
            <Route path="/dashboard/invoices/:business_id" element={<AllInvoices />} />
            <Route path="/dashboard/wallet/:business_id/print" element={<WalletPrint />} />

            <Route path='*' element={
                <h1>404 Not Found</h1>
            } />
        </Routes>
    )
}