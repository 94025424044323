export default function Modal({ children, title, close }) {
    const id = Math.floor(Math.random() * 1000001);
    return (
        <div className="modal fade show" id={id} style={{display: 'block', overflowY: 'auto'}} onClick={(e) => {
            if(e.target?.id == id){
                close()
            }
        }}
        role={'dialog'}
        >
            <div className="modal-dialog" role={'document'}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                        <button type="button" className="btn-close" onClick={close}></button>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}