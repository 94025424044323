import { useEffect, useState } from "react";
import { useRecoilState } from "recoil"
import currentBusinessState from "../../atoms/currentBusinessState";
import GenericItemContainer from "../../components/GenericItemContainer";
import Loading from "../../components/Loading";
import Api from "../../lib/Api";

export default function OverrideContentDescription(){

    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);

    const [loading, setLoading ] = useState(true)

    const [adminItems, setAdminItems] = useState([]);

    const [businessItems, setBusinessItems] = useState([]);

    const [formSaving, setFormSaving] = useState(false);

    useEffect(() => {
        setLoading(true)
        if(currentBusiness && currentBusiness.id){
            Api.get(`/${currentBusiness.id}/generic_items`)
                .then(res => {
                    if(res.success){
                        setAdminItems(res.admin_items)
                        setBusinessItems(res.business_items)
                        setLoading(false)
                    }
                    else{
                        alert(res.message ?? "Something went wrong")
                    }
                })

                .catch(e => {
                    alert(e.message ?? "Something went wrong")
                })


        }
    }, [currentBusiness])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setFormSaving(true)
        // console.log(businessItems);
        try {
            const res = await Api.post(`/${currentBusiness.id}/generic_items`, businessItems)
            if(res.success){
                alert('Saved successfully')
            }
        } catch (error) {
            alert('Something went wrong')
        }
        setFormSaving(false)
    }

    if(loading){
        return (
            <Loading />
        )
    }
    return (
        <form onSubmit={handleSubmit}>
            <GenericItemContainer 
                adminItems={adminItems}
                businessItems={businessItems}
                setBusinessItems={setBusinessItems}
            />
            {formSaving && 
                <Loading />
            }
            {!formSaving && 
                <button className="btn btn-primary">Save</button>
            }
        </form>
        
    )
}