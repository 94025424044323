import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Loading from "../components/Loading";
import UpdateOrderDocument from "../components/UpdateOrderDocument";
import Api from "../lib/Api";

export default function UpdateDocumentationPage(){

    const [searchParams, setSearchParams] = useSearchParams();
    const [inputs, setInputs] = useState(null);

    const { order_id } = useParams();

    const [error, setError] = useState(null);

    const [order, setOrder] = useState(null);

    const [loading, setLoading ] = useState(true);

    const code = searchParams.get('code');

    useEffect(() => {   
        if(order_id && code){
            setLoading(true);
            setError(null)
            Api.post(`/inputs/${order_id}`, {
                code
            })
                .then(res => {
                    if(res.success){
                        setInputs(res.inputs);
                        setOrder(res.order)
                        setLoading(false)
                    }
                    else{
                        setInputs(null)
                        setOrder(null)
                        setError(res.message ?? "Something went worng")
                        setLoading(false)
                    }
                })
        }
    }, [])



    if(loading){
        return (
            <Loading />
        )
    }

    if(error){
        return (
            <div className="p-8 w-100 h-100 m-8" >
                <div className="alert alert-warning">{error}</div>
            </div>
        )
    }

    return (
        <UpdateOrderDocument 
            inputs={inputs}
            order={order}
            code={code}
        />
    )
}