import dayjs from 'dayjs'

export default function WalletTransactions({ transactions }) {
    if (transactions.length < 1) {
        return (
            <div className="">
                <p>No transactions to show.</p>
            </div>
        )
    }


    return (
        <div className="wallet-transaction-container">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Amount ({process.env.REACT_APP_CURRENCY_CODE})</th>
                        <th scope="col">Type</th>
                        <th scope="col">Description</th>
                        <th scope="col">Order</th>
                        <th scope="col">Paid At</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions && transactions.map &&
                        transactions.map(transaction => {
                            return (
                                <tr key={transaction.id}>
                                    <th scope="row">{transaction.id}</th>
                                    <td> {process.env.REACT_APP_CURRENCY_CODE} {(transaction.amount / 100).toFixed(2)}</td>
                                    <td>{transaction.is_credit ? "Credit" : "Debit"}</td>
                                    <td>{transaction.description}</td>
                                    <td>
                                        {transaction.order &&
                                            <p> #{transaction.order.id} Shipment from {transaction.order.shipment.delivery_city} - {transaction.order.shipment.delivery_zip}, {transaction.order.shipment.delivery_country} to {transaction.order.shipment.pickup_city} - {transaction.order.shipment.pickup_zip}, {transaction.order.shipment.pickup_country}</p>
                                        }
                                    </td>
                                    <td>
                                        {transaction.paid_at ? dayjs(transaction.paid_at).format('DD-MMM-YYYY') : ""}
                                    </td>
                                </tr>
                            )
                        })
                    }
                    
                </tbody>
            </table>
        </div>
    )
}