import { useState } from "react";
import Api from "../lib/Api";
import Loading from "./Loading";
import Modal from "./util/Modal";
import Warning from "./util/Warning";

export default function BusinessDocumentUpdate({businessId, documentId, setDocuments, document, close}){

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)


    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null)
        setLoading(true);
        const data = new FormData(e.target);

        const res = await Api.filePost(`/${businessId}/update_document/${documentId}`, data);
        console.log(res);
        if(res.success){
            setLoading(false)
            setDocuments(res.documents);
            close()
        }
        else{
            if(res.errors){
                setError(res.errors.file[0]);
            }
        }
        setLoading(false)
    }
    return (
        <Modal 
            title={`Update Document : ${document.document}`}
            close={close}
        >
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input type={'file'} name={'file'} className='form-control' />
                    {error && 
                        <Warning message={error} />
                    }
                </div>
                {!loading && 
                    <button className="btn btn-primary">Update</button>
                }
                {loading && 
                    <Loading />
                }
                
            </form>
        </Modal>
    )
}