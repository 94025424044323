import { useEffect, useState } from "react";
import Api from "../lib/Api";
import showB64File from "../lib/showB64File";
import DocumentAddFormForOrder from "./DocumentAddFormForOrder";
import DocumentEditFormForOrder from "./DocumentEditFormForOrder";
import DocumentForOrderSingleItem from "./DocumentForOrderSingleItem";
import Loading from "./Loading";
import Modal from "./util/Modal";

export default function DocumentsForOrderModal({ order, close }) {

    const [docs, setDocs] = useState(null)

    const [editDoc, setEditDoc] = useState(null)

    const [addDoc, setAddDoc] = useState(false)

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        Api.get(`/${order.business_id}/view_docs/${order.id}`)
            .then(res => {
                if (res.success) {
                    setDocs(res.docs)
                }
            })
            .catch((e) => {
                alert(e.message ?? "Something Went Wrong")
            })
    }

    return (
        <Modal
            title={`Documents For Order ID : ${order.id}`}
            close={close}
        >
            <div>
                {!docs &&
                    <div className="text-center p-4">
                        <Loading />
                    </div>
                }

                {!editDoc && !addDoc && docs && docs.length &&
                    <>
                    <button className="btn btn-primary" 
                        style={{float: 'right'}}
                        onClick={() => {
                            setAddDoc(!addDoc)
                        }}
                    >Add Additional Doc</button>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Document</th>
                                <th scope="col">File</th>
                                <th scope="col">Update</th>
                            </tr>
                        </thead>
                        <tbody>
                            {docs.map(doc => <DocumentForOrderSingleItem key={doc.id} doc={doc} edit={() => {
                                setEditDoc(doc)
                            }} />)}

                        </tbody>
                    </table>
                    </>
                }

                {editDoc && !addDoc && 
                    <DocumentEditFormForOrder 
                        doc={editDoc}
                        callback={() => {
                            setEditDoc(null)
                            setDocs(null)
                            fetchData()
                        }}
                    />
                }

                {!editDoc && addDoc && 
                    <DocumentAddFormForOrder 
                        order={order}
                        callback={() => {
                            setEditDoc(null)
                            setDocs(null)
                            fetchData()
                        }}
                    />
                }
            </div>
        </Modal>
    )
}