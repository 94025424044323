import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import ShipmentEditForm from "../../../components/ShipmentEditForm"

export default function AddShipment(){

    const [shipement, setShipment] = useState(null)

    const navigate = useNavigate()

    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">New Shipments</h3>

                <div className="card-tools">
                    <Link to={'/dashboard/shipments'} className='btn btn-primary'>Go Back</Link>
                </div>
            </div>
            <div className="card-body">
                <ShipmentEditForm 
                    setShipment={(shipement) => {
                        navigate('/dashboard/shipments')
                    }}
                />
            </div>


        </div>
    )
}