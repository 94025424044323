import Carousel from "../components/Carousel";
import { Link, useNavigate } from "react-router-dom";

export default function IndexPage(){
    return (
        <>
            
		<section className="top-section">
            <div className="background-section">
                <div className="row align-items-center m-0">
                    <div className="col-md-12 p-0">
                        <img src="/homebanner.jpg" className="img-fluid imagesppp"  alt="" />
                        {/* https://www.freepik.com/premium-vector/online-shopping-design-concept-with-people-mobile-online-shopping-app-gifts-shopping-items-credit-cards_8480555.htm#query=ecommerce%20marketing&position=12&from_view=search */}
                    </div>
                </div>
            </div> 
            <div className="background-overflow">
                <div className="w-100 text-end pe-5">
                    {/* <h2 className="new-h2-sty1 ">Skip the hassle with BIZ EXPRESS</h2>
                    <p className="p-sty1 ">Obtain access to your own business dashboard, multiple carriers,<br /> and the freedom to control your own shipments. </p> */}
                    <div className="yuhfg ">
                        <Link to="/auth/signup" >Sign Up And Save With BIZ Express</Link>
                    </div>    
                </div>
            </div>
        </section>
        
        <section className="pe-5 ps-5 mt-5">
            <div className="container">
                <div className="_brndtl text-center mb-5">
                    <h4>Australias' Best Courier Delivery Rates</h4>
                    <h6>Get your parcels where they need to go without breaking the bank!</h6>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="card p-4 package-box">
                        <div>
                            <h2 className="isgfff text-center">{'Awesome'.toUpperCase()}</h2>
                            {/* <div className="box-imgss text-center">
                                <img src="/australia-m.png" alt=""  />
                            </div> */}
                            <div className="text-center">Prices From:</div>
                            <div className="row align-items-end mb-4">
                                <div className="col-2"></div>
                                <div className="col-2"><img src="/australia.png" alt="" className="img-fluid" /></div>
                                <div className="col-8 text-start hvgvg">{process.env.REACT_APP_CURRENCY_CODE} 7.49 <br /><span>Domestic </span></div>
                            </div>
                            <div className="row align-items-end mb-4">
                                <div className="col-2"></div>
                                <div className="col-2"><img src="/around.png" alt="" className="img-fluid" /></div>
                                <div className="col-8 text-start hvgvg">{process.env.REACT_APP_CURRENCY_CODE} 6.32 <br /><span>International E-commerce Imports </span></div>
                            </div>
                            
                            <div className="box-boby-text">
                                <ul>
                                    <li><img src="/tik.png" /> Free Account</li>
                                    <li><img src="/tik.png" /> Pickup from your door / office</li>
                                    <li><img src="/tik.png" /> Tracking available</li>
                                    <li><img src="/tik.png" /> Free API integration</li>
                                    <li><img src="/tik.png" /> Signature on delivery</li>
                                    <li><img src="/tik.png" /> Customer Service Support</li>
                                    <li><img src="/tik.png" /> Chat Support</li>
                                    <li><img src="/tik.png" /> Monthly Statement</li>
                                    <li><img src="/tik.png" /> Create own customer for resell</li>
                                    <li><img src="/tik.png" /> 20+ consignments picked up from one location at the same time per day</li>
                                </ul>
                            </div>
                        </div>

                        <div>
                            <div className="yuhfgmcn text-center">
                                <strong>Minimum 100+ cons per week</strong>
                            </div>   
                            <div className="yuhfg text-center mt-3">
                                <Link to="/auth/login" >Create Your Free Account</Link>
                            </div>                             
                        </div>                        
                        
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="card p-4 package-box">
                        <div>
                            <h2 className="isgfff text-center">{'Striker'.toUpperCase()}</h2>
                            {/* <div className="box-imgss text-center">
                                <img src="/australia-m.png" alt=""  />
                            </div> */}
                            <div className="text-center">Prices From:</div>
                            <div className="row align-items-end mb-4">
                                <div className="col-2"></div>
                                <div className="col-2"><img src="/australia.png" alt="" className="img-fluid" /></div>
                                <div className="col-8 text-start hvgvg"> {process.env.REACT_APP_CURRENCY_CODE} 7.55 <br /><span>Domestic </span></div>
                            </div>
                            <div className="row align-items-end mb-4">
                                <div className="col-2"></div>
                                <div className="col-2"><img src="/around.png" alt="" className="img-fluid" /></div>
                                <div className="col-8 text-start hvgvg">{process.env.REACT_APP_CURRENCY_CODE} 6.32<br /><span>International E-commerce Imports </span></div>
                            </div>
                            
                            <div className="box-boby-text">
                                <ul>
                                    <li><img src="/tik.png" /> Free Account</li>
                                    <li><img src="/tik.png" /> Pickup from your door / office</li>
                                    <li><img src="/tik.png" /> Tracking available</li>
                                    <li><img src="/tik.png" /> Free API integration</li>
                                    <li><img src="/tik.png" /> Signature on delivery</li>
                                    <li><img src="/tik.png" /> Customer Service Support</li>
                                    <li><img src="/tik.png" /> Chat Support</li>
                                    <li><img src="/tik.png" /> Monthly Statement</li>
                                    <li><img src="/tik.png" /> Create own customer for resell</li>
                                    <li><img src="/tik.png" /> 20+ consignments picked up from one location at the same time per day</li>
                                </ul>
                            </div>
                            
                        </div>

                        <div>
                            <div className="yuhfgmcn text-center">
                                <strong>Minimum 50-99 cons per week</strong>
                            </div>
                            <div className="yuhfg text-center mt-3">
                                <Link to="/auth/login" >Create Your Free Account</Link>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="card p-4 package-box">
                        <div>
                            <h2 className="isgfff text-center">{'Stunner'.toUpperCase()}</h2>
                            {/* <div className="box-imgss text-center">
                                <img src="/australia-m.png" alt=""  />
                            </div> */}
                            <div className="text-center">Prices From:</div>
                            <div className="row align-items-end mb-4">
                                <div className="col-2"></div>
                                <div className="col-2"><img src="/australia.png" alt="" className="img-fluid" /></div>
                                <div className="col-8 text-start hvgvg"> {process.env.REACT_APP_CURRENCY_CODE} 9.00 <br /><span>Domestic </span></div>
                            </div>
                            <div className="row align-items-end mb-4">
                                <div className="col-2"></div>
                                <div className="col-2"><img src="/around.png" alt="" className="img-fluid" /></div>
                                <div className="col-8 text-start hvgvg">{process.env.REACT_APP_CURRENCY_CODE} 6.32<br /><span>International E-commerce Imports </span></div>
                            </div>
                            
                            <div className="box-boby-text">
                                <ul>
                                    <li><img src="/tik.png" /> Free Account</li>
                                    <li><img src="/tik.png" /> Pickup from your door / office</li>
                                    <li><img src="/tik.png" /> Tracking available</li>
                                    <li><img src="/tik.png" /> Free API integration</li>
                                    <li><img src="/tik.png" /> Signature on delivery</li>
                                    <li><img src="/tik.png" /> Customer Service Support</li>
                                    <li><img src="/tik.png" /> Chat Support</li>
                                    <li><img src="/tik.png" /> Monthly Statement</li>
                                    <li><img src="/tik.png" /> Create own customer for resell</li>
                                    <li><img src="/tik.png" /> These rates apply to the majority of  booked consignments from 1- 9 per cons per day</li>
                                </ul>
                            </div>
                            
                        </div>
                        <div>
                            <div className="yuhfgmcn text-center">
                                <strong>Minimum 1 consignment per day</strong>
                            </div>
                            <div className="yuhfg text-center mt-3">
                                <Link to="/auth/login" >Create Your Free Account</Link>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="card p-4 package-box">
                        <div>
                            <h2 className="isgfff text-center">{'No Signature'.toUpperCase()}</h2>
                            {/* <div className="box-imgss text-center">
                                <img src="/australia-m.png" alt=""  />
                            </div> */}
                            <div className="text-center">Prices From:</div>
                            <div className="row align-items-end mb-4">
                                <div className="col-2"></div>
                                <div className="col-2"><img src="/australia.png" alt="" className="img-fluid" /></div>
                                <div className="col-8 text-start hvgvg"> {process.env.REACT_APP_CURRENCY_CODE} 7.62 <br /><span>Domestic </span></div>
                            </div>
                            <div className="row align-items-end mb-4">
                                <div className="col-2"></div>
                                <div className="col-2"><img src="/around.png" alt="" className="img-fluid" /></div>
                                <div className="col-8 text-start hvgvg">{process.env.REACT_APP_CURRENCY_CODE} 2.00<br /><span>International E-commerce Imports </span></div>
                            </div>
                            
                            <div className="box-boby-text">
                                <ul>
                                    <li><img src="/tik.png" /> Free Account</li>
                                    <li><img src="/tik.png" /> Pickup from your door / office</li>
                                    <li><img src="/tik.png" /> Tracking available</li>
                                    <li><img src="/tik.png" /> Free API integration</li>
                                    <li><img src="/tik.png" /> No Signature</li>
                                    <li><img src="/tik.png" /> Customer Service Support</li>
                                    <li><img src="/tik.png" /> Chat Support</li>
                                    <li><img src="/tik.png" /> Monthly Statement</li>
                                    <li><img src="/tik.png" /> Create own customer for resell</li>
                                    <li><img src="/tik.png" /> These rates are for the majority of  booked consignments from 1- 9 per day</li>
                                </ul>
                            </div>
                            
                        </div>
                        
                        <div>
                            <div className="yuhfgmcn text-center">
                                <strong>Minimum 1 consignment per day</strong>
                            </div>
                            <div className="yuhfg text-center mt-3">
                                <Link to="/auth/login" >Create Your Free Account</Link>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
		<div className="middle-section">
            <img src="/bg-123.jpg" className="mid-image" />
            {/* https://www.freepik.com/premium-vector/fast-delivery-package-by-scooter-mobile-phone-order-package-e-commerce-by-app-tracking-courier-by-map-application-three-dimensional-concept-vector-illustration_6961014.htm#page=3&query=delivery%20service&position=11&from_view=search */}
			<div className="mt-5 zindex-2 vhfjhf">
				<div className="row m-0 align-items-center">
                    <div className="col-md-6"></div>
					<div className="col-md-6">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 p-tb-50 p-lr-30">
                                <h2>12</h2>
                                <h3>Million Deliveries Per Month Globally</h3>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 p-tb-50 p-lr-30">
                                <h2>32,000</h2>
                                <h3>Outlets In Asia, Europe, Americas</h3>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 p-tb-50 p-lr-30">
                                <h2>22,000</h2>
                                <h3>People Employed Globally</h3>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 p-tb-50 p-lr-30">
                                <h2>1</h2>
                                <h3>Global Brand</h3>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
        <section className="mb-5">
            <div className="_brndtl p-5 text-center">
                <h4>E-Commerce <strong>Integration</strong></h4>
                <p className="psty-1">In order to make sending a large number of parcels as simple as possible for your business, we interface seamlessly with ecommerce leaders and DTDC partners such as eBay, Shopify, WooCommerce, Wish, Magento, and many more.</p>
            </div>
            <div className="row m-0 ">
                <div className="col-md-6 p-0"><img src="long-bnr.jpg" className="long-imga" /></div>
                <div className="col-md-6 pe-3 ps-3">                    
                    <div className="_owlin card shadow mb-3">
                        <div className="row">
                            <div className="col-md-2"><img src="https://www.dtdcaustralia.com.au/assets/images/mg1.png" alt="" /></div>
                            <div className="col-md-10 text-start">
                                <h4>Magento</h4>
                                <p>Magento is an Adobe-owned ecommerce website platform that specialises in ecommerce websites. Magento users have access to hundreds of unique tools that aid in connecting with consumers and selling their items.</p>
                            </div>
                        </div>
                    </div>
                    <div className="_owlin card shadow mb-3">
                        <div className="row">
                            <div className="col-md-2"> <img src="https://www.dtdcaustralia.com.au/assets/images/mg2.png" alt="" /></div>
                            <div className="col-md-10 text-start">
                                <h4>WooCommerce</h4>
                                <p>WooCommerce is a WordPress ecommerce plugin. It simplifies the process of developing and administering an online store by providing respectable levels of flexibility as well as various important features such as inventory and tax administration, secure payments, and shipping integration.</p>
                            </div>
                        </div>
                    </div>
                    <div className="_owlin card shadow mb-3">
                        <div className="row">
                            <div className="col-md-2"><img src="https://www.dtdcaustralia.com.au/assets/images/mg3.png" alt="" /></div>
                            <div className="col-md-10 text-start">
                                <h4>e Bay</h4>
                                <p>eBay is a popular online shopping site recognised for its auctions and consumer-to-consumer purchases. It is also widely used as a sales channel by internet businesses. eBay is offered in a variety of countries.</p>
                            </div>
                        </div>
                    </div>
                    <div className="_owlin card shadow mb-3">
                        <div className="row">
                            <div className="col-md-2"> <img src="https://www.dtdcaustralia.com.au/assets/images/mg4.png" alt="" /></div>
                            <div className="col-md-10 text-start">
                                <h4>Shopify</h4>
                                <p>Shopify provides a simple approach to quickly build an online store without the hassle of infrastructure and development expenditures that self-hosted systems such as Magento might incur. Because all of the functions are properly organised, the admin interface is clean, user-friendly, and quite intuitive. You will always have a piece of mind knowing when your shipment has arrived.</p>
                            </div>
                        </div>
                    </div>
                    <div className="_owlin card shadow mb-3">
                        <div className="row">
                            <div className="col-md-2"> <img src="/wish.png" alt="" /></div>
                            <div className="col-md-10 text-start">
                                <h4>Wish</h4>
                                <p>Wish is a rapidly expanding online marketplace that allows customers to explore and purchase things from third-party vendors. It was established in 2010 in San Francisco by Peter Szulczewski and Danny Zhang, and has since grown in popularity throughout the world.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
		
        {/* <section className="bg-light-gray pt-5 pb-5 wswfzs">
            <div className="container">
                <div className="_brndtl text-center mb-5">
                    <h4>Reliable, cost effective <strong>Australia wide and International  </strong>Courier Solutions</h4>
                    <p className="psty-1">We provide quality services through our online portal at affordable rates, making it easier for you and your business to fulfil your shipping needs.</p>
                </div>
                <div className="row mb-4 ">
                    <div className="col-lg-3 col-md-4 p-2">
                        <div className="border rounded-lg p-3 min-h-315">
                            <h2 className="new-h2-sty1"> <strong>Domestic</strong> Courier Services</h2>
                            <p className="p-sty1">DTDC has your back when it comes to interstate shipping. We are known for providing quick and secure services, and our customers rely on us to get their items where they need to go. With our extensive network courier partners, precise driver tracking, and updates, we always leave you smiling.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 p-2">
                    <div className="border rounded-lg p-3 min-h-315">
                        <h2 className="new-h2-sty1"><strong>International </strong>Shipping</h2>
                        <p className="p-sty1">You are in command. At the press of a button, select your shipping partner from a list that includes DTDC, DHL, TNT, and others. We will have your items dispatched to you as soon as possible. DTDC is the top choice when it comes to shipping goods worldwide since, no matter where you are...We can help you with whatever you require.</p>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-4 p-2">
                    <div className="border rounded-lg p-3 min-h-315">
                        <h2 className="new-h2-sty1"> <strong>Road Freight</strong> </h2>
                        <p className="p-sty1">DTDC Australia prides itself in its ingenious workmanship as a road freight service provider. We help businesses expand by ensuring safe and effective transportation of your cargo. We have warehouses and terminals spread all over the continent, DTDC leaves no stone unturned in providing facilities of the door to door courier Australia wide for the delivery of your goods.</p>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-4 p-2">
                    <div className="border rounded-lg p-3 min-h-315">
                        <h2 className="new-h2-sty1"><strong>Excess Baggage </strong></h2>
                        <p className="p-sty1">DTDC specialises in sending excess baggage and personal effects all over the world. Our mission is to make your journey with us as easy as possible, so why not use the courier service that has got your back?</p>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-4 p-2">
                    <div className="border rounded-lg p-3 min-h-315">
                        <h2 className="new-h2-sty1"><strong>Time Saving</strong></h2>
                        <p className="p-sty1">Save your time and energy and focus on generating more money and selling more goods Our technology works with yours to provide quotations to customers and save you time by integrating with your wholesale or e-commerce solution.</p>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-4 p-2">
                    <div className="border rounded-lg p-3 min-h-315">
                        <h2 className="new-h2-sty1"><strong>Multiple Services, One Secure Login</strong></h2>
                        <p className="p-sty1">Quote, book, and track your parcels from your Biz Express Dashboard on any device. We make the process easier by providing what we call ¨Ease Of Access¨ Shipping for our commercial clients.</p>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-4 p-2">
                    <div className="border rounded-lg p-3 min-h-315">
                        <h2 className="new-h2-sty1"><strong>Advanced Support Features</strong></h2>
                        <p className="p-sty1">Get quick access to thorough information and support from our team of dedicated professionals whenever and wherever you are!</p>
                    </div>
                    </div>
                </div>
            </div>
        </section> */}
        <section className="position-relative ab-footer">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6"><img src="/footer-img.jpg" className="w-100" /></div>
                    <div className="col-md-6">
                        <div className="gcfscc w-100">
                            <div className="container ">
                                <h2 className="new-h2-sty2  position-relative z-index1">Instant Pricing From Several Different Carriers Within One Login</h2>
                                <p className="p-sty1 position-relative z-index1  me-auto ms-auto fs-18b">We are determined to make your process as swift as possible, that is why we give you access to multiple services and features with one secure login. Track, Compare and Browse all at the click of a button. We make innovation easier by putting the world of logistics at the tip of your fingers.</p>
                                <div className="yuhfg position-relative z-index1"> 
                                    <a href="/contact-us">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        

        </>
    )
}