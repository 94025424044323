import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import currentUserState from "../atoms/currentUserState";


export default function Navigation() {
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState)
    const [isExpanded, setIsExpanded] = useState(false)

    const navigate = useNavigate();
    return (
        <nav className="navbar navbar-dark bg-1d5b44 navbar-expand-md">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <img className="_logo" src={`${process.env.REACT_APP_LOGO_LOCATION}`} alt=""  />
                </a>
                <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" onClick={() => {
                    setIsExpanded(!isExpanded)
                }}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={isExpanded ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'} id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto ms-5 mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" to="/" >Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/features" >Features</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact-us" >Contact Us</Link>
                        </li>

                        


                        {/* {currentUser && currentUser.id === undefined &&
                            <>
                                < li className="nav-item">
                                    <Link className="nav-link" to="/auth/login" >Log In</Link>
                                </li>
                                < li className="nav-item">
                                    <Link className="nav-link" to="/auth/signup" >Sign Up</Link>
                                </li>
                            </>

                        } */}


                    </ul>
                    
                    <div className="d-flex">
                        {currentUser && !currentUser.id && 
                             <button className="btn fsdghsd me-3" onClick={() => {
                                
                                navigate('/auth/login')
                                
                            }}
                            disabled={!currentUser}
                            >Login</button>
                        }
                        <button className="btn fsdghsd" onClick={() => {
                            if(!currentUser){
                                return;
                            }
                            if(currentUser.id){
                                navigate('/dashboard/')
                            }
                            else{
                                navigate('/auth/signup')
                            }
                        }}
                        disabled={!currentUser}
                        >{!currentUser ? 'Loading...' : (currentUser.id ? 'Dashboard' : 'Create Your Free Account') }</button>
                    </div>

                </div>
            </div>
        </nav >
    )
} 
