import Slider from "react-slick";



export default function Carousel({children, conf={}}){
    const defaultSettings = {
        dots: false,
        nav: false,
        infinite: true,
        autoplay: true,
        pauseOnHover: true,
        speed: 1000,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        margin: "50px",
        cssEase: "linear",
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
          ]
    };

    
    

    const settings = {...defaultSettings, ...conf}
    return (
        <Slider {...settings} >
            {children}
        </Slider>
        )    
}
