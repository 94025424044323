import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import currentBusinessState from "../../../atoms/currentBusinessState";
import Api from "../../../lib/Api";
import ReactTableBody from "../../../components/util/ReactTableBody";
import Loading from "../../../components/Loading";
import { Link } from "react-router-dom";

export default function BatchIndex(){
    const [loading, setLoading] = useState(true)
    const [batches, setBatches] = useState([]); 
    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState)


    const getBatches = async () => {
        setLoading(true);
        if(currentBusiness && currentBusiness.id){
            const res = await Api.get(`/${currentBusiness.id}/batch`);

            if(res.success){
                setBatches(res.batches);
                setLoading(false)
            }

        }
    }

    useEffect(() => {
        getBatches()
            .catch(e => {
                alert(e.message ?? "Something went wrong")
                setLoading(false)
            })
    }, [currentBusiness])

    const deleteBatch = async (batch) => {
        // console.log(batch);
        if(window.confirm(`Are you sure you want to delete Batch : ${batch.name}`)){
            try {
                setLoading(true);

                const res = await Api.delete(`/${currentBusiness.id}/batch/${batch.id}/delete`)
                if(!res.success){
                    throw Error(res.message ?? "Something went wrong")
                }
                const myBatches = [...batches].filter(b => b.id != batch.id);

                setBatches(myBatches)
                setLoading(false);
            } catch (error) {
                alert(error.message ?? "Something went wrong")
                setLoading(false)
            }
            
        }
    }

    const header = [

        {
            Header: 'ID',
            accessor: 'id', // accessor is the "key" in the data
        },
        {
            Header: "Name", 
            accessor: "name"
        },
        {
            Header: "Amount",
            accessor: "amount",
            Cell: props => <>{process.env.REACT_APP_CURRENCY_CODE} {(props.value/100).toFixed(2)}</>
        },
        {
            Header: "Shipments",
            accessor: "shipments",
            Cell: props => <>{props.value.length}</>
        },
        {
            Header: "Action",
            accessor: d => d,
            Cell: (props) => <>
                <Link className="btn btn-primary" to={`/dashboard/batch/${props.value.id}`}>View</Link>
                <button className="btn btn-danger"
                    onClick={() => {
                        deleteBatch(props.value)
                    }}
                >Delete</button>
            </>
        }
    ]

    if(loading){
        return <Loading />
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">Shipment Batch</h3>

                <div className="card-tools">
                    <button className="btn btn-secondary">New Batch</button>
                </div>
            </div>
            <div className="card-body">
                <div className="">
                <ReactTableBody
                    body={batches} 
                    headers={header} 
                />
                </div>

            </div>
            
        </div>
    );
}