import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import currentUserState from "../../atoms/currentUserState";
import Invoice from "../../components/Invoice";
import Loading from "../../components/Loading";
import EnsureCsrfCookie from "../../components/util/EnsureCsrfCookie";
import GetCurrentUser from "../../components/util/GetCurrentUser";
import Api from "../../lib/Api";

export default function AllInvoices() {


    const [loading, setLoading] = useState(true);

    const [business, setBusiness] = useState(null);
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState)

    const { business_id } = useParams();

    let [searchParams, setSearchParams] = useSearchParams();

    

    const [invoices, setInvoices] = useState([]);

    const date = searchParams.get('date');

    useEffect(() => {
        if (currentUser && currentUser.id) {
            Api.post(`/${business_id}/invoices`, {
                date
            })
                .then(res => {
                    if (res.success) {
                        setLoading(false)
                        setInvoices(res.invoices);
                        setBusiness(res.business)
                    }
                    else {
                        alert(res.message ?? "Something went wrong");
                    }
                })
        }
    }, [business_id, currentUser])




    if (loading) {
        return (
            <>
                <EnsureCsrfCookie />
                <GetCurrentUser />
                <Loading />
            </>
        )
    }
    return (
        <>
            <EnsureCsrfCookie />
            <GetCurrentUser />
            <Invoice
                invoices={invoices}
                business={business}
                invoice_no={`B${business_id}D${dayjs(date).format('YYYYMMDD')}`}
                
            />
        </>

    )
}