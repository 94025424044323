import { useRecoilState } from "recoil"
import courierServicesState from "../atoms/courierServicesState"
import BatchShipmentError from "./BatchShipmentError"
import Select from "react-select";
import { useState } from "react";
import Api from "../lib/Api";
import currentBusinessState from "../atoms/currentBusinessState";
import { Link, useNavigate } from "react-router-dom";
import OrdersTableForBatch from "./OrdersTableForBatch";
import PDFMerger from 'pdf-merger-js';
import showB64File from "../lib/showB64File";

export default function BatchShipmentView({currentBatch, setCurrentBatch, handleCourierServiceChangeForBatch, removeShipmentFromBatch, recheckPrice}){

    const [courierServices, setCourierServices] = useRecoilState(courierServicesState)
    const [currentBusiness, setCurrentBusniess] = useRecoilState(currentBusinessState)

    const [loading, setLoading ] = useState(false)
    const navigate = useNavigate();
    const getCurrentCourierServiceOption = () => {
        const service = courierServices.find(s => s.id == currentBatch.dtdc_courier_service_id)
        if(service){
            return {
                label: service.title,
                value: service.id
            }
        }
        
    }

    

    const placeBulkOrder = async () => {
        let isOrderDisabled = false;

        currentBatch.shipments.forEach(shipment => {
            if(!shipment.quote){
                isOrderDisabled = true;
            }
        });

        if(isOrderDisabled){
            alert("Some shipments in the batch don't have a quote. Please remove those, or choose a different courier service")
            return;
        }


        const res = await Api.post(`/${currentBusiness.id}/placeOrder/${currentBatch.id}`);     
        
        navigate(`/dashboard/batch/${currentBatch.id}`)
    }

    const generatePdf = () => {
        Api.get(`/${currentBusiness.id}/batch/${currentBatch.id}/download`)
            .then(res => {
                if(res.success){
                    showB64File(res.file, res.mime)
                } 
            })
           
    }
    return (
        <div>

            <div>
                <p>{currentBatch.name}</p>

                <h1 style={{color: 'rgb(59, 51, 99)'}}>Total : {(currentBatch.amount /100).toFixed(2)}</h1>
                {!currentBatch.order_placed_at && 
                    <Select 
                        options={courierServices.filter(cs => cs.register_pick_up).map(service => {
                            return {
                                label: service.title,
                                value: service.id
                            }
                        })}
                        onChange={(val) => {
                            handleCourierServiceChangeForBatch(currentBatch.id, val.value)
                        }}
                        value={getCurrentCourierServiceOption()}
                    />
            }
            <button onClick={generatePdf}>Gen PDF</button>
            </div>
            {!currentBatch.order_placed_at && 
            
                currentBatch.shipments.map(shipment => {
                    return (
                        <div style={{
                            background: '#75726c78',
                            padding: '1rem',
                            borderRadius: '1rem',
                            marginBottom: '1rem',
                            marginTop: '1rem'
                        }}>
                            <div className="row">
                                <div className="col-6">
                                    <h1 style={{color: 'rgb(59, 51, 99)'}}>{shipment.quote ? (shipment.quote.price /100).toFixed(2) : null}</h1>
                                </div>
                                <div className="col-6" >
                                    <button className="btn btn-danger" onClick={() => {
                                        removeShipmentFromBatch(shipment.id)
                                    }}>Delete</button>
                                    <Link to={`/dashboard/shipments/${shipment.id}/edit`} className="btn btn-primary" target="_blank">Edit</Link>
                                    <button className="btn btn-success" onClick={() => {
                                        recheckPrice(shipment.id)
                                    }}>ReCheck</button>

                                </div>
                                <div className="col-6">
                                    <p>From</p>
                                    <p>{shipment.pickup_city} - {shipment.pickup_zip}, {shipment.pickup_country}</p>
                                </div>
                                <div className="col-6">
                                    <p>To</p>
                                    <p>{shipment.delivery_city} - {shipment.delivery_zip}, {shipment.delivery_country}</p>
                                </div>
                            </div>
                            <div style={{maxWidth: '100%', overflow: 'auto'}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                        <th>Weight(KG)</th>
                                        <th>Length(CM)</th>
                                        <th>Width(CM)</th>
                                        <th>Height(CM)</th>
                                        <th>ITem Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {shipment.items.map(i => {
                                            return (
                                                <tr>
                                                    <th>{i.weight}</th>
                                                    <td>{i.length}</td>
                                                    <td>{i.width}</td>
                                                    <td>{i.height}</td>
                                                    <td>{i.type}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            

                            {shipment.error &&
                                <BatchShipmentError error={shipment.error} />
                            }
                        </div>
                    )
                })
            }

            {currentBatch.order_placed_at && 
                <OrdersTableForBatch orders={currentBatch.orders} />
            }
            

            <button className="btn btn-primary"
                onClick={placeBulkOrder}               
            >Place Order</button>
        </div>
    )
}