import OrderContainer from "../../../components/OrderContainer";

export default function OrdersPage() {
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">Orders</h3>

                <div className="card-tools">
                    {/* <Link to={'/dashboard/shipments/import'} className='btn btn-primary'>Import</Link> */}
                </div>
            </div>
            <div className="card-body order-table-fix-w">
                <OrderContainer />
            </div>
        </div>
    )
}