import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import currentBusinessState from "../../atoms/currentBusinessState";
import DocumentIndex from "../../components/DocumentIndex";
import Loading from "../../components/Loading";
import Api from "../../lib/Api";

export default function BusinessDocument(){
    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);

    const [documents, setDocuments] = useState(null);
    
    useEffect(() => {
        if(currentBusiness && currentBusiness.id){
            Api.get(`/${currentBusiness.id}/documets`)
                .then(res => {
                    if(res.success){
                        setDocuments(res.documents)
                    }
                    else{
                        alert(res.message ?? "Something Went Wrong")
                        setDocuments(null)
                    }
                })
        }
        else{
            setDocuments(null)
        }
    }, [currentBusiness]);


    if(!documents || !currentBusiness || !currentBusiness.id){
        return <Loading />
    }

    return <DocumentIndex documents={documents} setDocuments={setDocuments} currentBusiness={currentBusiness} />
}