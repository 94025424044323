import { useEffect } from "react";
import { useRecoilState } from "recoil";
import courierServicesState from "../../atoms/courierServicesState";
import currentBusinessState from "../../atoms/currentBusinessState";
import currentUserState from "../../atoms/currentUserState";
import Api from "../../lib/Api";

export default function CourierServices(){
    const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

    const [courierServices, setCourierServices] = useRecoilState(courierServicesState);

    useEffect(() => {
        if(currentBusiness && currentBusiness.id && currentUser && currentUser.id){
            Api.get(`/${currentBusiness.id}/courier_services`)
                .then(res => {
                    if(res.success){
                        setCourierServices(res.courier_services);
                    }
                })
        }
    }, [currentBusiness, currentUser])
}