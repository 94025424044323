import { useEffect, useState } from "react"

export default function CitySugesstion({input, callback = () => {}, country = 'AU'}){

    const [sugesstion, setSugesstion] = useState([]);
    useEffect(() => {
        if(input.length < 2){
            setSugesstion([])
            return;
        }
        let url;
        if(country == 'AU'){
            url = `${process.env.REACT_APP_DTDC_PUBLIC_ASSET_URL}/../api/city_suggestion?zip_code=${encodeURIComponent(input)}`
        }
        else{
            url = `${process.env.REACT_APP_DTDC_PUBLIC_ASSET_URL}/../api/other_country_city_suggestion?zip_code=${encodeURIComponent(input)}&country_code=${encodeURIComponent(country)}`
        }

        fetch(`${url}`)
            .then(res => res.json())
            .then(res => {
                setSugesstion(res)
            })
    }, [input])

    return (
        <div className="sugesstion">
            {sugesstion.map((s, key) => {
                return (
                    <p
                        key={key}
                        onClick={() => {
                            callback(s.name, s.zip_code);
                            setSugesstion([])
                        }}
                    >{s.name} - {s.zip_code}</p>
                )
            })}
        </div>
    )
}