export default function getProductTypeOptions() {
    return [
        {
            label: "Parcel/Carton/Baggage",
            value: "parcel"
        },
        {
            label: "Pallet",
            value: "crate"
        },
        {
            label: "Documents",
            value: "documents"
        }
    ];
}

export function productTypeList(){
    return ['parcel', 'crate', 'documents'];
}

